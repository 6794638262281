import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.channelText.action";
import * as canvasAction from "../actions/canvas.action";
import * as history from "../actions/history/canvas.history.action";
import * as notification from "../actions/notification.action";
import axios from "../config/axios";

// Update channel text owned
export function* updateChannelTextOwnedSaga(action) {
  yield put(actions.updateChannelTextOwnedStart());
  const data = {
    user_id: action.user_id,
    channel_text_owned_text1: action.channel_text_owned_text1,
    channel_text_owned_text2: action.channel_text_owned_text2,
    channel_text_owned_text3: action.channel_text_owned_text3,
    channel_text_owned_text4: action.channel_text_owned_text4,
    channel_text_owned_text5: action.channel_text_owned_text5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/channel-text-owned`,
      data
    );
    yield put(
      actions.updateChannelTextOwnedSuccess(response.status, response.message)
    );
    yield put(
      actions.setChannelTextOwned(
        action.channel_text_owned_text1,
        action.channel_text_owned_text2,
        action.channel_text_owned_text3,
        action.channel_text_owned_text4,
        action.channel_text_owned_text5
      )
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(
      notification.setNotification(
        response.status,
        false,
        response.message,
        false
      )
    );
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateChannelTextOwnedFail(e.message));
  }
}

// Update channel text shared
export function* updateChannelTextSharedSaga(action) {
  yield put(actions.updateChannelTextSharedStart());
  const data = {
    user_id: action.user_id,
    channel_text_shared_text1: action.channel_text_shared_text1,
    channel_text_shared_text2: action.channel_text_shared_text2,
    channel_text_shared_text3: action.channel_text_shared_text3,
    channel_text_shared_text4: action.channel_text_shared_text4,
    channel_text_shared_text5: action.channel_text_shared_text5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/channel-text-shared`,
      data
    );
    yield put(
      actions.updateChannelTextSharedSuccess(response.status, response.message)
    );
    yield put(
      actions.setChannelTextShared(
        action.channel_text_shared_text1,
        action.channel_text_shared_text2,
        action.channel_text_shared_text3,
        action.channel_text_shared_text4,
        action.channel_text_shared_text5
      )
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(
      notification.setNotification(
        response.status,
        false,
        response.message,
        false
      )
    );
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateChannelTextSharedFail(e.message));
  }
}

// Update channel text paid
export function* updateChannelTextPaidSaga(action) {
  yield put(actions.updateChannelTextPaidStart());
  const data = {
    user_id: action.user_id,
    channel_text_paid_text1: action.channel_text_paid_text1,
    channel_text_paid_text2: action.channel_text_paid_text2,
    channel_text_paid_text3: action.channel_text_paid_text3,
    channel_text_paid_text4: action.channel_text_paid_text4,
    channel_text_paid_text5: action.channel_text_paid_text5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/channel-text-paid`,
      data
    );
    yield put(
      actions.updateChannelTextPaidSuccess(response.status, response.message)
    );
    yield put(
      actions.setChannelTextPaid(
        action.channel_text_paid_text1,
        action.channel_text_paid_text2,
        action.channel_text_paid_text3,
        action.channel_text_paid_text4,
        action.channel_text_paid_text5
      )
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(
      notification.setNotification(
        response.status,
        false,
        response.message,
        false
      )
    );
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateChannelTextPaidFail(e.message));
  }
}
