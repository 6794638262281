import React, { Component } from "react";
import { connect } from "react-redux";
import Aux from "../../../hoc/Auxilary/Auxilary";
import Input from "../../UI/Input/Input";
import * as canvasActions from "../../../actions/canvas.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";

class Header extends Component {
  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    name: ""
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      canvas_id: nextProps.canvasDetails.canvas_id,
      name: nextProps.canvasDetails.name
    });
  }

  clickHandler = () => {
    this.inputRef.select();
  };

  reff = ref => {
    this.inputRef = ref;
  };

  changeHandler = (field, event) => {
    const value = event.target.value;
    this.setState(state => {
      return {
        [field]: value
      };
    });
  };

  updateHandler = () => {
    if (
      this.props.canvasDetails.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      this.props.updateCanvasName(
        this.state.user_id,
        this.state.canvas_id,
        this.props.canvasList.canvas,
        this.state.name
      );
    }
  };

  bookBBCall = () => {
    window.open("https://ebrandcall.com", "_blank");
  };

  render() {
    return (
      <Aux>
        <header className={"header"}>
          <div className={"header__logo"}>
            <img src="/img/main/logo.svg" alt="eBrandBuilders" />

            <Input
              id={this.state.name}
              value={this.state.name}
              field={"name"}
              placeholder={"Enter One Pager Name"}
              focusout={this.updateHandler}
              changeHandler={this.changeHandler}
              clickHandler={this.clickHandler}
              reff={this.reff}
            />
          </div>

          <div className={"header__buttons"}>
            <button data-target="share">
              <img src="/img/main/Share.svg" alt="share" />
              <span className={"tooltip"}>share</span>
            </button>

            <button data-target="history">
              <img src="/img/main/History2.svg" alt="history" />
              <span className={"tooltip"}>history</span>
            </button>

            <button data-target="account">
              <img src="img/main/Account.svg" alt="account" />
              <span className={"tooltip"}>Account</span>
            </button>

            <button data-target="create">
              <img src="img/main/Plus.svg" alt="plus" />
              <span className={"tooltip"}>One Pager</span>
            </button>

            <button data-target="notepad">
              <img src="img/main/Notepad.svg" alt="notepad" />
              notepad
            </button>
          </div>

          <div className={"header__video"}>
            <button className={"video-open"} onClick={this.bookBBCall}>
              <img src="img/main/video.svg" alt="watch" />
              Book a Brand Breakthrough call
            </button>
          </div>
        </header>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCanvasName: (user_id, canvas_id, list, name) => {
      dispatch(canvasActions.canvasNameUpdate(user_id, canvas_id, list, name));
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasDetails: state.canvasDetails,
    canvasList: state.canvasList,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
