import * as actionTypes from "./actionTypes";

// Update competition
export const updateCompetitionStart = () => {
  return {
    type: actionTypes.UPDATE_COMPETITION_INIT
  };
};

export const updateCompetition = (user_id, canvas_id, competition) => {
  return {
    type: actionTypes.UPDATE_COMPETITION,
    user_id: user_id,
    canvas_id: canvas_id,
    competition: competition
  };
};

export const updateCompetitionSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_COMPETITION_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCompetitionFail = message => {
  return {
    type: actionTypes.UPDATE_COMPETITION_FAILURE,
    message: message
  };
};

// Set updated competition in store
export const setCompetition = competition => {
  return {
    type: actionTypes.SET_COMPETITION,
    competition: competition
  };
};
