import * as actionTypes from "../actionTypes";

// Update customer audience history
export const updateCustomerAudienceHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY_INIT
  };
};

export const updateCustomerAudienceHistory = (
  user_id,
  canvas_id,
  history_id,
  customer_audience
) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    customer_audience: customer_audience
  };
};

export const updateCustomerAudienceHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCustomerAudienceHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY_FAILURE,
    message: message
  };
};

// Update customer elevate history
export const updateCustomerElevateHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY_INIT
  };
};

export const updateCustomerElevateHistory = (
  user_id,
  canvas_id,
  history_id,
  customer_elevate
) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    customer_elevate: customer_elevate
  };
};

export const updateCustomerElevateHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCustomerElevateHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY_FAILURE,
    message: message
  };
};

// Update community history
export const updateCommunityHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_HISTORY_INIT
  };
};

export const updateCommunityHistory = (
  user_id,
  canvas_id,
  history_id,
  community
) => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    community: community
  };
};

export const updateCommunityHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCommunityHistoryFailure = message => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_HISTORY_FAILURE,
    message: message
  };
};
