import React from "react";

const input = props => {
  return (
    <input
      id={props.id}
      value={props.value}
      placeholder={props.placeholder}
      onChange={event => props.changeHandler(props.field, event)}
      onBlur={() => props.focusout(props.field)}
      onClick={() => props.clickHandler()}
      ref={props.reff}
    />
  );
};

export default input;
