import React from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { Link } from "react-router-dom";

const notFound = () => {
  return (
    <Aux>
      <div className={"error404"}>
        <h2>Page not found</h2>
        <Link to="/" className={"error404__to-home"}>
          Go back home
        </Link>
      </div>
    </Aux>
  );
};

export default notFound;
