import React, { Component } from "react";
import Aux from "../Auxilary/Auxilary";
import { connect } from "react-redux";
import * as canvasActions from "../../actions/canvas.action";

class Share extends Component {
  state = {
    isOpened: false,
    index: null,
    canvas_id: null,
    show: false
  };

  componentDidMount() {
    let path = this.props.match.path;
    let token = this.props.match.params.token;
    if (path.includes("invitation")) {
      this.props.canvasCopy(token);
    }
  }

  render() {
    if (
      this.props.canvasCopyInvitation.copied === "copied" &&
      this.props.canvasCopyInvitation.status === true
    ) {
      if (
        this.props.login.isLoggedIn === true ||
        localStorage.getItem("token")
      ) {
        this.props.history.push("/dashboard");
      } else {
        this.props.history.push("/login");
      }
    }

    return (
      <Aux>
        <div className="processing">
          {this.props.canvasCopyInvitation.loading === true ? (
            <h2 className={"show"}> Please wait...</h2>
          ) : null}

          {this.props.canvasCopyInvitation.loading === false &&
          this.props.canvasCopyInvitation.status === true ? (
            <h2 className={"show"}>
              {this.props.canvasCopyInvitation.message}
            </h2>
          ) : null}

          {this.props.canvasCopyInvitation.loading === false &&
          this.props.canvasCopyInvitation.error === true ? (
            <h2 className={"show"}>
              {this.props.canvasCopyInvitation.message}
            </h2>
          ) : null}
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    canvasCopy: token => {
      dispatch(canvasActions.canvasCopyInvitation(token));
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasCopyInvitation: state.canvasCopyInvitation,
    login: state.login
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Share);
