import { takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { signupSaga, checkUserInListSaga } from "./signup.saga";
import { loginSaga } from "./login.saga";
import {
  resetInitiateSaga,
  verifyInitiateSaga,
  resetPasswordSaga
} from "./forgotPassword.saga";

import { accountDetailsSaga, updateAccountSaga } from "./account.saga";
import {
  createCanvasSaga,
  canvasListSaga,
  canvasDetailsSaga,
  addCommentSaga,
  commentListSaga,
  collaborateCommentListSaga,
  deleteCanvasSaga,
  updateCanvasNameSaga,
  sendCanvasSaga,
  updateNoteSaga,
  canvasDetailsViewSaga,
  canvasCopyInvitationSaga,
  canvasCollaborateAddCommentSaga
} from "./canvas.saga";

import {
  updateCorePurposeSaga,
  updateCoreEssenceSaga,
  updateCoreValuesSaga
} from "./canvas.core.saga";

import {
  updateCustomerAudienceSaga,
  updateCustomerElevateSaga,
  updateCommunitySaga
} from "./canvas.customer.saga.js";

import { updateCompetitionSaga } from "./canvas.competition.saga";
import {
  updateContentStorySaga,
  updateContentCalendarSaga
} from "./canvas.content.saga";

import {
  updateCreativesSaga,
  uploadLogoSaga,
  uploadPackageSaga,
  addColorSaga,
  deleteColorSaga,
  deleteCreativeFileSaga
} from "./canvas.creatives.saga";

import {
  updateChannelTextOwnedSaga,
  updateChannelTextSharedSaga,
  updateChannelTextPaidSaga
} from "./canvas.channelText.saga";

import { updateChannelTextValueSaga } from "./canvas.channelTextValue.saga";

import {
  canvasHistoryListSaga,
  canvasHistoryDetailsSaga,
  canvasHistoryListLoadMoreSaga
} from "./history/history.saga";

import {
  updateCorePurposeHistorySaga,
  updateCoreValuesHistorySaga,
  updateCoreEssenceHistorySaga
} from "./history/core.history.saga";

import {
  updateCustomerAudienceHistorySaga,
  updateCustomerElevateHistorySaga,
  updateCommunityHistorySaga
} from "./history/customer.history.saga";

import { updateCompetitionHistorySaga } from "./history/competition.history.saga";
import {
  updateCreativesHistorySaga,
  uploadLogoHistorySaga,
  uploadPackageHistorySaga,
  addColorHistorySaga,
  deleteColorHistorySaga,
  deleteHistoryCreateFileSaga
} from "./history/creatives.history.saga";

import {
  updateContentStoryHistorySaga,
  updateContentCalendarHistorySaga
} from "./history/content.history.saga";

import {
  updateChannelTextOwnedHistorySaga,
  updateChannelTextSharedHistorySaga,
  updateChannelTextPaidHistorySaga
} from "./history/channelText.history.saga";

import { updateChannelTextValueHistorySaga } from "./history/channelTextValue.history.saga";

export function* watchers() {
  // Sign up
  yield takeEvery(actionTypes.SIGNUP, signupSaga);
  yield takeEvery(actionTypes.CHECK_USER_IN_LIST, checkUserInListSaga);

  // Login
  yield takeEvery(actionTypes.LOGIN, loginSaga);

  // Reset password
  yield takeEvery(actionTypes.INITIATE_PROCESS, resetInitiateSaga);
  yield takeEvery(actionTypes.VERIFICATION_PROCESS, verifyInitiateSaga);
  yield takeEvery(actionTypes.RESET_PROCESS, resetPasswordSaga);

  // Account
  yield takeEvery(actionTypes.ACCOUNT_DETAILS_FETCH, accountDetailsSaga);
  yield takeEvery(actionTypes.ACCOUNT_UPDATE, updateAccountSaga);

  // Canvas
  yield takeEvery(actionTypes.CREATE_CANVAS, createCanvasSaga);
  yield takeEvery(actionTypes.CANVAS_LIST_FETCH, canvasListSaga);
  yield takeEvery(actionTypes.CANVAS_DETAILS_FETCH, canvasDetailsSaga);
  yield takeEvery(actionTypes.CANVAS_VIEW, canvasDetailsViewSaga);
  yield takeEvery(actionTypes.CANVAS_DELETE, deleteCanvasSaga);
  yield takeEvery(actionTypes.CANVAS_NAME_UPDATE, updateCanvasNameSaga);
  yield takeEvery(actionTypes.SEND_CANVAS, sendCanvasSaga);
  yield takeEvery(actionTypes.CANVAS_COPY_INVITATION, canvasCopyInvitationSaga);
  yield takeEvery(
    actionTypes.CANVAS_COLLABORATE_ADD_COMMENT,
    canvasCollaborateAddCommentSaga
  );

  // Update note/notepad
  yield takeEvery(actionTypes.UPDATE_NOTE, updateNoteSaga);

  // Update core purpose

  yield takeEvery(actionTypes.UPDATE_CORE_PURPOSE, updateCorePurposeSaga);

  // Update core essence
  yield takeEvery(actionTypes.UPDATE_CORE_ESSENCE, updateCoreEssenceSaga);

  // Update core values
  yield takeEvery(actionTypes.UPDATE_CORE_VALUES, updateCoreValuesSaga);

  // Update customer audience
  yield takeEvery(
    actionTypes.UPDATE_CUSTOMER_AUDIENCE,
    updateCustomerAudienceSaga
  );

  // Update customer elevate
  yield takeEvery(
    actionTypes.UPDATE_CUSTOMER_ELEVATE,
    updateCustomerElevateSaga
  );

  // Update community
  yield takeEvery(actionTypes.UPDATE_COMMUNITY, updateCommunitySaga);

  // Update competition
  yield takeEvery(actionTypes.UPDATE_COMPETITION, updateCompetitionSaga);

  // Update content story
  yield takeEvery(actionTypes.UPDATE_CONTENT_STORY, updateContentStorySaga);

  // Update content story
  yield takeEvery(
    actionTypes.UPDATE_CONTENT_CALENDAR,
    updateContentCalendarSaga
  );

  // Update creatives
  yield takeEvery(actionTypes.UPDATE_CREATIVES, updateCreativesSaga);
  yield takeEvery(actionTypes.UPLOAD_LOGO, uploadLogoSaga);
  yield takeEvery(actionTypes.UPLOAD_PACKAGE, uploadPackageSaga);
  yield takeEvery(actionTypes.ADD_COLOR, addColorSaga);
  yield takeEvery(actionTypes.DELETE_COLOR, deleteColorSaga);
  yield takeEvery(actionTypes.DELETE_CREATIVE_FILE, deleteCreativeFileSaga);

  // Channel text owned
  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_OWNED,
    updateChannelTextOwnedSaga
  );

  // Channel text shared
  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_SHARED,
    updateChannelTextSharedSaga
  );

  // Channel text paid
  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_PAID,
    updateChannelTextPaidSaga
  );

  // Channel text value
  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_VALUE,
    updateChannelTextValueSaga
  );

  // Comment
  yield takeEvery(actionTypes.ADD_COMMENT, addCommentSaga);
  yield takeEvery(actionTypes.GET_COMMENT, commentListSaga);
  yield takeEvery(
    actionTypes.GET_COLLABORATE_COMMENT,
    collaborateCommentListSaga
  );

  // Canvas history list
  yield takeEvery(actionTypes.CANVAS_HISTORY_LIST, canvasHistoryListSaga);
  yield takeEvery(actionTypes.CANVAS_HISTORY_DETAILS, canvasHistoryDetailsSaga);
  yield takeEvery(
    actionTypes.CANVAS_HISTORY_LIST_LOAD_MORE,
    canvasHistoryListLoadMoreSaga
  );

  /***************************************************************************************
   * Canvas history update
   **************************************************************************************/
  yield takeEvery(
    actionTypes.UPDATE_CORE_PURPOSE_HISTORY,
    updateCorePurposeHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CORE_VALUES_HISTORY,
    updateCoreValuesHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CORE_ESSENCE_HISTORY,
    updateCoreEssenceHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY,
    updateCustomerAudienceHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY,
    updateCustomerElevateHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_COMMUNITY_HISTORY,
    updateCommunityHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_COMPETITION_HISTORY,
    updateCompetitionHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CREATIVES_HISTORY,
    updateCreativesHistorySaga
  );

  // History logo
  yield takeEvery(actionTypes.UPLOAD_LOGO_HISTORY, uploadLogoHistorySaga);
  yield takeEvery(actionTypes.UPLOAD_PACKAGE_HISTORY, uploadPackageHistorySaga);
  yield takeEvery(actionTypes.ADD_COLOR_HISTORY, addColorHistorySaga);
  yield takeEvery(actionTypes.DELETE_COLOR_HISTORY, deleteColorHistorySaga);
  yield takeEvery(actionTypes.DELETE_HISTORY_CREATIVE_FILE, deleteHistoryCreateFileSaga);

  yield takeEvery(
    actionTypes.UPDATE_CONTENT_STORY_HISTORY,
    updateContentStoryHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY,
    updateContentCalendarHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY,
    updateChannelTextOwnedHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY,
    updateChannelTextSharedHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY,
    updateChannelTextPaidHistorySaga
  );

  yield takeEvery(
    actionTypes.UPDATE_CHANNEL_TEXT_VALUE_HISTORY,
    updateChannelTextValueHistorySaga
  );
}
