import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/creatives.history.action";
import * as creativesAction from "../../actions/canvas.creatives.action";
import * as canvasActions from "../../actions/canvas.creatives.action";
import * as notification from "../../actions/notification.action";
import axios from "../../config/axios";

// Update creatives history
export function* updateCreativesHistorySaga(action) {
  yield put(actions.updateCreativesHistoryStart());
  const data = {
    user_id: action.user_id,
    naming: action.naming,
    domain: action.domain,
    typography: action.typography,
    logo: action.logo,
    packaging: action.packaging,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/creatives`,
      data
    );
    yield put(
      actions.updateCreativesHistorySuccess(response.status, response.message)
    );
    yield put(
      creativesAction.setCreatives(
        action.naming,
        action.domain,
        action.typography,
        action.logo,
        action.packaging
      )
    );
  } catch (e) {
    yield put(actions.updateCreativesHistoryFail(e.message));
  }
}

// Upload logo
export function* uploadLogoHistorySaga(action) {
  yield put(actions.uploadLogoHistoryStart());
  yield put(
    notification.setNotification(
      true,
      false,
      "Image upload in progress"
    )
  );
  let formData = new FormData();
  formData.append("user_id", action.user_id);
  formData.append("canvas_id", action.canvas_id);
  formData.append("history_id", action.history_id);
  formData.append("logo", action.file);

  try {
    let response = yield axios.post("/canvas/history/upload-logo", formData);
    yield put(
      actions.uploadLogoHistorySuccess(response.message, response.data)
    );
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
    yield put(canvasActions.setLogo(response.data));
  } catch (e) {
    yield put(actions.uploadLogoHistoryFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Upload package
export function* uploadPackageHistorySaga(action) {
  yield put(actions.uploadPackageHistoryStart());
  yield put(
    notification.setNotification(
      true,
      false,
      "Image upload in progress"
    )
  );
  let formData = new FormData();
  formData.append("user_id", action.user_id);
  formData.append("canvas_id", action.canvas_id);
  formData.append("history_id", action.history_id);
  formData.append("packaging", action.file);

  try {
    let response = yield axios.post("/canvas/history/upload-package", formData);
    yield put(
      actions.uploadPackageHistorySuccess(response.message, response.data)
    );
    yield put(canvasActions.setPackaging(response.data));
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
  } catch (e) {
    yield put(actions.uploadPackageHistoryFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Add history color
export function* addColorHistorySaga(action) {
  yield put(actions.addColorHistoryStart());

  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    color: action.color,
    history_id: action.history_id
  };

  try {
    let response = yield axios.post("/canvas/history/add-color", data);
    yield put(actions.addColorHistorySuccess(response.message, response.data));
    yield put(canvasActions.updateColorList(action.list, action.color));
  } catch (e) {
    yield put(actions.addColorHistoryFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Delete color
export function* deleteColorHistorySaga(action) {
  yield put(actions.deleteColorHistoryStart());

  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    color: action.color,
    history_id: action.history_id
  };

  try {
    let response = yield axios.post("/canvas/history/delete-color", data);
    yield put(actions.deleteColorHistorySuccess(response.message));
    yield put(canvasActions.removeColorFromList(action.list, action.color));
    yield put(notification.setNotification(true, false, response.message));
  } catch (e) {
    yield put(actions.deleteColorHistoryFail(e.message));
  }
}

// Delete logo/package
export function* deleteHistoryCreateFileSaga(action) {
  yield put(actions.deleteHistoryCreativeFileStart());

  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    history_id: action.history_id,
    type: action.fileType,
    path: action.path
  };

  try {
    let response = yield axios.post("/canvas/history/delete-creative-file", data);
    yield put(actions.deleteHistoryCreativeFileSuccess(response.message));

    if (action.fileType === "logo") {
      yield put(canvasActions.setLogo(''));
    } else if (action.fileType === "packaging") {
      yield put(canvasActions.setPackaging(''));
    }

    yield put(notification.setNotification(true, false, response.message));
  } catch (e) {
    yield put(actions.deleteHistoryCreativeFileFail(e.message));
  }
}
