import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/content.history.action";
import * as contentAction from "../../actions/canvas.content.action";
import axios from "../../config/axios";

// Update content story history
export function* updateContentStoryHistorySaga(action) {
  yield put(actions.updateContentStoryHistoryStart());
  const data = {
    user_id: action.user_id,
    history_id: action.history_id,
    content_brand: action.content_brand,
    content_story: action.content_story
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/content-story`,
      data
    );
    yield put(
      actions.updateContentStoryHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(
      contentAction.setContentStory(action.content_brand, action.content_story)
    );
  } catch (e) {
    yield put(actions.updateContentStoryHistoryFail(e.message));
  }
}

// Update content calendar history
export function* updateContentCalendarHistorySaga(action) {
  yield put(actions.updateContentCalendarHistoryStart());
  const data = {
    user_id: action.user_id,
    history_id: action.history_id,
    content_core: action.content_core,
    content_customer: action.content_customer,
    content_community: action.content_community,
    content_competition: action.content_competition,
    content_creatives: action.content_creatives
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/content-calendar`,
      data
    );
    yield put(
      actions.updateContentCalendarHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(
      contentAction.setContentCalendar(
        action.content_core,
        action.content_customer,
        action.content_community,
        action.content_competition,
        action.content_creatives
      )
    );
  } catch (e) {
    yield put(actions.updateContentCalendarHistoryFail(e.message));
  }
}
