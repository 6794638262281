import React, { Component } from "react";
import styles from "./Modal.module.css";
import Aux from "../../../hoc/Auxilary/Auxilary";
import Backdrop from "../Backdrop/Backdrop";
import Button from "../Button/Button";

class Modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );
  }

  componentWillUpdate() {}

  render() {
    return (
      <Aux>
        <Backdrop show={this.props.show} />
        <div
          className={styles.Modal}
          style={{
            transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
            opacity: this.props.show ? "1" : "0"
          }}
        >
          <div className={styles.ModalHeader}>{this.props.header}</div>
          <div className={styles.ModalContent}>{this.props.content} </div>
          <div className={styles.ModalFooter}>
            <Button btnType="Cancel" clicked={this.props.cancel}> Cancel </Button>
            <Button btnType="Danger" clicked={this.props.confirm}> Delete </Button>
          </div>
        </div>
      </Aux>
    );
  }
}

export default Modal;
