import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Public view store
const canvasViewState = {
  preview: false,
  collaborate: false,
  private: false,
  token: ""
};

export const canvasView = (state = canvasViewState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANVAS_PRIVATE:
      return updateObject(state, {
        private: true
      });

    case actionTypes.SET_CANVAS_PREVIEW:
      return updateObject(state, {
        preview: true,
        collaborate: false,
        token: action.token
      });

    case actionTypes.SET_CANVAS_COLLABORATE:
      return updateObject(state, {
        collaborate: true,
        preview: false,
        token: action.token
      });

    default: {
      return state;
    }
  }
};

// Collaborate add comment
const collaborateAddCommentState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const collaborateAddComment = (
  state = collaborateAddCommentState,
  action
) => {
  switch (action.type) {
    case actionTypes.CANVAS_COLLABORATE_ADD_COMMENT_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.CANVAS_COLLABORATE_ADD_COMMENT:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.CANVAS_COLLABORATE_ADD_COMMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message
      });

    case actionTypes.CANVAS_COLLABORATE_ADD_COMMENT_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
