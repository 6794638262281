import { put } from "redux-saga/effects";
import * as signupAction from "../actions/signup.action";
import axios from "../config/axios";

export function* signupSaga(action) {
  yield put(signupAction.signupStart());

  const signupData = {
    first_name: action.first_name,
    last_name: action.last_name,
    zip_code: action.zip_code,
    email: action.email,
    password: action.password
  };

  try {
    const response = yield axios.post("sign-up", signupData);
    yield put(signupAction.signupSuccess(response.message));
  } catch (error) {
    yield put(signupAction.signupFail(error.message));
  }
}

// Check user present in the list
export function* checkUserInListSaga(action) {
  yield put(signupAction.checkUserInListStart());

  const validateData = {
    email: action.email
  };

  try {
    const response = yield axios.post("sign-up/validate", validateData);
    yield put(signupAction.checkUserInListSuccess(response.message));
  } catch (error) {
    yield put(signupAction.checkUserInListFail(error.message));
  }
}
