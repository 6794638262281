import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Channel text owned store
const channelTextOwnedState = {
  canvas_id: null,
  history_id: null,
  channel_text_owned_text1: "",
  channel_text_owned_text2: "",
  channel_text_owned_text3: "",
  channel_text_owned_text4: "",
  channel_text_owned_text5: "",
  status: "idle",
  loading: false,
  message: null,
  error: null
};

export const channelTextOwned = (state = channelTextOwnedState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANNEL_TEXT_OWNED_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          channel_text_owned_text1: action.details.channel_text_owned_text1,
          channel_text_owned_text2: action.details.channel_text_owned_text2,
          channel_text_owned_text3: action.details.channel_text_owned_text3,
          channel_text_owned_text4: action.details.channel_text_owned_text4,
          channel_text_owned_text5: action.details.channel_text_owned_text5
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          channel_text_owned_text1: action.details.channel_text_owned_text1,
          channel_text_owned_text2: action.details.channel_text_owned_text2,
          channel_text_owned_text3: action.details.channel_text_owned_text3,
          channel_text_owned_text4: action.details.channel_text_owned_text4,
          channel_text_owned_text5: action.details.channel_text_owned_text5
        });
      }

    case actionTypes.CLEAR_CHANNEL_TEXT_OWNED_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        channel_text_owned_text1: "",
        channel_text_owned_text2: "",
        channel_text_owned_text3: "",
        channel_text_owned_text4: "",
        channel_text_owned_text5: ""
      });

    case actionTypes.SET_CHANNEL_TEXT_OWNED:
      return updateObject(state, {
        channel_text_owned_text1: action.channel_text_owned_text1,
        channel_text_owned_text2: action.channel_text_owned_text2,
        channel_text_owned_text3: action.channel_text_owned_text3,
        channel_text_owned_text4: action.channel_text_owned_text4,
        channel_text_owned_text5: action.channel_text_owned_text5
      });

    default: {
      return state;
    }
  }
};

// Channel text shared store
const channelTextSharedState = {
  canvas_id: null,
  history_id: null,
  channel_text_shared_text1: "",
  channel_text_shared_text2: "",
  channel_text_shared_text3: "",
  channel_text_shared_text4: "",
  channel_text_shared_text5: "",
  status: "idle",
  loading: false,
  message: null,
  error: null
};

export const channelTextShared = (state = channelTextSharedState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANNEL_TEXT_SHARED_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          channel_text_shared_text1: action.details.channel_text_shared_text1,
          channel_text_shared_text2: action.details.channel_text_shared_text2,
          channel_text_shared_text3: action.details.channel_text_shared_text3,
          channel_text_shared_text4: action.details.channel_text_shared_text4,
          channel_text_shared_text5: action.details.channel_text_shared_text5
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          channel_text_shared_text1: action.details.channel_text_shared_text1,
          channel_text_shared_text2: action.details.channel_text_shared_text2,
          channel_text_shared_text3: action.details.channel_text_shared_text3,
          channel_text_shared_text4: action.details.channel_text_shared_text4,
          channel_text_shared_text5: action.details.channel_text_shared_text5
        });
      }

    case actionTypes.CLEAR_CHANNEL_TEXT_SHARED_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        channel_text_shared_text1: "",
        channel_text_shared_text2: "",
        channel_text_shared_text3: "",
        channel_text_shared_text4: "",
        channel_text_shared_text5: ""
      });

    case actionTypes.SET_CHANNEL_TEXT_SHARED:
      return updateObject(state, {
        channel_text_shared_text1: action.channel_text_shared_text1,
        channel_text_shared_text2: action.channel_text_shared_text2,
        channel_text_shared_text3: action.channel_text_shared_text3,
        channel_text_shared_text4: action.channel_text_shared_text4,
        channel_text_shared_text5: action.channel_text_shared_text5
      });

    default: {
      return state;
    }
  }
};

// Channel text paid store
const channelTextPaidState = {
  canvas_id: null,
  history_id: null,
  channel_text_paid_text1: "",
  channel_text_paid_text2: "",
  channel_text_paid_text3: "",
  channel_text_paid_text4: "",
  channel_text_paid_text5: "",
  status: "idle",
  loading: false,
  message: null,
  error: null
};

export const channelTextPaid = (state = channelTextPaidState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANNEL_TEXT_PAID_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          channel_text_paid_text1: action.details.channel_text_paid_text1,
          channel_text_paid_text2: action.details.channel_text_paid_text2,
          channel_text_paid_text3: action.details.channel_text_paid_text3,
          channel_text_paid_text4: action.details.channel_text_paid_text4,
          channel_text_paid_text5: action.details.channel_text_paid_text5
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          channel_text_paid_text1: action.details.channel_text_paid_text1,
          channel_text_paid_text2: action.details.channel_text_paid_text2,
          channel_text_paid_text3: action.details.channel_text_paid_text3,
          channel_text_paid_text4: action.details.channel_text_paid_text4,
          channel_text_paid_text5: action.details.channel_text_paid_text5
        });
      }

    case actionTypes.CLEAR_CHANNEL_TEXT_PAID_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        channel_text_paid_text1: "",
        channel_text_paid_text2: "",
        channel_text_paid_text3: "",
        channel_text_paid_text4: "",
        channel_text_paid_text5: ""
      });

    case actionTypes.SET_CHANNEL_TEXT_PAID:
      return updateObject(state, {
        channel_text_paid_text1: action.channel_text_paid_text1,
        channel_text_paid_text2: action.channel_text_paid_text2,
        channel_text_paid_text3: action.channel_text_paid_text3,
        channel_text_paid_text4: action.channel_text_paid_text4,
        channel_text_paid_text5: action.channel_text_paid_text5
      });

    default: {
      return state;
    }
  }
};

// Update channel text owned
const updateChannelTextOwnedState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateChannelTextOwned = (
  state = updateChannelTextOwnedState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CHANNEL_TEXT_OWNED_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_OWNED_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_OWNED_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update channel text shared
const updateChannelTextSharedState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateChannelTextShared = (
  state = updateChannelTextSharedState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CHANNEL_TEXT_SHARED_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_SHARED_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_SHARED_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update channel text paid
const updateChannelTextPaidState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateChannelTextPaid = (
  state = updateChannelTextPaidState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CHANNEL_TEXT_PAID_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_PAID_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_PAID_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
