import React, { Component } from "react";
import { connect } from "react-redux";
import Aux from "../../../../hoc/Auxilary/Auxilary";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as createCanvasAction from "../../../../actions/canvas.action";
import { getUserIdFromToken } from "../../../../shared/utils/utils";
import * as canvasActions from "../../../../actions/canvas.action";

class CreateCanvas extends Component {
  constructor(props) {
    super(props);
    this.sidebarCloseRef = React.createRef();
    this.count = 0;
  }

  closeSidebar = () => {
    this.props.resetCreateCanvas();
    this.setState({ name: null });
  };

  state = {
    name: null
  };

  render() {
    const createCanvasSchema = Yup.object().shape({
      name: Yup.string().required("Canvas name is required")
    });

    let createCanvasForm = (
      <Formik
        initialValues={{ name: "" }}
        validationSchema={createCanvasSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const user_id = getUserIdFromToken();
          this.setState({ name: values.name });
          let canvasList = this.props.canvasList.canvas;
          this.props.createCanvas(user_id, values.name, canvasList);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field type="text" name="name" placeholder="" />
            <ErrorMessage
              className="validation__error"
              name="name"
              component="div"
            />
            <input type="submit" value="CREATE ONE PAGER" />
          </Form>
        )}
      </Formik>
    );

    return (
      <Aux>
        <div className={"create"} id="create">
          <div className={"create__title"}>
            <h4> </h4>

            <div className={"sidebar__close"} ref={this.sidebarCloseRef}>
              <button onClick={this.closeSidebar} >
                x
              </button>
            </div>
          </div>

          <div className={"create__content"}>
            <h3>CREATE A NEW ONE PAGER</h3>

            <p>Give your One Pager a name</p>

            {createCanvasForm}
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createCanvas: (user_id, name, list) => {
      dispatch(createCanvasAction.createCanvas(user_id, name, list));
    },
    resetCreateCanvas: () => {
      dispatch(createCanvasAction.resetcreateCanvas());
    },
    getCanvasDetails: (user_id, canvas_id) => {
      dispatch(canvasActions.canvasDetailsFetch(user_id, canvas_id));
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasCreate: state.createCanvas,
    canvasList: state.canvasList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCanvas);
