import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/core.history.action";
import * as coreAction from "../../actions/canvas.core.action";
import * as notification from "../../actions/notification.action";
import axios from "../../config/axios";

// Update core purpose history
export function* updateCorePurposeHistorySaga(action) {
  yield put(actions.updateCorePurposeHistoryStart());
  const data = {
    user_id: action.user_id,
    core_purpose: action.core_purpose,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/core-purpose`,
      data
    );
    yield put(
      actions.updateCorePurposeHistorySuccess(response.status, response.message)
    );
    yield put(coreAction.setCorePurpose(action.core_purpose));
    yield put(
      notification.setNotification(response.status, false, response.message, false)
    );
  } catch (e) {
    yield put(actions.updateCorePurposeHistoryFail(e.message));
  }
}

// Update core values history
export function* updateCoreValuesHistorySaga(action) {
  yield put(actions.updateCoreValuesHistoryStart());
  const data = {
    user_id: action.user_id,
    core_values: action.core_values,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/core-values`,
      data
    );
    yield put(
      actions.updateCoreValuesHistorySuccess(response.status, response.message)
    );
    yield put(
      notification.setNotification(response.status, false, response.message, false)
    );
    yield put(coreAction.setCoreValues(action.core_values));
  } catch (e) {
    yield put(actions.updateCoreValuesHistoryFail(e.message));
  }
}

// Update core essence history
export function* updateCoreEssenceHistorySaga(action) {
  yield put(actions.updateCoreEssenceHistoryStart());
  const data = {
    user_id: action.user_id,
    core_essence: action.core_essence,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/core-essence`,
      data
    );
    yield put(
      actions.updateCoreEssenceHistorySuccess(response.status, response.message)
    );
    yield put(coreAction.setCoreEssence(action.core_essence));
  } catch (e) {
    yield put(actions.updateCoreEssenceHistoryFail(e.message));
  }
}
