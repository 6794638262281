import React, { Component } from "react";
import Aux from "../../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import { getUserIdFromToken } from "../../../../shared/utils/utils";
import * as canvasActions from "../../../../actions/canvas.action";
import * as historyActions from "../../../../actions/history/canvas.history.action";
import InfiniteScroll from "react-infinite-scroll-component";

class History extends Component {
  state = {
    canvas_id: null,
    history: null,
    history_id: null,
    page: 0,
    hasMore: true,
    user_id: getUserIdFromToken(),
    updatedAt: ""
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.canvasDelete.status === "deleted" &&
      this.props.canvasList.canvas.length === 0
    ) {
      this.props.resetCanvasDelete();
      this.setState({ history: null });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.canvasHistoryList !== this.props.canvasHistoryList) {
      this.setState({
        canvas_id: nextProps.canvasHistoryList.canvas_id,
        history: nextProps.canvasHistoryList.history,
        hasMore: nextProps.canvasHistoryList.hasMore
      });
    }

    if (nextProps.canvasHistoryList.caller === true) {
      this.setState({ page: 0 });
    }

    if (nextProps.canvasDetails.history_id === null) {
      this.setState({
        history_id: null,
        updatedAt: nextProps.canvasDetails.updated_at
      });
    }
  }

  historyDetails = (history_id, canvas_id) => {
    this.setState({ history_id: history_id });
    this.props.getHistoryDetails(this.state.user_id, canvas_id, history_id);
  };

  canvasDetails = () => {
    this.setState({ history_id: null });
    this.props.getCanvasDetails(this.state.user_id, this.state.canvas_id);
  };

  loadMore = () => {
    this.setState({
      page: Number(this.state.page) + Number(1),
      hasMore: false
    });

    setTimeout(
      function() {
        this.props.getMoreHistoryList(
          this.state.user_id,
          this.state.canvas_id,
          this.props.canvasHistoryList.history,
          this.state.page
        );
      }.bind(this),
      1000
    );
  };

  render() {
    const { history, hasMore } = this.state;

    let style = {
      height: "700px",
      overflow: "scroll"
    };

    let item;
    let len;
    if (history !== null) {
      if (history.length > 0) {
        len = Number(history.length);
        let list = history;
        item = list.map((history, index) => {
          let activeClass;
          if (history._id === this.state.history_id) {
            activeClass = "active";
          }

          return (
            <div
              key={index}
              className={activeClass}
              onClick={() =>
                this.historyDetails(history._id, history.canvas_id)
              }
            >
              <h3>{history.date}</h3> &nbsp; | {history.time}
            </div>
          );
        });
      }
    }

    return (
      <Aux>
        <div className={"history"} id="history">
          <div className={"history__title"}>
            <h2>History</h2>
            <div className={"sidebar__close"}>
              <button>x</button>
            </div>
          </div>

          {history !== null ? (
            history.length > 0 ? (
              <div
                className={"history__current"}
                onClick={() => this.canvasDetails()}
              >
                Current version {this.state.updatedAt}
              </div>
            ) : null
          ) : null}

          {history !== null ? (
            history.length > 0 ? (
              <div
                id="historyListLoader"
                className={"history__list"}
                style={style}
                // key={this.props.canvasDetails.updated_at}
              >
                <InfiniteScroll
                  dataLength={len}
                  next={this.loadMore}
                  hasMore={hasMore}
                  scrollableTarget="historyListLoader"
                  useWindow={true}
                >
                  {item}
                </InfiniteScroll>
              </div>
            ) : (
              <div className={"history__current-empty"}>No history data</div>
            )
          ) : null}
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMoreHistoryList: (user_id, canvas_id, list, page) => {
      dispatch(
        historyActions.canvasHistoryListLoadMore(user_id, canvas_id, list, page)
      );
    },
    getHistoryDetails: (user_id, canvas_id, history_id) => {
      dispatch(
        historyActions.canvasHistoryDetailsFetch(user_id, canvas_id, history_id)
      );
    },
    getCanvasDetails: (user_id, canvas_id) => {
      dispatch(canvasActions.canvasDetailsFetch(user_id, canvas_id));
    },
    resetCanvasDelete: () => {
      dispatch(canvasActions.canvasDeleteReset());
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasDetails: state.canvasDetails,
    canvasHistoryList: state.canvasHistoryList,
    canvasDelete: state.canvasDelete,
    canvasList: state.canvasList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
