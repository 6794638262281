import React, { Component } from "react";
import { connect } from "react-redux";
import * as coreActions from "../../../actions/canvas.core.action";
import * as historyActions from "../../../actions/history/core.history.action";
import Aux from "../../../hoc/Auxilary/Auxilary";
import TextArea from "../../UI/TextArea/TextArea";
import Essence from "./Essence";
import { getUserIdFromToken } from "../../../shared/utils/utils";

class Core extends Component {
  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    core_purpose: "",
    core_essence: "",
    core_values: "",
    clickedSaveBtn: false,
    clickedCancelBtn: false,
    showSuccess: false,
    identifier: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.core !== this.props.core) {
      this.setState({
        canvas_id: nextProps.core.canvas_id,
        history_id: nextProps.core.history_id,
        core_purpose: nextProps.core.core_purpose,
        core_essence: nextProps.core.core_essence,
        core_values: nextProps.core.core_values
      });
    }

    if (
      (nextProps.UpdateCorePurpose.status === "completed" &&
        nextProps.UpdateCorePurpose.status !==
          this.props.UpdateCorePurpose.status) ||
      (nextProps.UpdateCoreValues.status === "completed" &&
        nextProps.UpdateCoreValues.status !==
          this.props.UpdateCoreValues.status) ||
      (nextProps.UpdateCoreEssence.status === "completed" &&
        nextProps.UpdateCoreEssence.status !==
          this.props.UpdateCoreEssence.status)
    ) {
      this.setState({ showSuccess: true, identifier: this.state.field });
    }
  }

  // Save
  save = (field, value) => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      this.setState({ showSuccess: false, field: field });
      if (field === "core_purpose") {
        if (this.state.history_id !== null) {
          this.props.updateCorePurposeHistory(
            this.state.user_id,
            this.state.canvas_id,
            this.state.history_id,
            value
          );
        } else {
          this.props.updateCorePurpose(
            this.state.user_id,
            this.state.canvas_id,
            value
          );
        }
      } else if (field === "core_essence") {
        if (this.state.history_id !== null) {
          this.props.updateCoreEssenceHistory(
            this.state.user_id,
            this.state.canvas_id,
            this.state.history_id,
            value
          );
        } else {
          this.props.updateCoreEssence(
            this.state.user_id,
            this.state.canvas_id,
            value
          );
        }
      } else if (field === "core_values") {
        if (this.state.history_id !== null) {
          this.props.updateCoreValuesHistory(
            this.state.user_id,
            this.state.canvas_id,
            this.state.history_id,
            value
          );
        } else {
          this.props.updateCoreValues(
            this.state.user_id,
            this.state.canvas_id,
            value
          );
        }
      }
    }
  };

  render() {
    return (
      <Aux>
        <div className={"content__element"}>
          <div className={"content__title"}>
            <h2>
              <img src="../img/main/Core.svg" alt="Core" />
              Core
            </h2>
          </div>

          <div className={"content__group-wrapper group-third"}>
            <TextArea
              nextClass={"group-short"}
              canvas_id={this.state.canvas_id}
              id={"core_purpose"}
              commentSection={"core_purpose"}
              identifier={this.state.field}
              field={"core_purpose"}
              title={"WHY DO YOU EXIST? PURPOSE."}
              value={this.state.core_purpose}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={400}
              alertLimit={25}
              commentBoxPosition={"right"}
            />
          </div>

          <div className={"content__group-wrapper group-third"}>
            <Essence
              field={"core_essence"}
              id={"core_essencee"}
              value={this.state.core_essence}
              canvas_id={this.state.canvas_id}
              save={this.save}
              identifier={this.state.field}
              showComment={this.showComment}
              commentSection={"core_essence"}
              showSuccess={this.state.showSuccess}
              maxLimit={55}
              alertLimit={5}
              commentBoxPosition={"right"}
            />
          </div>

          <div className={"content__group-wrapper group-third"}>
            <TextArea
              nextClass={"group-short"}
              id={"core_values"}
              commentSection={"core_values"}
              canvas_id={this.state.canvas_id}
              identifier={this.state.field}
              field={"core_values"}
              title={"WHAT DO YOU STAND FOR? VALUES."}
              value={this.state.core_values}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              save={this.save}
              showComment={this.showComment}
              showSuccess={this.state.showSuccess}
              maxLimit={600}
              alertLimit={25}
              commentBoxPosition={"right"}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCorePurpose: (user_id, canvas_id, core_purpose) => {
      dispatch(coreActions.updateCorePurpose(user_id, canvas_id, core_purpose));
    },
    updateCoreEssence: (user_id, canvas_id, core_essence) => {
      dispatch(coreActions.updateCoreEssence(user_id, canvas_id, core_essence));
    },
    updateCoreValues: (user_id, canvas_id, core_values) => {
      dispatch(coreActions.updateCoreValues(user_id, canvas_id, core_values));
    },
    updateCorePurposeHistory: (
      user_id,
      canvas_id,
      history_id,
      core_purpose
    ) => {
      dispatch(
        historyActions.updateCorePurposeHistory(
          user_id,
          canvas_id,
          history_id,
          core_purpose
        )
      );
    },
    updateCoreEssenceHistory: (
      user_id,
      canvas_id,
      history_id,
      core_essence
    ) => {
      dispatch(
        historyActions.updateCoreEssenceHistory(
          user_id,
          canvas_id,
          history_id,
          core_essence
        )
      );
    },
    updateCoreValuesHistory: (user_id, canvas_id, history_id, core_values) => {
      dispatch(
        historyActions.updateCoreValuesHistory(
          user_id,
          canvas_id,
          history_id,
          core_values
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    core: state.core,
    UpdateCorePurpose: state.updateCorePurpose,
    UpdateCoreValues: state.updateCoreValues,
    UpdateCoreEssence: state.updateCoreEssence,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Core);
