import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";

class SingleInput extends Component {
  state = {
    field: this.props.field,
    value: this.props.value,
    maxLimit: this.props.maxLimit,
    alertLimit: this.props.alertLimit,
    alertLength: 0,
    showAlert: this.props.showAlert,
    reverseAlert: this.props.reverseAlert
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value
      });
    }

    if (
      nextProps.showAlert === this.props.showAlert &&
      nextProps.reverseAlert !== this.props.reverseAlert
    ) {
      this.setState({
        showAlert: nextProps.showAlert
      });
    }
  }

  // Allow only numbers
  validateNumber(e) {
    if (this.props.numberValidation === true) {
      if (
        [46, 8, 9, 27, 13, 107, 109, 189].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (
        (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
        (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }
    }
  }

  changeHandler = (field, event) => {
    const value = event.target.value;

    let valueLength = value.length;
    let alertLength = Number(this.state.maxLimit) - Number(valueLength);

    if (alertLength <= this.state.alertLimit) {
      this.setState({ showAlert: true, alertLength: alertLength });
    } else {
      this.setState({ showAlert: false });
    }

    this.props.onUpdate(this.props.field, event);
  };

  render() {
    return (
      <Aux>
        {this.state.showAlert === true ? (
          <span className={"symbols-left"}>{this.state.alertLength}</span>
        ) : null}
        <input
          type="text"
          className={this.props.inputClass}
          id={this.props.id}
          ref={this.props.inputRef}
          value={this.state.value}
          maxLength={this.props.maxLimit}
          disabled={this.props.disabled}
          onChange={event => this.changeHandler(this.props.field, event)}
          onKeyDown={this.validateNumber.bind(this)}
          onClick={this.props.clickHandler}
        />
      </Aux>
    );
  }
}

export default SingleInput;
