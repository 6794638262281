import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as competitionActions from "../../../actions/canvas.competition.action";
import * as historyActions from "../../../actions/history/competition.history.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import TextArea from "../../UI/TextArea/TextArea";

class Competition extends Component {
  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    competition: "",
    showSuccess: false,
    identifier: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.competition !== this.props.competition) {
      this.setState({
        canvas_id: nextProps.competition.canvas_id,
        history_id: nextProps.competition.history_id,
        competition: nextProps.competition.competition
      });
    }

    if (
      nextProps.UpdateCompetition.status === "completed" &&
      nextProps.UpdateCompetition.status !== this.props.UpdateCompetition.status
    ) {
      this.setState({ showSuccess: true, identifier: this.state.field });
    }
  }

  // Save function
  save = (field, value) => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      this.setState({ showSuccess: false, field: field });
      if (field === "competition") {
        if (this.state.history_id !== null) {
          this.props.updateCompetitionHistory(
            this.state.user_id,
            this.state.canvas_id,
            this.state.history_id,
            value
          );
        } else {
          this.props.updateCompetition(
            this.state.user_id,
            this.state.canvas_id,
            value
          );
        }
      }
    }
  };

  render() {
    return (
      <Aux>
        <div className={"content__element element-40"}>
          <div className={"content__title"}>
            <h2>
              <img src="/img/main/Competition.svg" alt="Competition" />
              Competition
            </h2>
          </div>

          <div className={"content__group-wrapper group-long"}>
            <TextArea
              nextClass={""}
              id={"competition"}
              commentSection={"competition"}
              canvas_id={this.state.canvas_id}
              identifier={this.state.field}
              field={"competition"}
              title={"HOW ARE YOU UNIQUE?"}
              value={this.state.competition}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={800}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCompetition: (user_id, canvas_id, competition) => {
      dispatch(
        competitionActions.updateCompetition(user_id, canvas_id, competition)
      );
    },
    updateCompetitionHistory: (user_id, canvas_id, history_id, competition) => {
      dispatch(
        historyActions.updateCompetitionHistory(
          user_id,
          canvas_id,
          history_id,
          competition
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    competition: state.competition,
    UpdateCompetition: state.updateCompetition,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Competition);
