import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as customerActions from "../../../actions/canvas.customer.action";
import * as historyActions from "../../../actions/history/customer.history.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import TextArea from "../../UI/TextArea/TextArea";

class Customer extends Component {
  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    customer_audience: "",
    customer_elevate: "",
    community: "",
    showSuccess: false,
    identifier: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.customer !== this.props.customer) {
      this.setState({
        canvas_id: nextProps.customer.canvas_id,
        history_id: nextProps.customer.history_id,
        customer_audience: nextProps.customer.customer_audience,
        customer_elevate: nextProps.customer.customer_elevate,
        community: nextProps.customer.community
      });
    }

    if (
      (nextProps.UpdateCustomerAudience.status === "completed" &&
        nextProps.UpdateCustomerAudience.status !==
          this.props.UpdateCustomerAudience.status) ||
      (nextProps.UpdateCustomerElevate.status === "completed" &&
        nextProps.UpdateCustomerElevate.status !==
          this.props.UpdateCustomerElevate.status) ||
      (nextProps.UpdateCommunity.status === "completed" &&
        nextProps.UpdateCommunity.status !== this.props.UpdateCommunity.status)
    ) {
      this.setState({ showSuccess: true, identifier: this.state.field });
    }
  }

  // Save function
  save = (field, value) => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      this.setState({ [field]: value, showSuccess: false, field: field });
      setTimeout(
        function() {
          if (field === "customer_audience") {
            if (this.state.history_id !== null) {
              this.props.updateCustomerAudienceHistory(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                this.state.customer_audience
              );
            } else {
              this.props.updateCustomerAudience(
                this.state.user_id,
                this.state.canvas_id,
                this.state.customer_audience
              );
            }
          } else if (field === "customer_elevate") {
            if (this.state.history_id !== null) {
              this.props.updateCustomerElevateHistory(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                this.state.customer_elevate
              );
            } else {
              this.props.updateCustomerElevate(
                this.state.user_id,
                this.state.canvas_id,
                this.state.customer_elevate
              );
            }
          } else if (field === "community") {
            if (this.state.history_id !== null) {
              this.props.updateCommunityHistory(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                this.state.community
              );
            } else {
              this.props.updateCommunity(
                this.state.user_id,
                this.state.canvas_id,
                this.state.community
              );
            }
          }
        }.bind(this),
        500
      );
    }
  };

  render() {
    return (
      <Aux>
        <div className={"content__element element-60"}>
          <div className={"content__title"}>
            <h2>
              <img src="/img/main/Customer.svg" alt="Customer" />
              Customer
            </h2>
          </div>

          <div className={"content__group-wrapper group-short"}>
            <TextArea
              nextClass={"group-short"}
              id={"customer_audience"}
              canvas_id={this.state.canvas_id}
              commentSection={"customer_audience"}
              identifier={this.state.field}
              field={"customer_audience"}
              title={"WHO’S YOUR AUDIENCE?"}
              value={this.state.customer_audience}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={1000}
              alertLimit={25}
              commentBoxPosition={"right"}
            />
          </div>

          <div className={"content__group-wrapper group-short"}>
            <TextArea
              nextClass={"group-short"}
              id={"customer_elevate"}
              canvas_id={this.state.canvas_id}
              commentSection={"customer_elevate"}
              identifier={this.state.field}
              field={"customer_elevate"}
              title={"HOW DOES YOUR PRODUCT ELEVATE?"}
              value={this.state.customer_elevate}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={400}
              alertLimit={25}
              commentBoxPosition={"right"}
            />
          </div>
        </div>

        <div className={"content__element element-40"}>
          <div className={"content__title"}>
            <h2>
              <img src="/img/main/Community.svg" alt="Community" />
              Community
            </h2>
          </div>

          <div className={"content__group-wrapper group-long"}>
            <TextArea
              nextClass={"group-short"}
              id={"community"}
              canvas_id={this.state.canvas_id}
              commentSection={"community"}
              identifier={this.state.field}
              field={"community"}
              title={"WHAT CULTURAL GROUP DO WE IDENTIFY WITH?"}
              value={this.state.community}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={800}
              alertLimit={25}
              commentBoxPosition={"right"}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCustomerAudience: (user_id, canvas_id, customer_audience) => {
      dispatch(
        customerActions.updateCustomerAudience(
          user_id,
          canvas_id,
          customer_audience
        )
      );
    },
    updateCustomerElevate: (user_id, canvas_id, customer_elevate) => {
      dispatch(
        customerActions.updateCustomerElevate(
          user_id,
          canvas_id,
          customer_elevate
        )
      );
    },
    updateCommunity: (user_id, canvas_id, community) => {
      dispatch(customerActions.updateCommunity(user_id, canvas_id, community));
    },
    updateCustomerAudienceHistory: (
      user_id,
      canvas_id,
      history_id,
      customer_audience
    ) => {
      dispatch(
        historyActions.updateCustomerAudienceHistory(
          user_id,
          canvas_id,
          history_id,
          customer_audience
        )
      );
    },
    updateCustomerElevateHistory: (
      user_id,
      canvas_id,
      history_id,
      customer_elevate
    ) => {
      dispatch(
        historyActions.updateCustomerElevateHistory(
          user_id,
          canvas_id,
          history_id,
          customer_elevate
        )
      );
    },
    updateCommunityHistory: (user_id, canvas_id, history_id, community) => {
      dispatch(
        historyActions.updateCommunityHistory(
          user_id,
          canvas_id,
          history_id,
          community
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    customer: state.customer,
    UpdateCustomerAudience: state.updateCustomerAudience,
    UpdateCustomerElevate: state.updateCustomerElevate,
    UpdateCommunity: state.updateCommunity,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
