import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as loginActions from "../../../../actions/login.action";
import * as canvasActions from "../../../../actions/canvas.action";
import { connect } from "react-redux";

class Logout extends Component {
  constructor(props) {
    super(props);
    localStorage.clear();
  }

  componentDidMount() {
    this.props.logout();
    this.props.resetCanvasDetails();
    this.props.resetCoreData();
    this.props.resetCustomerData();
    this.props.resetCommunityData();
    this.props.resetCompetitionData();
    this.props.resetCreativesData();
    this.props.resetContentStoryData();
    this.props.resetContentCalendarData();
    this.props.resetChannelTextOwnedData();
    this.props.resetChannelTextSharedData();
    this.props.resetChannelTextPaidData();
    this.props.resetChannelTextValueData();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(loginActions.logout());
    },
    resetCanvasDetails: () => {
      dispatch(canvasActions.clearCanvasDetailsData());
    },
    resetCoreData: () => {
      dispatch(canvasActions.clearCoreData());
    },
    resetCustomerData: () => {
      dispatch(canvasActions.clearCustomerData());
    },
    resetCommunityData: () => {
      dispatch(canvasActions.clearCommunityData());
    },
    resetCompetitionData: () => {
      dispatch(canvasActions.clearCompetitionData());
    },
    resetCreativesData: () => {
      dispatch(canvasActions.clearCreativesData());
    },
    resetContentStoryData: () => {
      dispatch(canvasActions.clearContentStoryData());
    },
    resetContentCalendarData: () => {
      dispatch(canvasActions.clearContentCalendarData());
    },
    resetChannelTextOwnedData: () => {
      dispatch(canvasActions.clearChannelTextOwnedData());
    },
    resetChannelTextSharedData: () => {
      dispatch(canvasActions.clearChannelTextSharedData());
    },
    resetChannelTextPaidData: () => {
      dispatch(canvasActions.clearChannelTextPaidData());
    },
    resetChannelTextValueData: () => {
      dispatch(canvasActions.clearChannelTextValueData());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Logout);
