import * as actionTypes from "./actionTypes";

// Account details
export const accountDetailsFetchInitiated = () => {
  return {
    type: actionTypes.ACCOUNT_DETAILS_FETCH_INIT
  };
};

export const accountDetailsFetch = user_id => {
  return {
    type: actionTypes.ACCOUNT_DETAILS_FETCH,
    user_id: user_id
  };
};

export const accountDetailsFetchSuccess = (message, details) => {
  return {
    type: actionTypes.ACCOUNT_DETAILS_FETCH_SUCCESS,
    message: message,
    details: details
  };
};

export const accountDetailsFetchFailure = error => {
  return {
    type: actionTypes.ACCOUNT_DETAILS_FETCH_FAILURE,
    error: error
  };
};

// Account update
export const updateAccountInitiated = () => {
  return {
    type: actionTypes.ACCOUNT_UPDATE_INIT
  };
};

export const updateAccount = (
  id,
  first_name,
  last_name,
  email,
  password,
  newPassword
) => {
  return {
    type: actionTypes.ACCOUNT_UPDATE,
    id: id,
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
    newPassword: newPassword
  };
};

export const updateAccountSuccess = message => {
  return {
    type: actionTypes.ACCOUNT_UPDATE_SUCCESS,
    message: message
  };
};

export const updateAccountFailure = message => {
  return {
    type: actionTypes.ACCOUNT_UPDATE_FAILURE,
    message: message
  };
};

export const updateAccountReset = () => {
  return {
    type: actionTypes.ACCOUNT_UPDATE_RESET
  };
};
