import * as actionTypes from "./actionTypes";

// Set notification
export const setNotification = (status, error, message, newStatus) => {
  return {
    type: actionTypes.SET_NOTIFICATION,
    status: status,
    error: error,
    message: message,
    newStatus: newStatus
  };
};

// Invalid file format
export const invalidFileFormat = (status, error, message) => {
  return {
    type: actionTypes.INVALID_FILE_FORMAT,
    status: status,
    error: error,
    message: message
  };
};

export const clearNotification = () => {
  return {
    type: actionTypes.CLEAR_NOTIFICATION
  };
};
