import * as actionTypes from "../actionTypes";

// Update channel text owned history
export const updateChannelTextOwnedHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY_INIT
  };
};

export const updateChannelTextOwnedHistory = (
  user_id,
  canvas_id,
  history_id,
  channel_text_owned_text1,
  channel_text_owned_text2,
  channel_text_owned_text3,
  channel_text_owned_text4,
  channel_text_owned_text5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    channel_text_owned_text1: channel_text_owned_text1,
    channel_text_owned_text2: channel_text_owned_text2,
    channel_text_owned_text3: channel_text_owned_text3,
    channel_text_owned_text4: channel_text_owned_text4,
    channel_text_owned_text5: channel_text_owned_text5
  };
};

export const updateChannelTextOwnedHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextOwnedHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_HISTORY_FAILURE,
    message: message
  };
};


// Update channel text shared history
export const updateChannelTextSharedHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY_INIT
  };
};

export const updateChannelTextSharedHistory = (
  user_id,
  canvas_id,
  history_id,
  channel_text_shared_text1,
  channel_text_shared_text2,
  channel_text_shared_text3,
  channel_text_shared_text4,
  channel_text_shared_text5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    channel_text_shared_text1: channel_text_shared_text1,
    channel_text_shared_text2: channel_text_shared_text2,
    channel_text_shared_text3: channel_text_shared_text3,
    channel_text_shared_text4: channel_text_shared_text4,
    channel_text_shared_text5: channel_text_shared_text5
  };
};

export const updateChannelTextSharedHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextSharedHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_HISTORY_FAILURE,
    message: message
  };
};


// Update channel text paid
export const updateChannelTextPaidHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY_INIT
  };
};

export const updateChannelTextPaidHistory = (
  user_id,
  canvas_id,
  history_id,
  channel_text_paid_text1,
  channel_text_paid_text2,
  channel_text_paid_text3,
  channel_text_paid_text4,
  channel_text_paid_text5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    channel_text_paid_text1: channel_text_paid_text1,
    channel_text_paid_text2: channel_text_paid_text2,
    channel_text_paid_text3: channel_text_paid_text3,
    channel_text_paid_text4: channel_text_paid_text4,
    channel_text_paid_text5: channel_text_paid_text5
  };
};

export const updateChannelTextPaidHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextPaidHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_HISTORY_FAILURE,
    message: message
  };
};
