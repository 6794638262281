import { put } from "redux-saga/effects";
import * as initiateActions from "../actions/forgotPassword/resetInitiate.action";
import * as verifyActions from "../actions/forgotPassword/verifyInitiate.action";
import * as resetActions from "../actions/forgotPassword/resetPassword.action";
import axios from "../config/axios";

// Initiate reset password
export function* resetInitiateSaga(action) {
  yield put(initiateActions.initiateStart());

  const initiateData = {
    email: action.email
  };

  try {
    const response = yield axios.post("forgot-password/initiate", initiateData);

    yield put(initiateActions.initiateSuccess(response.message));
  } catch (error) {
    yield put(initiateActions.initiateFail(error.message));
  }
}

// Verify link is active or not
export function* verifyInitiateSaga(action) {
  yield put(verifyActions.verifyStart());

  const verifyData = {
    token: action.token
  };

  try {
    const response = yield axios.post("forgot-password/verify", verifyData);

    yield put(verifyActions.verifySuccess(response.message, response.id));
  } catch (error) {
    yield put(verifyActions.verifyFail(error.message));
  }
}

// Reset password
export function* resetPasswordSaga(action) {
  yield put(resetActions.resetStart());

  const resetPasswordData = {
    id: action.id,
    password: action.password
  };

  try {
    const response = yield axios.put(
      "forgot-password/reset",
      resetPasswordData
    );

    yield put(resetActions.resetSuccess(response.message));
  } catch (error) {
    yield put(resetActions.resetFail(error.message));
  }
}
