import * as actionTypes from "./actionTypes";

// Update content story
export const updateContentStoryStart = () => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_INIT
  };
};

export const updateContentStory = (
  user_id,
  canvas_id,
  content_brand,
  content_story
) => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY,
    user_id: user_id,
    canvas_id: canvas_id,
    content_brand: content_brand,
    content_story: content_story
  };
};

export const updateContentStorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateContentStoryFail = message => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_FAILURE,
    message: message
  };
};

// Set updated content story in store
export const setContentStory = (content_brand, content_story) => {
  return {
    type: actionTypes.SET_CONTENT_STORY,
    content_brand: content_brand,
    content_story: content_story
  };
};

// Update content calendar
export const updateContentCalendarStart = () => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_INIT
  };
};

export const updateContentCalendar = (
  user_id,
  canvas_id,
  content_core,
  content_customer,
  content_community,
  content_competition,
  content_creatives
) => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR,
    user_id: user_id,
    canvas_id: canvas_id,
    content_core: content_core,
    content_customer: content_customer,
    content_community: content_community,
    content_competition: content_competition,
    content_creatives: content_creatives
  };
};

export const updateContentCalendarSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_SUCCESS,
    status: status,
    message: message
  };
};

export const updateContentCalendarFail = message => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_FAILURE,
    message: message
  };
};

// Set updated content calendar in store
export const setContentCalendar = (
  content_core,
  content_customer,
  content_community,
  content_competition,
  content_creatives
) => {
  return {
    type: actionTypes.SET_CONTENT_CALENDAR,
    content_core: content_core,
    content_customer: content_customer,
    content_community: content_community,
    content_competition: content_competition,
    content_creatives: content_creatives
  };
};
