import * as actionTypes from "./actionTypes";

// Update customer audience
export const updateCustomerAudienceStart = () => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_INIT
  };
};

export const updateCustomerAudience = (
  user_id,
  canvas_id,
  customer_audience
) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE,
    user_id: user_id,
    canvas_id: canvas_id,
    customer_audience: customer_audience
  };
};

export const updateCustomerAudienceSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCustomerAudienceFail = message => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_AUDIENCE_FAILURE,
    message: message
  };
};

// Set updated customer audience in store
export const setCustomerAudience = customer_audience => {
  return {
    type: actionTypes.SET_CUSTOMER_AUDIENCE,
    customer_audience: customer_audience
  };
};

// Update customer elevate
export const updateCustomerElevateStart = () => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_INIT
  };
};

export const updateCustomerElevate = (user_id, canvas_id, customer_elevate) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE,
    user_id: user_id,
    canvas_id: canvas_id,
    customer_elevate: customer_elevate
  };
};

export const updateCustomerElevateSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCustomerElevateFail = message => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_ELEVATE_FAILURE,
    message: message
  };
};

// Set updated customer elevate in store
export const setCustomerElevate = customer_elevate => {
  return {
    type: actionTypes.SET_CUSTOMER_ELEVATE,
    customer_elevate: customer_elevate
  };
};

// Update community
export const updateCommunityStart = () => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_INIT
  };
};

export const updateCommunity = (user_id, canvas_id, community) => {
  return {
    type: actionTypes.UPDATE_COMMUNITY,
    user_id: user_id,
    canvas_id: canvas_id,
    community: community
  };
};

export const updateCommunitySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCommunityFailure = message => {
  return {
    type: actionTypes.UPDATE_COMMUNITY_FAILURE,
    message: message
  };
};

// Set updated community in store
export const setCommunity = community => {
  return {
    type: actionTypes.SET_COMMUNITY,
    community: community
  };
};
