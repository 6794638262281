import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Core data store
const coreDetailsState = {
  canvas_id: null,
  history_id: null,
  core_purpose: "",
  core_essence: "",
  core_values: ""
};

export const core = (state = coreDetailsState, action) => {
  switch (action.type) {
    case actionTypes.SET_CORE_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          core_purpose: action.details.core_purpose,
          core_essence: action.details.core_essence,
          core_values: action.details.core_values
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          core_purpose: action.details.core_purpose,
          core_essence: action.details.core_essence,
          core_values: action.details.core_values
        });
      }

    case actionTypes.CLEAR_CORE_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        core_purpose: "",
        core_essence: "",
        core_values: ""
      });

    case actionTypes.SET_CORE_PURPOSE:
      return updateObject(state, {
        core_purpose: action.core_purpose
      });

    case actionTypes.SET_CORE_VALUES:
      return updateObject(state, {
        core_values: action.core_values
      });

    case actionTypes.SET_CORE_ESSENCE:
      return updateObject(state, {
        core_essence: action.core_essence
      });

    default: {
      return state;
    }
  }
};

// Update core purpose
const updateCorePurposeState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCorePurpose = (state = updateCorePurposeState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CORE_PURPOSE_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CORE_PURPOSE_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CORE_PURPOSE_FAILURE:
      return updateObject(state, {
        status: "idle",
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CORE_PURPOSE_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CORE_PURPOSE_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CORE_PURPOSE_HISTORY_FAILURE:
      return updateObject(state, {
        status: "idle",
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update core values
const updateCoreValuesState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCoreValues = (state = updateCoreValuesState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CORE_VALUES_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CORE_VALUES_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CORE_VALUES_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CORE_VALUES_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CORE_VALUES_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CORE_VALUES_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update core essence
const updateCoreEssenceState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCoreEssence = (state = updateCoreEssenceState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CORE_ESSENCE_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CORE_ESSENCE_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CORE_ESSENCE_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CORE_ESSENCE_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CORE_ESSENCE_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CORE_ESSENCE_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
