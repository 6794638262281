import jwt_decode from "jwt-decode";
import moment from "moment";

// Update state object
export const updateObject = (oldObject, updatedproperties) => {
  return {
    ...oldObject,
    ...updatedproperties
  };
};

// Get user id from token
export const getUserIdFromToken = () => {
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    return decoded.id;
  }
};

// Add new item to existing canvas list
export const addItemToCanvasList = (list, details) => {
  let updated = JSON.parse(JSON.stringify(list));
  let data = {
    _id: details._id,
    name: details.name
  };

  updated.push(data);
  return updated;
};

// Filter comments per section
export const filterComment = (canvas_id, list, section) => {
  let tempList = list;

  const newList = tempList
    .filter(comment => {
      return comment.section === section;
    })
    .map(comment => {
      comment.date = moment(comment.createdAt).format("DD.MM.YYYY");
      comment.time = moment(comment.createdAt).format("hh:mm A");
      return comment;
    });

  return newList;
};

// Update comment list with new one
export const addFilterComment = (list, comment) => {
  let newList = list;

  comment.date = moment(comment.createdAt).format("DD.MM.YYYY");
  comment.time = moment(comment.createdAt).format("hh:mm A");
  newList.push(comment);

  return newList;
};

// Update color list with new one
export const updateColorList = (details, color) => {
  let updated = details;
  let data = {
    name: color
  };
  updated.push(data);
  return updated;
};

// Remove color from the list
export const removeColorFromList = (details, color) => {
  let updated = details;
  updated.splice(
    updated.findIndex(function(i) {
      return i.name === color;
    }),
    1
  );
  return updated;
};

// Delete canvas from list
export const removeItemFromList = (list, id, inde) => {
  let index  = list.indexOf(id);
  list.splice(index, 1);
  return list;
};

// Update name in the canvas list
export const updateCanvasNameInList = (list, id, name) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i]._id === id) {
      list[i].name = name;
    }
  }
  return list;
};

// Check user logged in with valid token
export const checkValidToken = () => {
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    let current = moment().unix();
    let expiry = moment(decoded.exp);
    let difference = expiry.diff(current, "days");
    if (difference > 7) {
      return false;
    } else {
      return true;
    }
  }
};

// Add date & time to history list
export const modifyHistoryList = list => {
  let newList = list;
  for (let i = 0; i < newList.length; i++) {
    newList[i].date = moment(newList[i].createdAt).format("MMM DD YYYY");
    newList[i].time = moment(newList[i].createdAt).format("hh:mm A");
  }
  return newList;
};

// Convert timestamp to date & time
export const timestampToDateTime = dateTime => {
  let date = moment(dateTime).format("MMM DD YYYY");
  let time = moment(dateTime).format("hh:mm A");
  let data = date + " " + time;
  return data;
};

// Append new history data to existing list
export const appendHistoryDataToList = (oldList, newList) => {
  oldList.push(...newList);
  return oldList;
};
