import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import SingleInput from "../../UI/SingleInput/SingleInput";

class TextValue extends Component {
  state = {
    field: null,
    value: this.props.value,
    text1: "",
    value1: "",
    text2: "",
    value2: "",
    text3: "",
    value3: "",
    text4: "",
    value4: "",
    text5: "",
    value5: "",
    disabled: false,
    saveBtnClick: false,
    cancelBtnClick: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.field === "channel_owned") {
      this.setState({
        text1: nextProps.values[0],
        value1: nextProps.values[1],
        text2: nextProps.values[2],
        value2: nextProps.values[3],
        text3: nextProps.values[4],
        value3: nextProps.values[5],
        text4: nextProps.values[6],
        value4: nextProps.values[7],
        text5: nextProps.values[8],
        value5: nextProps.values[9]
      });
    } else if (nextProps.field === "channel_shared") {
      this.setState({
        text1: nextProps.values[0],
        value1: nextProps.values[1],
        text2: nextProps.values[2],
        value2: nextProps.values[3],
        text3: nextProps.values[4],
        value3: nextProps.values[5],
        text4: nextProps.values[6],
        value4: nextProps.values[7],
        text5: nextProps.values[8],
        value5: nextProps.values[9]
      });
    } else if (nextProps.field === "channel_paid") {
      this.setState({
        text1: nextProps.values[0],
        value1: nextProps.values[1],
        text2: nextProps.values[2],
        value2: nextProps.values[3],
        text3: nextProps.values[4],
        value3: nextProps.values[5],
        text4: nextProps.values[6],
        value4: nextProps.values[7],
        text5: nextProps.values[8],
        value5: nextProps.values[9]
      });
    }
  }

  clickHandler = field => {
    this.setState(state => {
      return {
        field: field
      };
    });
    this.props.clickHandler(field);
  };

  changeHandler = (field, event) => {
    const value = event.target.value;
    let ownTextIndex = field.substr(18, 1);
    let ownValueIndex = field.substr(19, 1);
    let sharedTextIndex = field.substr(19, 1);
    let sharedValueIndex = field.substr(20, 1);
    let paidTextIndex = field.substr(17, 1);
    let paidValueIndex = field.substr(18, 1);

    for (let i = 0; i < 5; i++) {
      if (field === "channel_owned_text" + ownTextIndex) {
        let otext = "text" + ownTextIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_owned_value" + ownValueIndex) {
        let otext = "value" + ownValueIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_shared_text" + sharedTextIndex) {
        let otext = "text" + sharedTextIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_shared_value" + sharedValueIndex) {
        let otext = "value" + sharedValueIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_paid_text" + paidTextIndex) {
        let otext = "text" + paidTextIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_paid_value" + paidValueIndex) {
        let otext = "value" + paidValueIndex;
        this.setState({ [otext]: value });
      }
    }

    this.setState(state => {
      return {
        [field]: value,
        field: field,
        clickedSaveBtn: false,
        clickedCancelBtn: false
      };
    });

    this.props.changeHandler(field, value);
  };

  render() {
    let subRevenue;

    subRevenue =
      Number(this.state.value1) +
      Number(this.state.value2) +
      Number(this.state.value3) +
      Number(this.state.value4) +
      Number(this.state.value5);

    return (
      <Aux>
        <div className={"content__calc"}>
          <h4>{this.props.title}</h4>

          <div className={"content__line"}>
            <SingleInput
              key={this.props.fields[0]}
              id={this.props.fields[0]}
              field={this.props.fields[0]}
              value={this.state.text1}
              onUpdate={this.changeHandler}
              numberValidation={false}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in1"}
            />
            <SingleInput
              key={this.props.fields[1]}
              id={this.props.fields[1]}
              field={this.props.fields[1]}
              value={this.state.value1}
              onUpdate={this.changeHandler}
              numberValidation={true}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in2"}
            />
          </div>

          <div className={"content__line"}>
            <SingleInput
              key={this.props.fields[2]}
              id={this.props.fields[2]}
              field={this.props.fields[2]}
              value={this.state.text2}
              onUpdate={this.changeHandler}
              numberValidation={false}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in1"}
            />
            <SingleInput
              key={this.props.fields[3]}
              id={this.props.fields[3]}
              field={this.props.fields[3]}
              value={this.state.value2}
              onUpdate={this.changeHandler}
              numberValidation={true}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in2"}
            />
          </div>

          <div className={"content__line"}>
            <SingleInput
              key={this.props.fields[4]}
              id={this.props.fields[4]}
              field={this.props.fields[4]}
              value={this.state.text3}
              onUpdate={this.changeHandler}
              numberValidation={false}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in1"}
            />
            <SingleInput
              key={this.props.fields[5]}
              id={this.props.fields[5]}
              field={this.props.fields[5]}
              value={this.state.value3}
              onUpdate={this.changeHandler}
              numberValidation={true}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in2"}
            />
          </div>

          <div className={"content__line"}>
            <SingleInput
              key={this.props.fields[6]}
              id={this.props.fields[6]}
              field={this.props.fields[6]}
              value={this.state.text4}
              onUpdate={this.changeHandler}
              numberValidation={false}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in1"}
            />
            <SingleInput
              key={this.props.fields[7]}
              id={this.props.fields[7]}
              field={this.props.fields[7]}
              value={this.state.value4}
              onUpdate={this.changeHandler}
              numberValidation={true}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in2"}
            />
          </div>

          <div className={"content__line"}>
            <SingleInput
              key={this.props.fields[8]}
              id={this.props.fields[8]}
              field={this.props.fields[8]}
              value={this.state.text5}
              onUpdate={this.changeHandler}
              numberValidation={false}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in1"}
            />
            <SingleInput
              key={this.props.fields[9]}
              id={this.props.fields[9]}
              field={this.props.fields[9]}
              value={this.state.value5}
              onUpdate={this.changeHandler}
              numberValidation={true}
              maxLimit={25}
              alertLimit={5}
              showAlert={this.props.showAlert}
              reverseAlert={this.props.reverseAlert}
              inputClass={"content__line-in2"}
            />
          </div>

          <div className="content__line">
            <h5>sub revenue</h5>
            <input type="text" className={"content__line-in2"} disabled value={subRevenue} />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    commentList: state.commentList,
    channelTextValue: state.channelTextValue
  };
};

export default connect(
  mapStateToProps,
  null
)(TextValue);
// export default TextValue;
