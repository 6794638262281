import * as actionTypes from "../actionTypes";

export const resetStart = () => {
  return {
    type: actionTypes.RESET_INIT
  };
};

export const resetProcess = (id, password) => {
  return {
    type: actionTypes.RESET_PROCESS,
    id: id,
    password: password
  };
};

export const resetSuccess = (message, id) => {
  return {
    type: actionTypes.RESET_SUCCESS,
    message: message,
    id: id
  };
};

export const resetFail = message => {
  return {
    type: actionTypes.RESET_FAIL,
    message: message
  };
};

export const resetClearMessage = () => {
  return {
    type: actionTypes.RESET_CLEAR
  };
};
