import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.action";
import * as notification from "../actions/notification.action";
import * as history from "../actions/history/canvas.history.action";
import axios from "../config/axios";
import publicAxios from "../config/axios_public";

// Canvas list
export function* canvasListSaga(action) {
  // yield put(actions.canvasFetchInitiated());

  try {
    let response = yield axios.get(`/canvas/${action.user_id}/list`);
    yield put(actions.canvasListFetchSuccess(response.message, response.data));
    // yield put(actions.addCanvasToList(action.list, response.data));
  } catch (e) {
    yield put(actions.canvasListFetchFailure(e.message));
  }
}

// Create canvas
export function* createCanvasSaga(action) {
  yield put(actions.createCanvasStart());
  const data = {
    user_id: action.user_id,
    name: action.name
  };
  try {
    let response = yield axios.post("/canvas", data);
    yield put(
      actions.createCanvasSuccess(
        response.status,
        response.message,
        response.data._id
      )
    );
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
    yield put(actions.addCanvasToList(action.list, response.data));
  } catch (e) {
    yield put(actions.createCanvasFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Fetch canvas details
export function* canvasDetailsSaga(action) {
  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id
  };
  if (
    action.canvas_id &&
    action.canvas_id !== null &&
    action.canvas_id !== ""
  ) {
    try {
      let response = yield axios.post("/canvas/details", data);
      yield put(actions.commentsFetch(action.user_id, action.canvas_id));
      yield put(actions.setCanvasDetailsData(null, response.data));
      yield put(actions.setCoreData(null, response.data));
      yield put(actions.setCustomerData(null, response.data));
      yield put(actions.setCompetitionData(null, response.data));
      yield put(actions.setContentStoryData(null, response.data));
      yield put(actions.setContentCalendarData(null, response.data));
      yield put(actions.setCreativesData(null, response.data));
      yield put(actions.setChannelTextOwnedData(null, response.data));
      yield put(actions.setChannelTextSharedData(null, response.data));
      yield put(actions.setChannelTextPaidData(null, response.data));
      yield put(actions.setChannelTextValueData(null, response.data));
      yield put(actions.setNoteData(response.data));
      yield put(history.resetHistoryScroller());
      yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
      yield put(actions.setCurrentVersionTime(response.data.updatedAt));
    } catch (e) {
      yield put(actions.canvasDetailsFetchFailure(e.message));
      yield put(notification.setNotification(true, false, e.message));
    }
  }
}

// Update canvas name
export function* updateCanvasNameSaga(action) {
  yield put(actions.canvasNameUpdateStart());
  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    name: action.name
  };

  try {
    let response = yield axios.post(`/canvas/name`, data);
    yield put(
      actions.canvasNameUpdateSuccess(response.status, response.message)
    );
    yield put(actions.setCanvasName(action.name));
    yield put(
      actions.updateCanvasNameList(action.list, action.canvas_id, action.name)
    );
  } catch (e) {
    yield put(actions.canvasNameUpdateFailure(e.message));
  }
}

// Add comment
export function* addCommentSaga(action) {
  yield put(actions.addCommentStart());
  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    section: action.section,
    comment: action.comment
  };

  try {
    let response = yield axios.post("/canvas/add-comment", data);
    yield put(actions.addCommentSuccess(response.status, response.message));
    yield put(actions.updateFilterComment(action.list, response.data));
    yield put(actions.commentsFetch(action.user_id, action.canvas_id));
  } catch (e) {
    yield put(actions.addCommentFail(e.message));
  }
}

// Comment list
export function* commentListSaga(action) {
  yield put(actions.commentsFetchInitiated());
  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id
  };

  try {
    let response = yield axios.post(`/canvas/read-comment`, data);
    yield put(actions.commentsFetchSuccess(response.message, response.data));
  } catch (e) {
    yield put(actions.commentsFetchFailure(e.message));
  }
}

// Collaborate comment list
export function* collaborateCommentListSaga(action) {
  yield put(actions.collaborateCommentsFetchInitiated());
  
  const data = {
    token: action.token
  };

  try {
    let response = yield publicAxios.post(`/canvas/collaborate/read-comment`, data);
    yield put(
      actions.collaborateCommentsFetchSuccess(response.message, response.data)
    );
  } catch (e) {
    yield put(actions.collaborateCommentsFetchFailure(e.message));
  }
}

// Delete canvas
export function* deleteCanvasSaga(action) {
  let id = action.id;
  let list = action.list;
  // let index = action.index;

  yield put(actions.canvasDeleteInitiated());

  try {
    let response = yield axios.delete("canvas/" + id);
    yield put(actions.canvasDeleteSuccess(response.message));
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
    yield put(actions.canvasDeleteListItem(list, id));
  } catch (e) {
    yield put(actions.canvasDeleteFailure(e.message));
  }
}

// Send canvas
export function* sendCanvasSaga(action) {
  yield put(actions.sendCanvasStart());
  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    email: action.email
  };
  try {
    let response = yield axios.post("/canvas/send-copy", data);
    yield put(actions.sendCanvasSuccess(response.status, response.message));
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
  } catch (e) {
    yield put(actions.sendCanvasFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Update note/notepad
export function* updateNoteSaga(action) {
  yield put(actions.noteUpdateStart());
  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    notes: action.note
  };
  try {
    let response = yield axios.put("/canvas/notes", data);
    yield put(actions.noteUpdateSuccess(response.status, response.message));
    yield put(actions.setNote(action.note));
  } catch (e) {
    yield put(actions.noteUpdateFailure(e.message));
  }
}

// Fetch canvas details for public view & collaborate
export function* canvasDetailsViewSaga(action) { 
  try {
    let response = yield publicAxios.get(`/canvas/details/${action.token}`);
    yield put(actions.setCanvasDetailsData(null, response.data));
    yield put(actions.setCoreData(null, response.data));
    yield put(actions.setCustomerData(null, response.data));
    yield put(actions.setCompetitionData(null, response.data));
    yield put(actions.setContentStoryData(null, response.data));
    yield put(actions.setContentCalendarData(null, response.data));
    yield put(actions.setCreativesData(null, response.data));
    yield put(actions.setChannelTextOwnedData(null, response.data));
    yield put(actions.setChannelTextSharedData(null, response.data));
    yield put(actions.setChannelTextPaidData(null, response.data));
    yield put(actions.setChannelTextValueData(null, response.data));
    yield put(actions.collaborateCommentsFetch(action.token));
  } catch (e) {
    yield put(actions.canvasViewFailure(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Canvas copy invitation
export function* canvasCopyInvitationSaga(action) {
  yield put(actions.canvasCopyInvitationStart());

  try {
    let response = yield axios.get(`/canvas/add-copy/${action.token}`);
    yield put(
      actions.canvasCopyInvitationSuccess(response.status, response.message)
    );
  } catch (e) {
    yield put(actions.canvasCopyInvitationFailure(e.status, e.message));
  }
}

// Collaborate add comment
export function* canvasCollaborateAddCommentSaga(action) {
  yield put(actions.canvasCollaborateAddCommentStart());
  
  let data = {
    canvas_id: action.canvas_id,
    email: action.email,
    comment: action.comment,
    section: action.section
  };

  try {
    let response = yield publicAxios.post(`/canvas/collaborate/add-comment`, data);
    yield put(
      actions.canvasCollaborateAddCommentSuccess(
        response.status,
        response.message
      )
    );
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
    yield put(actions.updateFilterComment(action.list, response.data));
    yield put(actions.collaborateCommentsFetch(action.token));
  } catch (e) {
    yield put(actions.canvasCollaborateAddCommentFail(e.status, e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}
