import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import ContentStory from "./ContentStory";
import ContentCalendar from "./ContentCalendar";

class Content extends Component {
  render() {
    return (
      <Aux>
        <div className={"content__element"}>
          <div className={"content__title"}>
            <h2>
              <img src="/img/main/Content.svg" alt="Content" />
              Content
            </h2>

            <button className={"content__title-btn"}>
              <span />
            </button>
          </div>

          <ContentStory />
          <ContentCalendar />
        </div>
      </Aux>
    );
  }
}

export default Content;
