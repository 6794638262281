import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as canvasActions from "../../../actions/canvas.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import ListItem from "../../UI/ListItem/ListItem";
import $ from "jquery";

class Sidemenu extends Component {
  constructor(props) {
    super(props);
    this.listItemRef = React.createRef();
    this.listRef = React.createRef();
  }

  state = {
    user_id: getUserIdFromToken(),
    canvas_query: "",
    canvas_id: "",
    clickedCanvas: "",
    searchInitiated: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.canvasList.canvas.length > 0) {
      if (prevProps.canvasList.canvas !== this.props.canvasList.canvas) {
        // Load the first canvas when coming from login page
        if (this.props.login.fromLoginPage === true) {
          let list = JSON.parse(JSON.stringify(this.props.canvasList.canvas));
          let id = list[0]._id;

          this.setState({ canvas_id: id });
          this.props.getCanvasDetails(this.state.user_id, id);
        } else {
          // Load the lastly added canvas on success
          let list = JSON.parse(JSON.stringify(this.props.canvasList.canvas));
          let id = list.reverse()[0]._id;
          this.setState({ canvas_id: id });
          this.props.getCanvasDetails(this.state.user_id, id);
        }
      } else if (prevProps.canvasDelete.status === "deleted") {
        // Load the first canvas on any canvas delete
        this.props.resetCanvasDelete();
        let list = JSON.parse(JSON.stringify(this.props.canvasList.canvas));
        let id = list[0]._id;
        if (this.state.canvas_id !== id) {
          if (this.state.searchInitiated === true) {
            this.setState({ searchInitiated: false, canvas_query: "" });
          } else {
            this.setState({ canvas_id: id });
            this.props.getCanvasDetails(this.state.user_id, id);
          }
        }
      }
    } else {
      if (this.props.canvasList.canvas.length > 0) {
        this.setState({ canvas_id: this.props.canvasList.canvas[0]._id });
        this.props.getCanvasDetails(
          this.state.user_id,
          this.props.canvasList.canvas[0]._id
        );
      }
    }

    if (
      this.props.canvasList.canvas.length === 0 &&
      prevProps.canvasDelete.status === "deleted"
    ) {
      this.props.resetCanvasDetails();
      this.props.resetCoreData();
      this.props.resetCustomerData();
      this.props.resetCommunityData();
      this.props.resetCompetitionData();
      this.props.resetCreativesData();
      this.props.resetContentStoryData();
      this.props.resetContentCalendarData();
      this.props.resetChannelTextOwnedData();
      this.props.resetChannelTextSharedData();
      this.props.resetChannelTextPaidData();
      this.props.resetChannelTextValueData();
      $(".content__group").each(function() {
        $(this)
          .children(".content__textarea")
          .removeClass("before");
      });
    }
  }

  canvasDetails = canvas_id => {
    this.setState({ canvas_id: canvas_id, clickedCanvas: canvas_id });
    this.props.getCanvasDetails(this.state.user_id, canvas_id);
  };

  // Filter data
  searchCanvas(event) {
    const value = event.target.value;
    if (value !== "") {
      this.setState({ searchInitiated: true, canvas_query: value });
    } else {
      this.setState({ searchInitiated: false, canvas_query: value });
    }
  }

  confirmDelete = (id, index) => {
    this.props.confirmDelete(id);
  };

  renderCanvas() {
    let canvasList = this.props.canvasList.canvas.filter(canvas => {
      return canvas.name.toLowerCase().indexOf(this.state.canvas_query) !== -1;
    });

    return canvasList.map((canvas, index) => {
      let activeClass;
      if (canvas._id === this.state.canvas_id) {
        activeClass = "active";
      } else {
        activeClass = "";
      }

      return (
        <ListItem
          editIcon="/img/main/edit-white.svg"
          deleteIcon="/img/main/trash-white.svg"
          key={canvas._id}
          id={canvas._id}
          name={canvas.name}
          classname={activeClass}
          canvasDetails={() => this.canvasDetails(canvas._id)}
          index={index}
          updateName={this.updateName}
          clickedCanvas={this.state.clickedCanvas}
          confirmDelete={() => this.confirmDelete(canvas._id, index)}
        />
      );
    });
  }

  updateName = (id, name) => {
    this.props.updateCanvasName(
      this.state.user_id,
      id,
      this.props.canvasList.canvas,
      name
    );
  };

  render() {
    return (
      <Aux>
        <div className={"menu show"}>
          <div className={"menu__title"}>
            <h2>my One Pager</h2>
          </div>

          <div className={"menu__search"}>
            <div className={"menu__search-field"}>
              <label htmlFor="search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 1000"
                  className={
                    "Icon Icon--search FilterBar-icon FilterBar-icon--search"
                  }
                >
                  <path
                    fillRule="evenodd"
                    d="M476.932295,563.944595 C344.129983,563.944595 236.083601,455.898213 236.083601,323.095901 C236.083601,190.349981 344.129983,82.3035985 476.932295,82.3035985 C609.734607,82.3035985 717.780989,190.349981 717.780989,323.095901 C717.780989,455.898213 609.734607,563.944595 476.932295,563.944595 M476.932295,0.02819582 C298.791104,0.02819582 153.86459,144.95471 153.86459,323.095901 C153.86459,395.389983 177.94382,461.875727 218.151059,515.729743 C214.485603,517.872625 210.650971,519.677158 207.549431,522.778698 L14.4644556,715.807282 C-4.82148521,735.093222 -4.82148521,766.390583 14.4644556,785.676523 C24.1074261,795.319494 36.6827618,800.056392 49.3144891,800.056392 C62.0026081,800.056392 74.5779438,795.319494 84.2209142,785.676523 L277.305889,592.591548 C280.463821,589.433616 282.268354,585.655377 284.354844,581.933528 C338.208861,622.140768 404.750996,646.219998 476.932295,646.219998 C655.073485,646.219998 800,501.293483 800,323.095901 C800,144.95471 655.073485,0.02819582 476.932295,0.02819582"
                    transform="translate(100 100)"
                  />
                </svg>
              </label>

              <input
                type="search"
                id="search"
                autoComplete="off"
                value={this.state.canvas_query}
                onChange={this.searchCanvas.bind(this)}
              />
            </div>

            <div className={"menu__search-buttons"}>
              <button data-target="create" className={"plus-btn"}>
                <img src="/img/main/Plus-white.svg" alt="plus" />
              </button>
            </div>
          </div>

          <ul className={"menu__list"}>
            {this.props.canvasList.loading === false ? (
              this.props.canvasList.canvas.length === 0 ? (
                <div className="empty__canvas">No canvas list</div>
              ) : (
                this.renderCanvas()
              )
            ) : (
              <div className="loading__canvas">Loading canvas list...</div>
            )}
          </ul>

          <div className={"menu__footer"}>
            <h3>create new One Pager</h3>

            <button data-target="create" className={"plus-btn"}>
              <img src="/img/main/Plus-white.svg" alt="plus" />
            </button>
          </div>

          <div className={"menu-toggler"}>
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 1000"
                className={"Icon Icon--chevron"}
              >
                <path d="M422.62,518a24.72,24.72,0,0,1,0-35L650.13,255.49a24.77,24.77,0,0,0-35-35l-245,245a49.5,49.5,0,0,0,0,70l245,245a24.76,24.76,0,0,0,35-35Z" />
              </svg>
            </button>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCanvasDetails: (user_id, canvas_id) => {
      dispatch(canvasActions.canvasDetailsFetch(user_id, canvas_id));
    },
    updateCanvasName: (user_id, canvas_id, list, name) => {
      dispatch(canvasActions.canvasNameUpdate(user_id, canvas_id, list, name));
    },
    resetCanvasDelete: () => {
      dispatch(canvasActions.canvasDeleteReset());
    },
    resetCanvasDetails: () => {
      dispatch(canvasActions.clearCanvasDetailsData());
    },
    resetCoreData: () => {
      dispatch(canvasActions.clearCoreData());
    },
    resetCustomerData: () => {
      dispatch(canvasActions.clearCustomerData());
    },
    resetCommunityData: () => {
      dispatch(canvasActions.clearCommunityData());
    },
    resetCompetitionData: () => {
      dispatch(canvasActions.clearCompetitionData());
    },
    resetCreativesData: () => {
      dispatch(canvasActions.clearCreativesData());
    },
    resetContentStoryData: () => {
      dispatch(canvasActions.clearContentStoryData());
    },
    resetContentCalendarData: () => {
      dispatch(canvasActions.clearContentCalendarData());
    },
    resetChannelTextOwnedData: () => {
      dispatch(canvasActions.clearChannelTextOwnedData());
    },
    resetChannelTextSharedData: () => {
      dispatch(canvasActions.clearChannelTextSharedData());
    },
    resetChannelTextPaidData: () => {
      dispatch(canvasActions.clearChannelTextPaidData());
    },
    resetChannelTextValueData: () => {
      dispatch(canvasActions.clearChannelTextValueData());
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasList: state.canvasList,
    canvasDelete: state.canvasDelete,
    login: state.login
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidemenu);
