import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.content.action";
import * as canvasAction from "../actions/canvas.action";
import * as history from "../actions/history/canvas.history.action";
import axios from "../config/axios";

// Update content story
export function* updateContentStorySaga(action) {
  yield put(actions.updateContentStoryStart());
  const data = {
    user_id: action.user_id,
    content_brand: action.content_brand,
    content_story: action.content_story
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/content-story`,
      data
    );
    yield put(
      actions.updateContentStorySuccess(response.status, response.message)
    );
    yield put(
      actions.setContentStory(action.content_brand, action.content_story)
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateContentStoryFail(e.message));
  }
}

// Update content calendar
export function* updateContentCalendarSaga(action) {
  yield put(actions.updateContentCalendarStart());
  const data = {
    user_id: action.user_id,
    content_core: action.content_core,
    content_customer: action.content_customer,
    content_community: action.content_community,
    content_competition: action.content_competition,
    content_creatives: action.content_creatives
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/content-calendar`,
      data
    );
    yield put(
      actions.updateContentCalendarSuccess(response.status, response.message)
    );
    yield put(
      actions.setContentCalendar(
        action.content_core,
        action.content_customer,
        action.content_community,
        action.content_competition,
        action.content_creatives
      )
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateContentCalendarFail(e.message));
  }
}
