import * as actionTypes from "../actions/actionTypes";
import { updateObject, checkValidToken } from "../shared/utils/utils";

const initialState = {
  error: false,
  loading: false,
  isLoggedIn: false,
  message: "",
  fromLoginPage: false
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_INIT:
      return updateObject(state, { loading: true });

    case actionTypes.LOGIN_SUCCESS:
      return updateObject(state, {
        loading: false,
        error: false,
        isLoggedIn: true,
        message: action.message,
        fromLoginPage: true
      });

    case actionTypes.LOGIN_FAIL:
      return updateObject(state, {
        loading: false,
        error: true,
        isLoggedIn: false,
        message: action.message
      });

    case actionTypes.LOGIN_RESET:
      return updateObject(state, {
        isLoggedIn: false,
        loading: false,
        error: false
      });

    case actionTypes.LOGIN_CHECK:
      let loggedIn = checkValidToken();
      return updateObject(state, {
        isLoggedIn: loggedIn
      });

    case actionTypes.LOGOUT:
      return updateObject(state, {
        isLoggedIn: false
      });

    default: {
      return state;
    }
  }
};

// const initialChecker = {
//   checkerLoading: false,
//   checkerLoggedin: false
// };

// export const loginChecker = (state = initialChecker, action) => {
//   switch (action.type) {
//     case actionTypes.LOGIN_CHECK_INIT:
//       return updateObject(state, {
//         checkerLoading: true
//       });

//     case actionTypes.LOGIN_CHECK:
//       return updateObject(state, {
//         checkerLoading: true
//       });

//     case actionTypes.LOGIN_CHECK_SUCCESS:
//       return updateObject(state, {
//         checkerLoading: false,
//         checkerLoggedin: true
//       });

//     case actionTypes.LOGIN_CHECK_FAIL:
//       return updateObject(state, {
//         checkerLoading: false,
//         checkerLoggedin: false
//       });

//     case actionTypes.LOGIN_CHECK_RESET:
//       return updateObject(state, {
//         checkerLoading: false,
//         checkerLoggedin: false
//       });

//     default: {
//       return state;
//     }
//   }
// };

export default login;
