import { put } from "redux-saga/effects";
import * as loginActions from "../actions/login.action";
import axios from "../config/axios";

export function* loginSaga(action) {
  yield put(loginActions.loginStart());

  const loginData = {
    email: action.email,
    password: action.password
  };

  try {
    const response = yield axios.post("login", loginData);
    yield localStorage.setItem("token", response.token);
    yield put(loginActions.loginSuccess(response.message));
  } catch (error) {
    yield put(loginActions.loginFail(error.message));
  }
}

export function* checkIfLoggedInSaga(action) {
  yield put(loginActions.loginCheckStart());
  try {
    var response = yield axios.get("check-login");
    yield put(loginActions.loginCheckSuccess(response.message));
  } catch (e) {
    yield put(loginActions.loginCheckFail("Not logged in yet"));
  }
}
