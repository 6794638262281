import * as actionTypes from "../actionTypes";

// Update core purpose history
export const updateCorePurposeHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_HISTORY_INIT
  };
};

export const updateCorePurposeHistory = (
  user_id,
  canvas_id,
  history_id,
  core_purpose
) => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    core_purpose: core_purpose
  };
};

export const updateCorePurposeHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCorePurposeHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_HISTORY_FAILURE,
    message: message
  };
};

// Update core values history
export const updateCoreValuesHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_HISTORY_INIT
  };
};

export const updateCoreValuesHistory = (
  user_id,
  canvas_id,
  history_id,
  core_values
) => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    core_values: core_values
  };
};

export const updateCoreValuesHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCoreValuesHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_HISTORY_FAILURE,
    message: message
  };
};

// Update core essence history
export const updateCoreEssenceHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_HISTORY_INIT
  };
};

export const updateCoreEssenceHistory = (
  user_id,
  canvas_id,
  history_id,
  core_essence
) => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    core_essence: core_essence
  };
};

export const updateCoreEssenceHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCoreEssenceHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_HISTORY_FAILURE,
    message: message
  };
};
