import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.creatives.action";
import * as canvasAction from "../actions/canvas.action";
import * as notification from "../actions/notification.action";
import * as history from "../actions/history/canvas.history.action";
import axios from "../config/axios";

// Update creatives
export function* updateCreativesSaga(action) {
  yield put(actions.updateCreativesStart());
  const data = {
    user_id: action.user_id,
    naming: action.naming,
    domain: action.domain,
    typography: action.typography,
    logo: action.logo,
    packaging: action.packaging
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/creatives`,
      data
    );
    yield put(
      actions.updateCreativesSuccess(response.status, response.message)
    );
    yield put(
      actions.setCreatives(
        action.naming,
        action.domain,
        action.typography,
        action.logo,
        action.packaging
      )
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCreativesFail(e.message));
  }
}

// Upload logo
export function* uploadLogoSaga(action) {
  yield put(actions.uploadLogoStart());
  yield put(
    notification.setNotification(
      true,
      false,
      "Image upload in progress"
    )
  );
  let formData = new FormData();
  formData.append("user_id", action.user_id);
  formData.append("canvas_id", action.canvas_id);
  formData.append("logo", action.file);

  try {
    let response = yield axios.post("/canvas/upload-logo", formData);
    yield put(actions.uploadLogoSuccess(response.message, response.data));
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
    yield put(actions.setLogo(response.data));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.uploadLogoFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Upload package
export function* uploadPackageSaga(action) {
  yield put(actions.uploadPackageStart());
  yield put(
    notification.setNotification(
      true,
      false,
      "Image upload in progress!"
    )
  );
  let formData = new FormData();
  formData.append("user_id", action.user_id);
  formData.append("canvas_id", action.canvas_id);
  formData.append("packaging", action.file);

  try {
    let response = yield axios.post("/canvas/upload-package", formData);
    yield put(actions.uploadPackageSuccess(response.message, response.data));
    yield put(actions.setPackaging(response.data));
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.uploadPackageFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Add color
export function* addColorSaga(action) {
  yield put(actions.addColorStart());

  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    color: action.color
  };

  try {
    let response = yield axios.post("/canvas/add-color", data);
    yield put(actions.addColorSuccess(response.message, response.data));
    yield put(actions.updateColorList(action.list, action.color));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.addColorFail(e.message));
    yield put(notification.setNotification(true, false, e.message));
  }
}

// Delete color
export function* deleteColorSaga(action) {
  yield put(actions.deleteColorStart());

  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    color: action.color
  };

  try {
    let response = yield axios.post("/canvas/delete-color", data);
    yield put(actions.deleteColorSuccess(response.message));
    yield put(actions.removeColorFromList(action.list, action.color));
    yield put(notification.setNotification(true, false, response.message));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.deleteColorFail(e.message));
  }
}

// Delete logo/package
export function* deleteCreativeFileSaga(action) {
  yield put(actions.deleteCreativeFileStart());

  const data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    type: action.fileType,
    path: action.path
  };

  try {
    let response = yield axios.post("/canvas/delete-creative-file", data);
    yield put(actions.deleteCreativeFileSuccess(response.message));

    if (action.fileType === "logo") {
      yield put(actions.setLogo(''));
    } else if (action.fileType === "packaging") {
      yield put(actions.setPackaging(''));
    }

    yield put(notification.setNotification(true, false, response.message));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.deleteCreativeFileFail(e.message));
  }
}
