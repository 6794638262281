import * as actionTypes from "../actionTypes";

// Update competition history
export const updateCompetitionHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_COMPETITION_HISTORY_INIT
  };
};

export const updateCompetitionHistory = (
  user_id,
  canvas_id,
  history_id,
  competition
) => {
  return {
    type: actionTypes.UPDATE_COMPETITION_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    competition: competition
  };
};

export const updateCompetitionHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_COMPETITION_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCompetitionHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_COMPETITION_HISTORY_FAILURE,
    message: message
  };
};
