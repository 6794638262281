import React, { Component } from "react";

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
    this.doneBtnRef = React.createRef();
  }

  state = {
    name: this.props.name,
    editMode: false,
    itemActivated: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.clickedCanvas !== this.props.clickedCanvas) {
      this.setState({ name: prevProps.name });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.name !== this.props.name) {
      this.setState({ name: nextProps.name });
    }

    if (nextProps.clickedCanvas !== this.props.clickedCanvas) {
      this.setState({ editMode: false, itemActivated: false });
      this.doneBtnRef.current.classList.remove("show");
    }

    if (nextProps.classname === "active") {
      this.setState({ itemActivated: true });
    }
  }

  editName = () => {
    if (this.state.itemActivated === true) {
      this.setState({ editMode: true });
      this.doneBtnRef.current.classList.add("show");
    }
  };

  changeHandler = event => {
    const name = event.target.value;
    this.setState({ name: name });
  };

  updateName = id => {
    this.doneBtnRef.current.classList.remove("show");
    this.setState({ editMode: false });
    this.props.updateName(id, this.state.name);
  };

  canvasDetails = id => {
    this.setState({ itemActivated: true });
    this.props.canvasDetails(id);
  };

  render() {
    let textClass;
    let inputClass;

    if (this.state.editMode === true) {
      textClass = "text__hidden";
      inputClass = "input__visible";
    } else {
      textClass = "text__visible";
      inputClass = "input__hidden";
    }

    return (
      <li key={this.props.id} className={this.props.classname}>
        <p
          className={["canvas_list", textClass].join(" ")}
          onClick={() => this.canvasDetails(this.props.id)}
          onChange={event => {
            this.changeHandler(event);
          }}
          ref={this.nameRef}
        >
          {this.state.name}
        </p>

        <input
          type="text"
          className={inputClass}
          value={this.state.name}
          onChange={event => {
            this.changeHandler(event);
          }}
        />

        {this.state.itemActivated === true ? (
          <span className={"menu__list-edit"} onClick={() => this.editName()}>
            <img src={this.props.editIcon} alt="edit" />
          </span>
        ) : null}

        <span className={"menu__list-delete"}>
          <img
            src={this.props.deleteIcon}
            alt="delete"
            onClick={() =>
              this.props.confirmDelete(this.props.id, this.props.index)
            }
          />
        </span>

        <span
          className={"menu__list-done"}
          ref={this.doneBtnRef}
          onClick={() => this.updateName(this.props.id)}
        >
          Done
        </span>
      </li>
    );
  }
}

export default ListItem;
