import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.channelTextValue.action";
import * as history from "../actions/history/canvas.history.action";
import * as canvasAction from "../actions/canvas.action";
import axios from "../config/axios";

// Update channel text value
export function* updateChannelTextValueSaga(action) {
  yield put(actions.updateChannelTextValueStart());
  const data = {
    user_id: action.user_id,
    channel_owned_text1: action.channel_owned_text1,
    channel_owned_value1: action.channel_owned_value1,
    channel_owned_text2: action.channel_owned_text2,
    channel_owned_value2: action.channel_owned_value2,
    channel_owned_text3: action.channel_owned_text3,
    channel_owned_value3: action.channel_owned_value3,
    channel_owned_text4: action.channel_owned_text4,
    channel_owned_value4: action.channel_owned_value4,
    channel_owned_text5: action.channel_owned_text5,
    channel_owned_value5: action.channel_owned_value5,

    channel_shared_text1: action.channel_shared_text1,
    channel_shared_value1: action.channel_shared_value1,
    channel_shared_text2: action.channel_shared_text2,
    channel_shared_value2: action.channel_shared_value2,
    channel_shared_text3: action.channel_shared_text3,
    channel_shared_value3: action.channel_shared_value3,
    channel_shared_text4: action.channel_shared_text4,
    channel_shared_value4: action.channel_shared_value4,
    channel_shared_text5: action.channel_shared_text5,
    channel_shared_value5: action.channel_shared_value5,

    channel_paid_text1: action.channel_paid_text1,
    channel_paid_value1: action.channel_paid_value1,
    channel_paid_text2: action.channel_paid_text2,
    channel_paid_value2: action.channel_paid_value2,
    channel_paid_text3: action.channel_paid_text3,
    channel_paid_value3: action.channel_paid_value3,
    channel_paid_text4: action.channel_paid_text4,
    channel_paid_value4: action.channel_paid_value4,
    channel_paid_text5: action.channel_paid_text5,
    channel_paid_value5: action.channel_paid_value5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/channel-text-value`,
      data
    );
    yield put(
      actions.updateChannelTextValueSuccess(response.status, response.message)
    );
    yield put(
      actions.setChannelTextValue(
        action.channel_owned_text1,
        action.channel_owned_value1,
        action.channel_owned_text2,
        action.channel_owned_value2,
        action.channel_owned_text3,
        action.channel_owned_value3,
        action.channel_owned_text4,
        action.channel_owned_value4,
        action.channel_owned_text5,
        action.channel_owned_value5,

        action.channel_shared_text1,
        action.channel_shared_value1,
        action.channel_shared_text2,
        action.channel_shared_value2,
        action.channel_shared_text3,
        action.channel_shared_value3,
        action.channel_shared_text4,
        action.channel_shared_value4,
        action.channel_shared_text5,
        action.channel_shared_value5,

        action.channel_paid_text1,
        action.channel_paid_value1,
        action.channel_paid_text2,
        action.channel_paid_value2,
        action.channel_paid_text3,
        action.channel_paid_value3,
        action.channel_paid_text4,
        action.channel_paid_value4,
        action.channel_paid_text5,
        action.channel_paid_value5
      )
    );
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateChannelTextValueFail(e.message));
  }
}
