import * as actionTypes from "../actionTypes";

export const initiateStart = () => {
  return {
    type: actionTypes.INITIATE_START
  };
};

export const initiateProcess = email => {
  return {
    type: actionTypes.INITIATE_PROCESS,
    email: email
  };
};

export const initiateSuccess = message => {
  return {
    type: actionTypes.INITIATE_SUCCESS,
    message: message
  };
};

export const initiateFail = message => {
  return {
    type: actionTypes.INITIATE_FAIL,
    message: message
  };
};

export const initiateClearMessage = () => {
  return {
    type: actionTypes.INITIATE_CLEAR_MESSAGE
  };
};

export const initiateReset = () => {
  return {
    type: actionTypes.INITIATE_RESET
  };
};
