import React, { Component } from "react";
import Aux from "../Auxilary/Auxilary";
import { connect } from "react-redux";
import * as canvasActions from "../../actions/canvas.action";
import $ from "jquery";
import Core from "../../components/user/Core/Core";
import Customer from "../../components/user/Customer/Customer";
import Competition from "../../components/user/Competition/Competition";
import Content from "../../components/user/Content/Content";
import Creatives from "../../components/user/Creatives/Creatives";
import Channel from "../../components/user/Channel/Channel";
import Header from "../../components/user/Header/Header";
import CreateCanvas from "../../components/user/Rightmenu/CreateCanvas/CreateCanvas";
import Notepad from "../../components/user/Rightmenu/Notepad/Notepad";
import Share from "../../components/user/Rightmenu/Share/Share";
import History from "../../components/user/Rightmenu/History/History";
import Account from "../../components/user/Rightmenu/Account/Account";
import Notification from "../../components/UI/Notification/Notification";

class Collaborate extends Component {
  constructor(props) {
    super(props);
    this.mainRef = React.createRef();
  }

  state = {
    isOpened: false,
    index: null,
    canvas_id: null,
    show: false
  };

  componentDidMount() {
    let path = this.props.match.path;
    let token = this.props.match.params.token;
    if (path.includes("collaborate")) {
      if (this.mainRef.current !== null) {
        this.mainRef.current.classList.add("collaborate");
        this.props.setCollaborateMode(token);
        $("input").attr("readonly", true);
        $("textarea").attr("readonly", true);
      }
    }

    this.props.getCanvasDetails(token);
    this.props.getCommentList(token);

    $(document).ready(function() {
      if ($("#main").hasClass("preview")) {
        $(".content__title-btn").each(function() {
          $(this).on("click", function() {
            $(this).toggleClass("off");

            let first = $(this)
              .parent()
              .nextAll(".content-first");
            let second = $(this)
              .parent()
              .nextAll(".content-second");

            if ($(this).hasClass("off")) {
              $(first).removeClass("show");
              $(second).addClass("show");
            } else {
              $(first).addClass("show");
              $(second).removeClass("show");
            }
          });
        });

        $(".video-open").on("click", function() {
          $(".video-popup").addClass("show");
        });
        $(".video-close").on("click", function() {
          $(".video-popup").removeClass("show");
        });

        $(".wrapper").on("click", function() {
          let sduration = $(".wrapper").css("transition-duration");
          let msduration = Number.parseFloat(sduration) * 1000;
          let comment = $(".content__comment");

          if (window.matchMedia("(min-width: 768px)").matches) {
            setTimeout(function() {
              if (
                ($(".wrapper").hasClass("menu-show") ||
                  $(".wrapper").hasClass("sidebar-notepad") ||
                  $(".wrapper").hasClass("sidebar-show")) &&
                $(".container").width() <= 1000
              ) {
                $(".wrapper").addClass("column");
                comment.addClass("position-mobile");
              } else {
                $(".wrapper").removeClass("column");
                comment.removeClass("position-mobile");
              }
            }, msduration);
          }
        });
      } else if ($("#main").hasClass("collaborate")) {
        $(".content__group").each(function() {
          $(this).on("focusin", function() {
            $(this)
              .children(".content__textarea")
              .addClass("cut");
            $(this)
              .children(".content__calcs")
              .addClass("cut");
            $(this)
              .children(".content__logo-big")
              .addClass("cut");
            $(this)
              .children(".content__subgroup-wrapper")
              .addClass("cut");
            $(this)
              .children(".content__textarea")
              .addClass("before");
            $(this)
              .children(".content__textarea")
              .next()
              .addClass("show");
            $(this)
              .children(".content__nav")
              .addClass("show");
          });

          $(this).on("focusout", function() {
            $(this)
              .children(".content__textarea")
              .removeClass("cut");
            $(this)
              .children(".content__calcs")
              .removeClass("cut");
            $(this)
              .children(".content__logo-big")
              .removeClass("cut");
            $(this)
              .children(".content__subgroup-wrapper")
              .removeClass("cut");

            $(this)
              .children(".content__textarea")
              .next()
              .removeClass("show");
            $(this)
              .children(".content__nav")
              .removeClass("show");

            if (
              $(this)
                .children(".content__textarea")
                .children("textarea")
                .val() === ""
            ) {
              $(this)
                .children(".content__textarea")
                .removeClass("before");
            }
            $(this)
              .next()
              .on("click", function() {
                $(this)
                  .children(".content__textarea")
                  .addClass("cut");
                $(this)
                  .children(".content__calcs")
                  .addClass("cut");
                $(this)
                  .children(".content__logo-big")
                  .addClass("cut");
                $(this)
                  .children(".content__subgroup-wrapper")
                  .addClass("cut");
                $(this)
                  .children(".content__textarea")
                  .addClass("before");
                $(this)
                  .children(".content__textarea")
                  .next()
                  .addClass("show");
              });
          });
        });

        $(".open-comment").each(function() {
          $(this).on("click", function() {
            $(this)
              .parent()
              .parent()
              .parent()
              .next()
              .toggleClass("show");
          });
        });

        $(document).on("click", function(e) {
          let comment = $(".content__comment");
          let nav = $(".content__nav");

          if (
            !comment.is(e.target) &&
            comment.has(e.target).length === 0 &&
            !nav.is(e.target) &&
            nav.has(e.target).length === 0
          ) {
            $(comment).removeClass("show");
          }
        });

        $(".content__comment-form").each(function() {
          $(this)
            .children("input")
            .on("click", function() {
              $(this)
                .parent()
                .parent()
                .removeClass("show");
            });
        });

        $(".content__comment-close").each(function() {
          $(this).on("click", function() {
            $(this)
              .parent()
              .removeClass("show");
          });
        });

        $(".content__title-btn").each(function() {
          $(this).on("click", function() {
            $(this).toggleClass("off");

            let first = $(this)
              .parent()
              .nextAll(".content-first");
            let second = $(this)
              .parent()
              .nextAll(".content-second");

            if ($(this).hasClass("off")) {
              $(first).removeClass("show");
              $(second).addClass("show");
            } else {
              $(first).addClass("show");
              $(second).removeClass("show");
            }
          });
        });

        $(".video-open").on("click", function() {
          $(".video-popup").addClass("show");
        });
        $(".video-close").on("click", function() {
          $(".video-popup").removeClass("show");
        });

        $(".wrapper").on("click", function() {
          let sduration = $(".wrapper").css("transition-duration");
          let msduration = Number.parseFloat(sduration) * 1000;
          let comment = $(".content__comment");

          if (window.matchMedia("(min-width: 768px)").matches) {
            setTimeout(function() {
              if (
                ($(".wrapper").hasClass("menu-show") ||
                  $(".wrapper").hasClass("sidebar-notepad") ||
                  $(".wrapper").hasClass("sidebar-show")) &&
                $(".container").width() <= 1000
              ) {
                $(".wrapper").addClass("column");
                comment.addClass("position-mobile");
              } else {
                $(".wrapper").removeClass("column");
                comment.removeClass("position-mobile");
              }
            }, msduration);
          }
        });
      } else {
        $(".menu-toggler").on("click", function() {
          $(".wrapper").toggleClass("menu-show");
        });

        $(".header__buttons button").each(function() {
          let name = $(this).data("target");
          // let plus = $(".menu__search-buttons button").data("target");

          $(this).on("click", function() {
            if ($("#" + name).hasClass("show")) {
              $("#" + name).removeClass("show");
              $(".wrapper").removeClass("sidebar-show sidebar-notepad");
            } else {
              $(".sidebar>div").removeClass("show");
              $("#" + name).addClass("show");

              if (name === "notepad") {
                $(".wrapper").removeClass("sidebar-show");
                $(".wrapper").addClass("sidebar-notepad");
              } else {
                $(".wrapper").removeClass("sidebar-notepad");
                $(".wrapper").addClass("sidebar-show");
              }
            }
          });
        });

        $(".plus-btn").each(function() {
          let plus = $(this).data("target");

          $(this).on("click", function() {
            if ($("#" + plus).hasClass("show")) {
              $("#" + plus).removeClass("show");
              $(".wrapper").removeClass("sidebar-show sidebar-notepad");
            } else {
              $(".sidebar>div").removeClass("show");
              $("#" + plus).addClass("show");

              if (plus === "notepad") {
                $(".wrapper").removeClass("sidebar-show");
                $(".wrapper").addClass("sidebar-notepad");
              } else {
                $(".wrapper").removeClass("sidebar-notepad");
                $(".wrapper").addClass("sidebar-show");
              }
            }
          });
        });

        $(".share__copy").each(function() {
          $(this).on("click", function() {
            $(this).addClass("sent");
            let that = $(this);
            setTimeout(function() {
              that.removeClass("sent");
            }, 1500);
          });
        });

        $(".content__group").each(function() {
          $(this)
            .children(".content__nav")
            .children(".content__nav-buttons")
            .children(".save")
            .on("click", function() {
              $(this)
                .parent()
                .parent()
                .addClass("nsaved");
              let that = $(this);
              setTimeout(function() {
                that
                  .parent()
                  .parent()
                  .removeClass("nsaved");
              }, 1500);
            });

          $(this).on("focusin", function() {
            $(this)
              .children(".content__textarea")
              .addClass("cut");
            $(this)
              .children(".content__calcs")
              .addClass("cut");
            $(this)
              .children(".content__logo-big")
              .addClass("cut");
            $(this)
              .children(".content__subgroup-wrapper")
              .addClass("cut");
            $(this)
              .children(".content__textarea")
              .addClass("before");
            $(this)
              .children(".content__textarea")
              .next()
              .addClass("show");
            $(this)
              .children(".content__nav")
              .addClass("show");
          });

          $(this).on("focusout", function() {
            $(this)
              .children(".content__textarea")
              .removeClass("cut");
            $(this)
              .children(".content__calcs")
              .removeClass("cut");
            $(this)
              .children(".content__logo-big")
              .removeClass("cut");
            $(this)
              .children(".content__subgroup-wrapper")
              .removeClass("cut");

            $(this)
              .children(".content__textarea")
              .next()
              .removeClass("show");
            $(this)
              .children(".content__nav")
              .removeClass("show");

            if (
              $(this)
                .children(".content__textarea")
                .children("textarea")
                .val() === ""
            ) {
              $(this)
                .children(".content__textarea")
                .removeClass("before");
            }
            $(this)
              .next()
              .on("click", function() {
                $(this)
                  .children(".content__textarea")
                  .addClass("cut");
                $(this)
                  .children(".content__calcs")
                  .addClass("cut");
                $(this)
                  .children(".content__logo-big")
                  .addClass("cut");
                $(this)
                  .children(".content__subgroup-wrapper")
                  .addClass("cut");
                $(this)
                  .children(".content__textarea")
                  .addClass("before");
                $(this)
                  .children(".content__textarea")
                  .next()
                  .addClass("show");
              });
          });
        });

        $(".open-comment").each(function() {
          $(this).on("click", function() {
            $(this)
              .parent()
              .parent()
              .parent()
              .next()
              .toggleClass("show");
          });
        });

        $(document).on("click", function(e) {
          let comment = $(".content__comment");
          let nav = $(".content__nav");

          if (
            !comment.is(e.target) &&
            comment.has(e.target).length === 0 &&
            !nav.is(e.target) &&
            nav.has(e.target).length === 0
          ) {
            $(comment).removeClass("show");
          }
        });

        $(".content__comment-form").each(function() {
          $(this)
            .children("input")
            .on("click", function() {
              $(this)
                .parent()
                .parent()
                .removeClass("show");
            });
        });

        $(".content__comment-close").each(function() {
          $(this).on("click", function() {
            $(this)
              .parent()
              .removeClass("show");
          });
        });

        $(".sidebar__close").each(function() {
          $(this).on("click", function() {
            $(this)
              .parent()
              .parent()
              .removeClass("show");
            $(".wrapper").removeClass("sidebar-show sidebar-notepad column");
          });
        });

        $(".content__group").each(function() {
          if (
            $(this)
              .children(".content__textarea")
              .children("textarea")
              .val() !== ""
          ) {
            $(this)
              .children(".content__textarea")
              .addClass("before");
          }
        });

        $(".content__title-btn").each(function() {
          $(this).on("click", function() {
            $(this).toggleClass("off");

            let first = $(this)
              .parent()
              .nextAll(".content-first");
            let second = $(this)
              .parent()
              .nextAll(".content-second");

            if ($(this).hasClass("off")) {
              $(first).removeClass("show");
              $(second).addClass("show");
            } else {
              $(first).addClass("show");
              $(second).removeClass("show");
            }
          });
        });

        $(".video-open").on("click", function() {
          $(".video-popup").addClass("show");
        });
        $(".video-close").on("click", function() {
          $(".video-popup").removeClass("show");
        });

        $(".wrapper").on("click", function() {
          let sduration = $(".wrapper").css("transition-duration");
          let msduration = Number.parseFloat(sduration) * 1000;
          let comment = $(".content__comment");

          if (window.matchMedia("(min-width: 768px)").matches) {
            setTimeout(function() {
              if (
                ($(".wrapper").hasClass("menu-show") ||
                  $(".wrapper").hasClass("sidebar-notepad") ||
                  $(".wrapper").hasClass("sidebar-show")) &&
                $(".container").width() <= 1000
              ) {
                $(".wrapper").addClass("column");
                comment.addClass("position-mobile");
              } else {
                $(".wrapper").removeClass("column");
                comment.removeClass("position-mobile");
              }
            }, msduration);
          }
        });
      }
    });

    $(window).on("resize load", function() {
      if (this.matchMedia("(max-width: 1024px)").matches) {
        $(".wrapper").removeClass("menu-show");
      }

      if (window.matchMedia("(max-width: 767px)").matches) {
        $(".content__comment").addClass("position-mobile");
      } else {
        $(".content__comment").removeClass("position-mobile");
      }
    });
  }

  bookBBCall = () => {
    window.open("https://ebrandcall.com", "_blank");
  };

  render() {
    return (
      <Aux>
        <div id="main" ref={this.mainRef}>
          <div className={"wrapper"}>
            {/*  Start .container */}
            <Notification />
            <main className={"container"}>
              {/*  Start .header */}
              <Header />
              {/*  End /.header */}

              {/*  Start .content */}
              <div className={"content"}>
                <div className={"content__box col"}>
                  <Core />
                </div>

                <div className={"content__box col"}>
                  <Customer />
                </div>

                <div className={"content__box col"}>
                  <Competition />

                  <Creatives />
                </div>

                <div className={"content__box col"}>
                  <Content />
                </div>

                <div className={"content__box col"}>
                  <Channel />
                </div>
              </div>

              <footer className={"footer"}>
                <button className={"video-open"} onClick={this.bookBBCall}>
                  <img src="../img/main/video.svg" alt="video" />
                  Watch the masterclass
                </button>
              </footer>
              {/*  End /.footer */}
            </main>
            {/*  End /.container */}

            {/*  Start .sidebar */}
            <div className={"sidebar"}>
              {/*  Start .history */}
              <History />
              {/*  End /.history */}

              {/*  Start .share */}
              <Share />
              {/*  End /.share */}

              {/*  Start .account */}
              <Account />
              {/*  End /.account */}

              {/*  Start .create */}
              <CreateCanvas />
              {/*  End /.create */}

              {/* Start .notepad */}
              <Notepad />
            </div>

            <div className={"video-popup"}>
              <div className={"video-popup__title"}>
                <h2>
                  How the 7C method transformed a -$2K online store into
                  <br /> a $1.59M global eCommerce brand, in just 12 months.
                </h2>
              </div>

              <div className={"video-popup__frame"}>
                <iframe
                  title="masterclass"
                  src="https://www.youtube.com/embed/6H4lkcHg7g8?controls=0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>

              <div className={"video-popup__close video-close"}>
                <button>&#215;</button>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCollaborateMode: token => {
      dispatch(canvasActions.setCanvasCollaborate(token));
    },
    getCanvasDetails: token => {
      dispatch(canvasActions.canvasView(token));
    },
    getCommentList: token => {
      dispatch(canvasActions.collaborateCommentsFetch(token));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Collaborate);
