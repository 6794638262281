import * as actionTypes from "./actionTypes";

// Update core purpose
export const updateCorePurposeStart = () => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_INIT
  };
};

export const updateCorePurpose = (user_id, canvas_id, core_purpose) => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE,
    user_id: user_id,
    canvas_id: canvas_id,
    core_purpose: core_purpose
  };
};

export const updateCorePurposeSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCorePurposeFail = message => {
  return {
    type: actionTypes.UPDATE_CORE_PURPOSE_FAILURE,
    message: message
  };
};

// Set updated core purpose in store
export const setCorePurpose = core_purpose => {
  return {
    type: actionTypes.SET_CORE_PURPOSE,
    core_purpose: core_purpose
  };
};

// Update core values
export const updateCoreValuesStart = () => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_INIT
  };
};

export const updateCoreValues = (user_id, canvas_id, core_values) => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES,
    user_id: user_id,
    canvas_id: canvas_id,
    core_values: core_values
  };
};

export const updateCoreValuesSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCoreValuesFail = message => {
  return {
    type: actionTypes.UPDATE_CORE_VALUES_FAILURE,
    message: message
  };
};

// Set updated core values in store
export const setCoreValues = core_values => {
  return {
    type: actionTypes.SET_CORE_VALUES,
    core_values: core_values
  };
};

// Update core essence
export const updateCoreEssenceStart = () => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_INIT
  };
};

export const updateCoreEssence = (user_id, canvas_id, core_essence) => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE,
    user_id: user_id,
    canvas_id: canvas_id,
    core_essence: core_essence
  };
};

export const updateCoreEssenceSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCoreEssenceFail = message => {
  return {
    type: actionTypes.UPDATE_CORE_ESSENCE_FAILURE,
    message: message
  };
};

// Set updated core essence in store
export const setCoreEssence = core_essence => {
  return {
    type: actionTypes.SET_CORE_ESSENCE,
    core_essence: core_essence
  };
};
