import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import $ from "jquery";
import Comment from "../../user/Comment/Comment";
import * as canvasActions from "../../../actions/canvas.action";

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.contentGroupRef = React.createRef();
    this.contentTextAreaRef = React.createRef();
    this.contentNavRef = React.createRef();
    this.spinnerRef = React.createRef();
    this.checkmarkRef = React.createRef();
    this.textAreaRef = React.createRef();
    this.commentBtnRef = React.createRef();
    this.contentNavButtonRef = React.createRef();
  }

  state = {
    canvas_id: this.props.canvas_id,
    field: null,
    value: this.props.value,
    showSuccess: this.props.showSuccess,
    maxLimit: this.props.maxLimit,
    alertLimit: this.props.alertLimit,
    alertLength: 0,
    showAlert: false,
    focusOutCounter: 0,
    displayComment: false
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.showSuccess !== this.props.showSuccess &&
      this.props.identifier === prevProps.field
    ) {
      this.showSuccess();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      canvas_id: nextProps.canvas_id
    });

    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value
      });
    }

    if (nextProps.value === "" && this.props.value === "") {
      // if textarea value are empty, add before class to show lines
      this.contentTextAreaRef.current.classList.remove("before");
    } else {
      // when switching between canvas having a value & one without a value
      this.contentTextAreaRef.current.classList.remove("before");
      this.contentTextAreaRef.current.classList.remove("cut");
    }

    if (nextProps.value !== "" && this.props.value !== "") {
      if (this.contentTextAreaRef.current !== null) {
        this.contentTextAreaRef.current.classList.add("before");
      } else {
        this.contentTextAreaRef.current.classList.remove("cut");
      }
    }
  }

  contentGroupFocus = () => {
    this.contentTextAreaRef.current.classList.add("before");
    this.contentTextAreaRef.current.classList.add("cut");
    this.contentNavRef.current.classList.add("show");
  };

  contentGroupBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        focusOutCounter: Number(this.state.focusOutCounter) + Number(1)
      });

      setTimeout(
        function () {
          if (this.state.focusOutCounter === 1) {
            if (this.state.value !== this.props.value) {
              this.save();
            } else if (this.state.value === this.props.value) {
              if (this.state.value === "") {
                this.contentTextAreaRef.current.classList.remove("before");
                this.contentTextAreaRef.current.classList.remove("cut");
                this.hideButtonBox();
              } else {
                this.contentTextAreaRef.current.classList.remove("cut");
                this.hideButtons();
                this.hideButtonBox();
              }
            } else {
              if (
                this.state.field !== "customer_audience" &&
                this.state.field !== "customer_elevate" &&
                this.state.field !== "community" &&
                this.state.field !== "content_story" &&
                this.state.field !== "content_brand" &&
                this.state.field !== "content_core" &&
                this.state.field !== "content_customer" &&
                this.state.field !== "content_community" &&
                this.state.field !== "content_competition" &&
                this.state.field !== "content_creatives"
              ) {
                this.hideButtons();
                this.hideButtonBox();
              }
            }
          }
        }.bind(this),
        500
      );
    } else {
    }
  };

  // Hide comment, save & cancel button
  hideButtons = () => {
    this.commentBtnRef.current.classList.add("hide_cs_btn");
    this.contentNavButtonRef.current.classList.add("hide_cs_btn");
  };

  // Show comment, save & cancel button
  showButtons = () => {
    this.contentNavButtonRef.current.classList.remove("hide_cs_btn");
  };

  // Function to show progress bubble
  showProgress = () => {
    this.spinnerRef.current.classList.remove("spinner_hidden");
  };

  // Remove progress bubble & checkmark
  showSuccess = () => {
    this.spinnerRef.current.classList.add("spinner_hidden");
    this.checkmarkRef.current.classList.remove("checkmark__hidden");

    setTimeout(
      function () {
        this.hideCheckmark();
        this.hideButtonBox();
        $("input").attr("disabled", false);
        $("textarea").attr("disabled", false);
      }.bind(this),
      2000
    );
  };

  hideCheckmark = () => {
    this.checkmarkRef.current.classList.add("checkmark__hidden");
  };

  hideButtonBox = () => {
    this.contentNavRef.current.classList.remove("show");
    setTimeout(() => {
      this.commentBtnRef.current.classList.remove("hide_cs_btn");
    }, 1000);
  };

  // Input change
  changeHandler = (field, event) => {
    const value = event.target.value;
    this.setState(state => {
      return {
        field: field,
        value: value,
        showSuccess: false,
        focusOutCounter: 0
      };
    });

    let valueLength = value.length;
    let alertLength = Number(this.state.maxLimit) - Number(valueLength);

    if (alertLength <= this.state.alertLimit) {
      this.setState({ showAlert: true, alertLength: alertLength });
    } else {
      this.setState({ showAlert: false });
    }

    if (value !== null || value !== "") {
      this.contentTextAreaRef.current.classList.add("before");
    } else {
      this.contentTextAreaRef.current.classList.remove("before");
    }
    if (value !== this.props.value) {
      this.contentNavButtonRef.current.classList.remove("hide_cs_btn");
    } else {
      this.contentNavButtonRef.current.classList.add("hide_cs_btn");
    }
  };

  clickHandler = () => {
    this.setState({ focusOutCounter: 0 });
  };

  // Save function
  save = () => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      $("input").attr("disabled", true);
      $("textarea").attr("disabled", true);
      this.setState({
        showAlert: false,
        alertLength: 0
      });

      this.hideButtons();
      this.showProgress();

      setTimeout(
        function () {
          if (this.state.focusOutCounter === 1) {
            this.props.save(this.state.field, this.state.value);
            this.setState({ focusOutCounter: 0 });
          }
        }.bind(this),
        500
      );
    }
  };

  cancel = () => {
    this.setState({
      value: this.props.value,
      showAlert: false,
      alertLength: 0,
      focusOutCounter: 0
    });
    this.hideButtons();
    this.hideButtonBox();
  };

  showComment = section => {
    if (this.state.canvas_id !== null) {
      this.setState({ displayComment: true });

      this.props.filterComment(
        this.state.canvas_id,
        this.props.commentList.comment,
        section
      );
    }
  };

  hideComment = section => {
    this.setState({ displayComment: false });
    this.hideButtonBox();
  };

  render() {
    if (this.state.value !== "") {
      if (this.contentTextAreaRef.current !== null) {
        this.contentTextAreaRef.current.classList.add("before");
      }
    } else {
      if (this.textAreaRef.current !== null) {
        this.textAreaRef.current.value = null;
      }
    }

    return (
      <Aux>
        <div
          className={["content__group", this.props.nextClass].join(" ")}
          onBlur={event => this.contentGroupBlur(event)}
          onFocus={this.contentGroupFocus}
          ref={this.contentGroupRef}
        >
          <div className={"content__subtitle"}>
            <h3>{this.props.title}</h3>
          </div>

          <div className={"content__textarea"} ref={this.contentTextAreaRef}>
            {this.state.showAlert === true ? (
              <span className={"symbols-left"}>{this.state.alertLength}</span>
            ) : null}
            <textarea
              id={this.props.id}
              value={this.state.value}
              maxLength={this.props.maxLimit}
              ref={this.textAreaRef}
              placeholder={this.props.placeholder}
              onChange={event => this.changeHandler(this.props.field, event)}
              onClick={this.clickHandler}
            />
            <label htmlFor={this.props.id} />
          </div>

          <div className={"content__nav"} ref={this.contentNavRef}>
            <div className={"content__nav-comment"} ref={this.commentBtnRef}>
              <button
                className="open-comment"
                onClick={() => this.showComment(this.props.commentSection)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                  <path d="M873.3,145.5c32.8,0,60.2,26.4,61.2,59.1v456.5c0,15.2-6.1,29.8-17.2,40.9c-11.1,11.2-25.7,17.3-41.1,17.3 H769.5c-19.2,0-37.4,8.8-49.4,23.9L636.3,849L546,741.8c-12-14.2-29.6-22.4-48.2-22.4h-374c-15.2,0-29.8-6.1-40.9-17.2 c-11.2-11.1-17.3-25.7-17.3-41.1V203.8c0-15.2,6.1-29.8,17.2-40.9c11.1-11.2,25.7-17.3,41.1-17.3H873.3 M873.3,82.5H123.8 C56.6,82.5,2.5,138.1,2.5,203.8v457.3c0,67.2,55.5,121.3,121.3,121.3h374l100.8,119.8c10.2,10.2,23.7,15.3,37.4,15.3 c13.7,0,27.6-5.1,38.5-15.3l95-119.8h106.6c67.2,0,121.3-55.5,121.3-121.3V203.8C996,136.6,940.5,82.5,873.3,82.5L873.3,82.5z" />
                </svg>
              </button>
            </div>

            {/* Progress bubbles */}
            <div className={"spinner spinner_hidden"} ref={this.spinnerRef}>
              <div className={"bounce1"} />
              <div className={"bounce2"} />
              <div className={"bounce3"} />
            </div>
            {/* Checkmark - Success */}
            <div
              className={"checkmark checkmark__hidden"}
              ref={this.checkmarkRef}
            >
              <svg
                className={"checkmark-svg"}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className={"checkmark__circle"}
                  cx="26"
                  cy="26"
                  r="26"
                  fill="none"
                />
                <path
                  className={"checkmark__check"}
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            {/* Error */}
            <div className={"danger__hidden"}>
              <svg
                className={"danger"}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className={"danger__circle"}
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className={"danger__check"}
                  fill="none"
                  d="M16 16 36 36 M36 16 16 36"
                />
              </svg>
            </div>
            <div
              className={"content__nav-buttons hide_cs_btn "}
              ref={this.contentNavButtonRef}
            >
              <button
                type="button"
                onClick={() => this.cancel(this.props.field)}
              >
                Cancel
              </button>

              <button type="button" onClick={() => this.save(this.props.field)}>
                Save
              </button>
            </div>
          </div>
        </div>

        <Comment
          id={this.props.id}
          canvas_id={this.state.canvas_id}
          section={this.props.commentSection}
          position={this.props.commentBoxPosition}
          hideComment={this.hideComment}
          displayComment={this.state.displayComment}
        />
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterComment: (canvas_id, list, section) => {
      dispatch(canvasActions.filterComment(canvas_id, list, section));
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasView: state.canvasView,
    commentList: state.commentList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextArea);
