import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as canvasActions from "../../../actions/canvas.action";
import * as channelTextValue from "../../../actions/canvas.channelTextValue.action";
import * as historyActions from "../../../actions/history/channelTextValue.history.action";
import Comment from "../Comment/Comment";
import TextValue from "./TextValue";
import $ from "jquery";
import { getUserIdFromToken } from "../../../shared/utils/utils";

class ChannelTextValue extends Component {
  constructor(props) {
    super(props);
    this.contentGroupRef = React.createRef();
    this.contentNavRef = React.createRef();
    this.contentNavButtonRef = React.createRef();
    this.contentGroupWrapperRef = React.createRef();
    this.spinnerRef = React.createRef();
    this.checkMarkRef = React.createRef();
    this.commentBtnRef = React.createRef();
  }

  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,

    channel_owned_text1: "",
    channel_owned_value1: "",
    channel_owned_text2: "",
    channel_owned_value2: "",
    channel_owned_text3: "",
    channel_owned_value3: "",
    channel_owned_text4: "",
    channel_owned_value4: "",
    channel_owned_text5: "",
    channel_owned_value5: "",

    channel_shared_text1: "",
    channel_shared_value1: "",
    channel_shared_text2: "",
    channel_shared_value2: "",
    channel_shared_text3: "",
    channel_shared_value3: "",
    channel_shared_text4: "",
    channel_shared_value4: "",
    channel_shared_text5: "",
    channel_shared_value5: "",

    channel_paid_text1: "",
    channel_paid_value1: "",
    channel_paid_text2: "",
    channel_paid_value2: "",
    channel_paid_text3: "",
    channel_paid_value3: "",
    channel_paid_text4: "",
    channel_paid_value4: "",
    channel_paid_text5: "",
    channel_paid_value5: "",

    alertLength: 0,
    showAlert: false,
    reverseAlert: false,
    focusOutCounter: 0,
    valueSaved: false,
    displayComment: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.channelTextValue !== this.props.channelTextValue) {
      this.setState({
        canvas_id: nextProps.channelTextValue.canvas_id,
        history_id: nextProps.channelTextValue.history_id,

        channel_owned_text1: nextProps.channelTextValue.channel_owned_text1,
        channel_owned_value1: nextProps.channelTextValue.channel_owned_value1,
        channel_owned_text2: nextProps.channelTextValue.channel_owned_text2,
        channel_owned_value2: nextProps.channelTextValue.channel_owned_value2,
        channel_owned_text3: nextProps.channelTextValue.channel_owned_text3,
        channel_owned_value3: nextProps.channelTextValue.channel_owned_value3,
        channel_owned_text4: nextProps.channelTextValue.channel_owned_text4,
        channel_owned_value4: nextProps.channelTextValue.channel_owned_value4,
        channel_owned_text5: nextProps.channelTextValue.channel_owned_text5,
        channel_owned_value5: nextProps.channelTextValue.channel_owned_value5,

        channel_shared_text1: nextProps.channelTextValue.channel_shared_text1,
        channel_shared_value1: nextProps.channelTextValue.channel_shared_value1,
        channel_shared_text2: nextProps.channelTextValue.channel_shared_text2,
        channel_shared_value2: nextProps.channelTextValue.channel_shared_value2,
        channel_shared_text3: nextProps.channelTextValue.channel_shared_text3,
        channel_shared_value3: nextProps.channelTextValue.channel_shared_value3,
        channel_shared_text4: nextProps.channelTextValue.channel_shared_text4,
        channel_shared_value4: nextProps.channelTextValue.channel_shared_value4,
        channel_shared_text5: nextProps.channelTextValue.channel_shared_text5,
        channel_shared_value5: nextProps.channelTextValue.channel_shared_value5,

        channel_paid_text1: nextProps.channelTextValue.channel_paid_text1,
        channel_paid_value1: nextProps.channelTextValue.channel_paid_value1,
        channel_paid_text2: nextProps.channelTextValue.channel_paid_text2,
        channel_paid_value2: nextProps.channelTextValue.channel_paid_value2,
        channel_paid_text3: nextProps.channelTextValue.channel_paid_text3,
        channel_paid_value3: nextProps.channelTextValue.channel_paid_value3,
        channel_paid_text4: nextProps.channelTextValue.channel_paid_text4,
        channel_paid_value4: nextProps.channelTextValue.channel_paid_value4,
        channel_paid_text5: nextProps.channelTextValue.channel_paid_text5,
        channel_paid_value5: nextProps.channelTextValue.channel_paid_value5
      });
    }

    if (
      nextProps.UpdateChannelTextValue.status !==
      this.props.UpdateChannelTextValue.status
    ) {
      if (nextProps.UpdateChannelTextValue.status === "completed") {
        this.showSuccess();
      }
    }
  }

  contentGroupFocus = () => {
    this.contentNavRef.current.classList.add("show");
  };

  contentGroupBlur = event => {
    let ownTextCounter = 0;
    let ownValueCounter = 0;
    let sharedTextCounter = 0;
    let sharedValueCounter = 0;
    let paidTextCounter = 0;
    let paidValueCounter = 0;

    for (let i = 1; i <= 5; i++) {
      if (
        this.state["channel_owned_text" + i] !==
        this.props.channelTextValue["channel_owned_text" + i]
      ) {
        ownTextCounter = Number(ownTextCounter) + Number(1);
      } else if (
        this.state["channel_owned_value" + i] !==
        this.props.channelTextValue["channel_owned_value" + i]
      ) {
        ownValueCounter = Number(ownValueCounter) + Number(1);
      } else if (
        this.state["channel_shared_text" + i] !==
        this.props.channelTextValue["channel_shared_text" + i]
      ) {
        sharedTextCounter = Number(sharedTextCounter) + Number(1);
      } else if (
        this.state["channel_shared_value" + i] !==
        this.props.channelTextValue["channel_shared_value" + i]
      ) {
        sharedValueCounter = Number(sharedValueCounter) + Number(1);
      } else if (
        this.state["channel_paid_text" + i] !==
        this.props.channelTextValue["channel_paid_text" + i]
      ) {
        paidTextCounter = Number(paidTextCounter) + Number(1);
      } else if (
        this.state["channel_paid_value" + i] !==
        this.props.channelTextValue["channel_paid_value" + i]
      ) {
        paidValueCounter = Number(paidValueCounter) + Number(1);
      }
    }

    if (
      ownTextCounter === 0 &&
      ownValueCounter === 0 &&
      sharedTextCounter === 0 &&
      sharedValueCounter === 0 &&
      paidTextCounter === 0 &&
      paidValueCounter === 0
    ) {
      this.hideButtonBox();
    }

    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        focusOutCounter: Number(this.state.focusOutCounter) + Number(1)
      });
      setTimeout(
        function() {
          if (
            this.state.focusOutCounter === 1 &&
            this.state.valueSaved !== true
          ) {
            if (
              ownTextCounter !== 0 ||
              ownValueCounter !== 0 ||
              sharedTextCounter !== 0 ||
              sharedValueCounter !== 0 ||
              paidTextCounter !== 0 ||
              paidValueCounter !== 0
            ) {
              this.save();
            }
          }
        }.bind(this),
        500
      );
    } else {
    }
  };

  // Remove "hide_cs_btn" class
  removeClass = cssClass => {
    this.contentNavButtonRef.current.classList.remove(cssClass);
  };

  // Add "hide_cs_btn" class
  addClass = cssClass => {
    this.contentNavButtonRef.current.classList.add(cssClass);
  };

  // Function to show progress bubble
  showProgress = () => {
    this.spinnerRef.current.classList.remove("spinner_hidden");
  };

  showSuccess = () => {
    this.spinnerRef.current.classList.add("spinner_hidden");
    this.checkMarkRef.current.classList.remove("checkmark__hidden");

    setTimeout(
      function() {
        this.hideCheckmark();
        this.hideButtonBox();
        this.setState({ reverseSaveBtn: false });
        $("input").attr("disabled", false);
        $("textarea").attr("disabled", false);
      }.bind(this),
      2000
    );
  };

  hideCheckmark = () => {
    this.setState({ disabled: false });
    this.checkMarkRef.current.classList.add("checkmark__hidden");
  };

  // Hide save & cancel button
  hideButtons = () => {
    this.commentBtnRef.current.classList.add("hide_cs_btn");
    this.contentNavButtonRef.current.classList.add("hide_cs_btn");
  };

  hideButtonBox = () => {
    this.contentNavRef.current.classList.remove("show");
    setTimeout(() => {
      this.commentBtnRef.current.classList.remove("hide_cs_btn");
    }, 1000);
  };

  cancel = () => {
    this.setState({
      clickedCancelBtn: true,
      showAlert: false,
      alertLength: 0,
      reverseAlert: !this.state.reverseAlert,
      channel_owned_text1: this.props.channelTextValue.channel_owned_text1,
      channel_owned_value1: this.props.channelTextValue.channel_owned_value1,
      channel_owned_text2: this.props.channelTextValue.channel_owned_text2,
      channel_owned_value2: this.props.channelTextValue.channel_owned_value2,
      channel_owned_text3: this.props.channelTextValue.channel_owned_text3,
      channel_owned_value3: this.props.channelTextValue.channel_owned_value3,
      channel_owned_text4: this.props.channelTextValue.channel_owned_text4,
      channel_owned_value4: this.props.channelTextValue.channel_owned_value4,
      channel_owned_text5: this.props.channelTextValue.channel_owned_text5,
      channel_owned_value5: this.props.channelTextValue.channel_owned_value5,

      channel_shared_text1: this.props.channelTextValue.channel_shared_text1,
      channel_shared_value1: this.props.channelTextValue.channel_shared_value1,
      channel_shared_text2: this.props.channelTextValue.channel_shared_text2,
      channel_shared_value2: this.props.channelTextValue.channel_shared_value2,
      channel_shared_text3: this.props.channelTextValue.channel_shared_text3,
      channel_shared_value3: this.props.channelTextValue.channel_shared_value3,
      channel_shared_text4: this.props.channelTextValue.channel_shared_text4,
      channel_shared_value4: this.props.channelTextValue.channel_shared_value4,
      channel_shared_text5: this.props.channelTextValue.channel_shared_text5,
      channel_shared_value5: this.props.channelTextValue.channel_shared_value5,

      channel_paid_text1: this.props.channelTextValue.channel_paid_text1,
      channel_paid_value1: this.props.channelTextValue.channel_paid_value1,
      channel_paid_text2: this.props.channelTextValue.channel_paid_text2,
      channel_paid_value2: this.props.channelTextValue.channel_paid_value2,
      channel_paid_text3: this.props.channelTextValue.channel_paid_text3,
      channel_paid_value3: this.props.channelTextValue.channel_paid_value3,
      channel_paid_text4: this.props.channelTextValue.channel_paid_text4,
      channel_paid_value4: this.props.channelTextValue.channel_paid_value4,
      channel_paid_text5: this.props.channelTextValue.channel_paid_text5,
      channel_paid_value5: this.props.channelTextValue.channel_paid_value5
    });
    this.hideButtons();
    this.hideButtonBox();
  };

  changeHandler = (field, value) => {
    let ownTextIndex = field.substr(18, 1);
    let ownValueIndex = field.substr(19, 1);
    let sharedTextIndex = field.substr(19, 1);
    let sharedValueIndex = field.substr(20, 1);
    let paidTextIndex = field.substr(17, 1);
    let paidValueIndex = field.substr(18, 1);

    this.setState({ valueSaved: false, focusOutCounter: 0 });

    for (let i = 0; i < 5; i++) {
      if (field === "channel_owned_text" + ownTextIndex) {
        this.setState({ [field]: value });
        if (
          value !==
          this.props.channelTextValue["channel_owned_text" + ownTextIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_owned_value" + ownValueIndex) {
        this.setState({ [field]: value });
        if (
          value !==
          this.props.channelTextValue["channel_owned_value" + ownValueIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_shared_text" + sharedTextIndex) {
        this.setState({ [field]: value });
        if (
          value !==
          this.props.channelTextValue["channel_shared_text" + sharedTextIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_shared_value" + sharedValueIndex) {
        this.setState({ [field]: value });
        if (
          value !==
          this.props.channelTextValue["channel_shared_value" + sharedValueIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_paid_text" + paidTextIndex) {
        this.setState({ [field]: value });
        if (
          value !==
          this.props.channelTextValue["channel_paid_text" + paidTextIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_paid_value" + paidValueIndex) {
        this.setState({ [field]: value });
        if (
          value !==
          this.props.channelTextValue["channel_paid_value" + paidValueIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      }
    }
  };

  save = () => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      if (
        this.props.channelTextValue.canvas_id !== null &&
        this.props.channelTextValue.canvas_id !== ""
      ) {
        $("input").attr("disabled", true);
        $("textarea").attr("disabled", true);
        this.setState({
          showAlert: false,
          reverseAlert: !this.state.reverseAlert,
          alertLength: 0,
          valueSaved: true
        });
        this.hideButtons();
        this.showProgress();
        if (this.state.history_id !== null) {
          this.props.updateChannelTextValueHistory(
            this.state.user_id,
            this.state.canvas_id,
            this.state.history_id,
            this.state.channel_owned_text1,
            this.state.channel_owned_value1,
            this.state.channel_owned_text2,
            this.state.channel_owned_value2,
            this.state.channel_owned_text3,
            this.state.channel_owned_value3,
            this.state.channel_owned_text4,
            this.state.channel_owned_value4,
            this.state.channel_owned_text5,
            this.state.channel_owned_value5,

            this.state.channel_shared_text1,
            this.state.channel_shared_value1,
            this.state.channel_shared_text2,
            this.state.channel_shared_value2,
            this.state.channel_shared_text3,
            this.state.channel_shared_value3,
            this.state.channel_shared_text4,
            this.state.channel_shared_value4,
            this.state.channel_shared_text5,
            this.state.channel_shared_value5,

            this.state.channel_paid_text1,
            this.state.channel_paid_value1,
            this.state.channel_paid_text2,
            this.state.channel_paid_value2,
            this.state.channel_paid_text3,
            this.state.channel_paid_value3,
            this.state.channel_paid_text4,
            this.state.channel_paid_value4,
            this.state.channel_paid_text5,
            this.state.channel_paid_value5
          );
        } else {
          this.props.updateChannelTextValue(
            this.state.user_id,
            this.state.canvas_id,
            this.state.channel_owned_text1,
            this.state.channel_owned_value1,
            this.state.channel_owned_text2,
            this.state.channel_owned_value2,
            this.state.channel_owned_text3,
            this.state.channel_owned_value3,
            this.state.channel_owned_text4,
            this.state.channel_owned_value4,
            this.state.channel_owned_text5,
            this.state.channel_owned_value5,

            this.state.channel_shared_text1,
            this.state.channel_shared_value1,
            this.state.channel_shared_text2,
            this.state.channel_shared_value2,
            this.state.channel_shared_text3,
            this.state.channel_shared_value3,
            this.state.channel_shared_text4,
            this.state.channel_shared_value4,
            this.state.channel_shared_text5,
            this.state.channel_shared_value5,

            this.state.channel_paid_text1,
            this.state.channel_paid_value1,
            this.state.channel_paid_text2,
            this.state.channel_paid_value2,
            this.state.channel_paid_text3,
            this.state.channel_paid_value3,
            this.state.channel_paid_text4,
            this.state.channel_paid_value4,
            this.state.channel_paid_text5,
            this.state.channel_paid_value5
          );
        }
      }
    }
  };

  showComment = () => {
    if (this.state.canvas_id !== null) {
      this.setState({ displayComment: true });
      this.props.filterComment(
        this.state.canvas_id,
        this.props.commentList.comment,
        "channel_text_value"
      );
    }
  };

  hideComment = () => {
    this.setState({ displayComment: false });
    this.hideButtonBox();
  };

  render() {
    let totalRevenue;

    totalRevenue =
      Number(this.state.channel_owned_value1) +
      Number(this.state.channel_owned_value2) +
      Number(this.state.channel_owned_value3) +
      Number(this.state.channel_owned_value4) +
      Number(this.state.channel_owned_value5) +
      Number(this.state.channel_shared_value1) +
      Number(this.state.channel_shared_value2) +
      Number(this.state.channel_shared_value3) +
      Number(this.state.channel_shared_value4) +
      Number(this.state.channel_shared_value5) +
      Number(this.state.channel_paid_value1) +
      Number(this.state.channel_paid_value2) +
      Number(this.state.channel_paid_value3) +
      Number(this.state.channel_paid_value4) +
      Number(this.state.channel_paid_value5);

    return (
      <Aux>
        <div
          className={"content__group-wrapper group-long"}
          ref={this.contentGroupWrapperRef}
        >
          <div
            className={"content__group"}
            onBlur={event => this.contentGroupBlur(event)}
            onFocus={this.contentGroupFocus}
            ref={this.contentGroupRef}
          >
            <div
              className={"content__calcs auto-scroll"}
              ref={this.contentCalcs}
            >
              <TextValue
                canvas_id={this.state.canvas_id}
                title={"OWNED CHANNEL"}
                changeHandler={this.changeHandler}
                numberValidation={true}
                field={"channel_owned"}
                maxLimit={10}
                alertLimit={5}
                showAlert={this.state.showAlert}
                reverseAlert={this.state.reverseAlert}
                fields={[
                  "channel_owned_text1",
                  "channel_owned_value1",
                  "channel_owned_text2",
                  "channel_owned_value2",
                  "channel_owned_text3",
                  "channel_owned_value3",
                  "channel_owned_text4",
                  "channel_owned_value4",
                  "channel_owned_text5",
                  "channel_owned_value5"
                ]}
                values={[
                  this.state.channel_owned_text1,
                  this.state.channel_owned_value1,
                  this.state.channel_owned_text2,
                  this.state.channel_owned_value2,
                  this.state.channel_owned_text3,
                  this.state.channel_owned_value3,
                  this.state.channel_owned_text4,
                  this.state.channel_owned_value4,
                  this.state.channel_owned_text5,
                  this.state.channel_owned_value5
                ]}
              />
              <TextValue
                canvas_id={this.state.canvas_id}
                title={"SHARED CHANNEL"}
                changeHandler={this.changeHandler}
                numberValidation={true}
                field={"channel_shared"}
                maxLimit={10}
                alertLimit={5}
                showAlert={this.state.showAlert}
                reverseAlert={this.state.reverseAlert}
                fields={[
                  "channel_shared_text1",
                  "channel_shared_value1",
                  "channel_shared_text2",
                  "channel_shared_value2",
                  "channel_shared_text3",
                  "channel_shared_value3",
                  "channel_shared_text4",
                  "channel_shared_value4",
                  "channel_shared_text5",
                  "channel_shared_value5"
                ]}
                values={[
                  this.state.channel_shared_text1,
                  this.state.channel_shared_value1,
                  this.state.channel_shared_text2,
                  this.state.channel_shared_value2,
                  this.state.channel_shared_text3,
                  this.state.channel_shared_value3,
                  this.state.channel_shared_text4,
                  this.state.channel_shared_value4,
                  this.state.channel_shared_text5,
                  this.state.channel_shared_value5
                ]}
              />
              <TextValue
                canvas_id={this.state.canvas_id}
                title={"PAID CHANNEL"}
                changeHandler={this.changeHandler}
                numberValidation={true}
                field={"channel_paid"}
                maxLimit={10}
                alertLimit={5}
                showAlert={this.state.showAlert}
                reverseAlert={this.state.reverseAlert}
                fields={[
                  "channel_paid_text1",
                  "channel_paid_value1",
                  "channel_paid_text2",
                  "channel_paid_value2",
                  "channel_paid_text3",
                  "channel_paid_value3",
                  "channel_paid_text4",
                  "channel_paid_value4",
                  "channel_paid_text5",
                  "channel_paid_value5"
                ]}
                values={[
                  this.state.channel_paid_text1,
                  this.state.channel_paid_value1,
                  this.state.channel_paid_text2,
                  this.state.channel_paid_value2,
                  this.state.channel_paid_text3,
                  this.state.channel_paid_value3,
                  this.state.channel_paid_text4,
                  this.state.channel_paid_value4,
                  this.state.channel_paid_text5,
                  this.state.channel_paid_value5
                ]}
              />
              <div className="content__calc">
                <div className="content__line">
                  <h5 className={"content__line-total"}>TOTAL REVENUE</h5>
                  <input
                    type="text"
                    className={"content__line-in2"}
                    disabled
                    value={totalRevenue}
                  />
                </div>
              </div>

              <div className="content__nav" ref={this.contentNavRef}>
                <div className="content__nav-comment" ref={this.commentBtnRef}>
                  <button
                    className="open-comment"
                    onClick={() => this.showComment()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1000 1000"
                    >
                      <path d="M873.3,145.5c32.8,0,60.2,26.4,61.2,59.1v456.5c0,15.2-6.1,29.8-17.2,40.9c-11.1,11.2-25.7,17.3-41.1,17.3 H769.5c-19.2,0-37.4,8.8-49.4,23.9L636.3,849L546,741.8c-12-14.2-29.6-22.4-48.2-22.4h-374c-15.2,0-29.8-6.1-40.9-17.2 c-11.2-11.1-17.3-25.7-17.3-41.1V203.8c0-15.2,6.1-29.8,17.2-40.9c11.1-11.2,25.7-17.3,41.1-17.3H873.3 M873.3,82.5H123.8 C56.6,82.5,2.5,138.1,2.5,203.8v457.3c0,67.2,55.5,121.3,121.3,121.3h374l100.8,119.8c10.2,10.2,23.7,15.3,37.4,15.3 c13.7,0,27.6-5.1,38.5-15.3l95-119.8h106.6c67.2,0,121.3-55.5,121.3-121.3V203.8C996,136.6,940.5,82.5,873.3,82.5L873.3,82.5z" />
                    </svg>
                  </button>
                </div>
                {/* Progress bubbles */}
                <div className={"spinner spinner_hidden"} ref={this.spinnerRef}>
                  <div className={"bounce1"} />
                  <div className={"bounce2"} />
                  <div className={"bounce3"} />
                </div>
                {/* Checkmark - Success */}
                <div
                  className={"checkmark checkmark__hidden"}
                  ref={this.checkMarkRef}
                >
                  <svg
                    className={"checkmark-svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className={"checkmark__circle"}
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className={"checkmark__check"}
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
                <div
                  className={"content__nav-buttons hide_cs_btn"}
                  ref={this.contentNavButtonRef}
                >
                  <button type="button" onClick={() => this.cancel()}>
                    Cancel
                  </button>

                  <button type="button" onClick={() => this.save()}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Comment
            id={"channel_text_value"}
            canvas_id={this.state.canvas_id}
            section={"channel_text_value"}
            position={"left"}
            hideComment={this.hideComment}
            displayComment={this.state.displayComment}
          />
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateChannelTextValue: (
      user_id,
      canvas_id,
      channel_owned_text1,
      channel_owned_value1,
      channel_owned_text2,
      channel_owned_value2,
      channel_owned_text3,
      channel_owned_value3,
      channel_owned_text4,
      channel_owned_value4,
      channel_owned_text5,
      channel_owned_value5,
      channel_shared_text1,
      channel_shared_value1,
      channel_shared_text2,
      channel_shared_value2,
      channel_shared_text3,
      channel_shared_value3,
      channel_shared_text4,
      channel_shared_value4,
      channel_shared_text5,
      channel_shared_value5,
      channel_paid_text1,
      channel_paid_value1,
      channel_paid_text2,
      channel_paid_value2,
      channel_paid_text3,
      channel_paid_value3,
      channel_paid_text4,
      channel_paid_value4,
      channel_paid_text5,
      channel_paid_value5
    ) => {
      dispatch(
        channelTextValue.updateChannelTextValue(
          user_id,
          canvas_id,
          channel_owned_text1,
          channel_owned_value1,
          channel_owned_text2,
          channel_owned_value2,
          channel_owned_text3,
          channel_owned_value3,
          channel_owned_text4,
          channel_owned_value4,
          channel_owned_text5,
          channel_owned_value5,
          channel_shared_text1,
          channel_shared_value1,
          channel_shared_text2,
          channel_shared_value2,
          channel_shared_text3,
          channel_shared_value3,
          channel_shared_text4,
          channel_shared_value4,
          channel_shared_text5,
          channel_shared_value5,
          channel_paid_text1,
          channel_paid_value1,
          channel_paid_text2,
          channel_paid_value2,
          channel_paid_text3,
          channel_paid_value3,
          channel_paid_text4,
          channel_paid_value4,
          channel_paid_text5,
          channel_paid_value5
        )
      );
    },
    updateChannelTextValueHistory: (
      user_id,
      canvas_id,
      history_id,
      channel_owned_text1,
      channel_owned_value1,
      channel_owned_text2,
      channel_owned_value2,
      channel_owned_text3,
      channel_owned_value3,
      channel_owned_text4,
      channel_owned_value4,
      channel_owned_text5,
      channel_owned_value5,
      channel_shared_text1,
      channel_shared_value1,
      channel_shared_text2,
      channel_shared_value2,
      channel_shared_text3,
      channel_shared_value3,
      channel_shared_text4,
      channel_shared_value4,
      channel_shared_text5,
      channel_shared_value5,
      channel_paid_text1,
      channel_paid_value1,
      channel_paid_text2,
      channel_paid_value2,
      channel_paid_text3,
      channel_paid_value3,
      channel_paid_text4,
      channel_paid_value4,
      channel_paid_text5,
      channel_paid_value5
    ) => {
      dispatch(
        historyActions.updateChannelTextValueHistory(
          user_id,
          canvas_id,
          history_id,
          channel_owned_text1,
          channel_owned_value1,
          channel_owned_text2,
          channel_owned_value2,
          channel_owned_text3,
          channel_owned_value3,
          channel_owned_text4,
          channel_owned_value4,
          channel_owned_text5,
          channel_owned_value5,
          channel_shared_text1,
          channel_shared_value1,
          channel_shared_text2,
          channel_shared_value2,
          channel_shared_text3,
          channel_shared_value3,
          channel_shared_text4,
          channel_shared_value4,
          channel_shared_text5,
          channel_shared_value5,
          channel_paid_text1,
          channel_paid_value1,
          channel_paid_text2,
          channel_paid_value2,
          channel_paid_text3,
          channel_paid_value3,
          channel_paid_text4,
          channel_paid_value4,
          channel_paid_text5,
          channel_paid_value5
        )
      );
    },
    filterComment: (canvas_id, list, section) => {
      dispatch(canvasActions.filterComment(canvas_id, list, section));
    }
  };
};

const mapStateToProps = state => {
  return {
    commentList: state.commentList,
    channelTextValue: state.channelTextValue,
    UpdateChannelTextValue: state.updateChannelTextValue,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelTextValue);
