import React, { Component } from "react";
import { connect } from "react-redux";
import * as canvasActions from "../../../actions/canvas.action";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import $ from "jquery";

class Comment extends Component {
  constructor(props) {
    super(props);
    this.contentCommentRef = React.createRef();
  }

  state = {
    token: "",
    displayComment: false,
    clickedSubmitBtn: false,
    clickedCommentInput: false
  };

  componentDidMount() {
    const that = this;
    $(document).on("click", function (e) {
      if (that.state.displayComment === true) {
        let comment = $(".content__comment");
        let nav = $(".content__nav");

        if (
          !comment.is(e.target) &&
          comment.has(e.target).length === 0 &&
          !nav.is(e.target) &&
          nav.has(e.target).length === 0
        ) {
          that.props.hideComment(that.props.section);
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.displayComment !== this.state.displayComment) {
      this.setState({ displayComment: nextProps.displayComment });
    }
  }

  commentFocusOut = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      // setTimeout(
      //   function() {
      //     if (
      //       this.state.clickedCommentInput === false &&
      //       this.state.clickedSubmitBtn === false
      //     ) {
      //       // this.setState({ displayComment: false });
      //       // this.props.hideComment(this.props.section);
      //     }

      //   }.bind(this),
      //   500
      // );
      this.props.hideComment(this.props.section);
    } else {
    }
  };

  clickHandler = () => {
    this.setState({ clickedCommentInput: true });
  };

  closeCommentBox = section => {
    this.props.hideComment(section);
  };

  render() {
    const textAreaClass = {
      resize: "none"
    };

    const privateCommentSchema = Yup.object().shape({
      comment: Yup.string().required("Comment is required")
    });

    const collaborateCommentSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      comment: Yup.string().required("Comment is required")
    });

    // Comment form (dashboard)
    let commentForm = (
      <Formik
        enableReinitialize="true"
        initialValues={{
          comment: ""
        }}
        validationSchema={privateCommentSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const user_id = getUserIdFromToken();
          const canvas_id = this.props.canvas_id;
          const list = this.props.filterCommentList.comment;
          if (this.props.canvas_id !== null) {
            this.setState({ clickedSubmitBtn: true });
            this.props.addComment(
              user_id,
              canvas_id,
              list,
              this.props.section,
              values.comment
            );
            setSubmitting(false);
            resetForm();
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component="textarea"
              id={this.props.section}
              name="comment"
              style={textAreaClass}
              onClick={this.clickHandler}
              placeholder="Add Comment"
            />
            <ErrorMessage
              className="validation__error"
              name="comment"
              component="div"
            />

            <input type="submit" value="submit" disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );

    // Collaborate comment form
    let collaborateCommentForm = (
      <Formik
        enableReinitialize="true"
        initialValues={{
          email: "",
          comment: ""
        }}
        validationSchema={collaborateCommentSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          if (this.props.canvasDetails.canvas_id !== null) {
            const list = this.props.filterCommentList.comment;
            this.setState({ clickedSubmitBtn: true });
            this.props.collaborateAddComment(
              this.props.canvasView.token,
              this.props.canvasDetails.canvas_id,
              list,
              values.email,
              this.props.section,
              values.comment
            );
            setSubmitting(false);
            resetForm();
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field type="email" name="email" placeholder="Email" />
            <ErrorMessage
              className={"validation__error"}
              name="email"
              component="div"
            />

            <Field
              component="textarea"
              id={this.props.section}
              name="comment"
              style={textAreaClass}
              onClick={this.clickHandler}
              placeholder="Add Comment"
            />
            <ErrorMessage
              className="validation__error"
              name="comment"
              component="div"
            />

            <input type="submit" value="submit" disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );

    return (
      <Aux>
        {this.state.displayComment === true ? (
          <div
            id={this.props.section}
            ref={this.contentCommentRef}
            className={["content__comment show", this.props.position].join(" ")}
            key={this.state.displayComment}
            tabIndex="1"
            onBlur={event => this.commentFocusOut(event)}
          >
            <div className={"content__comment-items"}>
              {this.props.filterCommentList.comment.map((comment, index) => {
                return (
                  <div className={"content__comment-item"} key={index}>
                    <p>{comment.comment}</p>
                    <span>
                      {comment.name} | {comment.date} | {comment.time}
                    </span>
                  </div>
                );
              })}
            </div>

            <div className={"content__comment-form"}>
              {this.props.canvasView.collaborate === true
                ? collaborateCommentForm
                : commentForm}
            </div>
            <div className="content__comment-close">
              <button onClick={() => this.closeCommentBox(this.props.section)}>
                x
              </button>
            </div>
          </div>
        ) : null}
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addComment: (user_id, canvas_id, list, section, comment) => {
      dispatch(
        canvasActions.addComment(user_id, canvas_id, list, section, comment)
      );
    },
    collaborateAddComment: (
      token,
      canvas_id,
      list,
      email,
      section,
      comment
    ) => {
      dispatch(
        canvasActions.canvasCollaborateAddComment(
          token,
          canvas_id,
          list,
          email,
          section,
          comment
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    commentList: state.commentList,
    filterCommentList: state.filterCommentList,
    canvasView: state.canvasView,
    canvasDetails: state.canvasDetails
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comment);