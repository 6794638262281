import React, { Component, Fragment } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import "./styles.css";

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
  }

  state = {};

  selectFile = () => {
    if (
      this.props.canvasView.preview !== true &&
      this.props.canvasView.collaborate !== true
    ) {
      this.fileRef.current.click();
    }
  };

  render() {
    let filePreviewStyle = {
      height: "7rem",
      width: " 7rem",
      cursor: "pointer"
    };

    return (
      <Aux>
        <h3>{this.props.title}</h3>
        <input
          ref={this.fileRef}
          type="file"
          style={{ display: "none" }}
          onChange={event => this.props.uploadFile(event, this.props.type)}
        />
        <div onClick={this.selectFile}>
          {this.props.fileExist === false ? (
            <img
              style={filePreviewStyle}
              src="/img/main/square-plus.svg"
              alt="Add Logo"
            />
          ) : (
            <Fragment>
              <img
                style={filePreviewStyle}
                src={this.props.file}
                alt="Creative Logo"
              />
            </Fragment>
          )}
        </div>

        {this.props.fileExist && <div className="creatives-image">
          <div className="remove-creative-image"
            onClick={() => this.props.removeFile(this.props.type, this.props.file)}>Remove</div>
        </div>}
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  null
)(FileUploader);
