import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

const signupState = {
  error: null,
  loading: false,
  isSignedUp: false,
  message: ""
};

export const signup = (state = signupState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_INIT:
      return updateObject(state, { loading: true, error: null });

    case actionTypes.SIGNUP_SUCCESS:
      return updateObject(state, {
        loading: false,
        error: false,
        isSignedUp: true,
        message: action.message
      });

    case actionTypes.SIGNUP_FAIL:
      return updateObject(state, {
        loading: false,
        error: true,
        isSignedUp: false,
        message: action.message
      });

    case actionTypes.CLEAR_SIGNUP_MESSAGE:
      return updateObject(state, {
        loading: false,
        error: null,
        message: null
      });

    case actionTypes.RESET_SIGNUP:
      return updateObject(state, {
        loading: false,
        error: null,
        message: null,
        isSignedUp: false
      });

    default:
      return state;
  }
};

// Whether user is present in the subscriber list
const userInListState = {
  loading: false,
  error: false,
  message: "",
  inList: false
};

export const userInList = (state = userInListState, action) => {
  switch (action.type) {
    case actionTypes.CHECK_USER_IN_LIST_INIT:
      return updateObject(state, {
        loading: true,
        error: false,
        inList: false
      });

    case actionTypes.CHECK_USER_IN_LIST_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message,
        inList: true
      });

    case actionTypes.CHECK_USER_IN_LIST_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        inList: false,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
