import { combineReducers } from "redux";
import { notification } from "./notification.reducer";
import {signup, userInList} from "./signup.reducer";
import login from "./login.reducer";
import forgotPassword from "./forgotPassword/resetInitiate.reducer";
import verifyInitiate from "./forgotPassword/verifyInitiate.reducer";
import resetPassword from "./forgotPassword/resetPassword.reducer";
import { accountDetails, accountUpdate } from "./account.reducer";
import { canvasView, collaborateAddComment } from "./canvas.view.reducer";
import {
  createCanvas,
  canvasList,
  commentList,
  filterCommentList,
  canvasDelete,
  canvasDetails,
  sendCanvas,
  notepad,
  updateNotepad,
  canvasCopyInvitation
} from "./canvas.reducer";

import {
  core,
  updateCorePurpose,
  updateCoreValues,
  updateCoreEssence
} from "./canvas.core.reducer";

import {
  customer,
  updateCustomerAudience,
  updateCustomerElevate,
  updateCommunity
} from "./canvas.customer.reducer";

import { competition, updateCompetition } from "./canvas.competition";
import {
  contentStory,
  contentCalendar,
  updateContentStory,
  updateContentCalendar
} from "./canvas.content.reducer";

import {
  creatives,
  updateCreatives,
  addColor,
  deleteColor
} from "./canvas.creatives.reducer";

import {
  channelTextOwned,
  updateChannelTextOwned,
  channelTextShared,
  updateChannelTextShared,
  channelTextPaid,
  updateChannelTextPaid
} from "./canvas.channelText.reducer";

import {
  channelTextValue,
  updateChannelTextValue
} from "./canvas.channelTextValue.reducer.js";

import { canvasHistoryList } from "./history/history.list.reducer";


export default combineReducers({
  // Notification
  notification: notification,
  signup: signup,
  userInList: userInList,
  login: login,

  forgotPassword: forgotPassword,
  verifyInitiate: verifyInitiate,
  resetPassword: resetPassword,

  accountDetails: accountDetails,
  accountUpdate: accountUpdate,
  // Canvas
  createCanvas: createCanvas,
  canvasList: canvasList,
  canvasDelete: canvasDelete,
  canvasDetails: canvasDetails,
  sendCanvas: sendCanvas,
  notepad: notepad,
  updateNotepad: updateNotepad,
  // Core
  core: core,
  updateCorePurpose: updateCorePurpose,
  updateCoreValues: updateCoreValues,
  updateCoreEssence: updateCoreEssence,
  // Customer
  customer: customer,
  updateCustomerAudience: updateCustomerAudience,
  updateCustomerElevate: updateCustomerElevate,
  updateCommunity: updateCommunity,
  // Competition
  competition: competition,
  updateCompetition: updateCompetition,
  // Content
  contentStory: contentStory,
  contentCalendar: contentCalendar,
  updateContentStory: updateContentStory,
  updateContentCalendar: updateContentCalendar,
  // Creatives
  creatives: creatives,
  updateCreatives: updateCreatives,
  addColor: addColor,
  deleteColor: deleteColor,
  // Channel text owned
  channelTextOwned: channelTextOwned,
  updateChannelTextOwned: updateChannelTextOwned,
  // Channel text shared
  channelTextShared: channelTextShared,
  updateChannelTextShared: updateChannelTextShared,
  // Channel text paid
  channelTextPaid: channelTextPaid,
  updateChannelTextPaid: updateChannelTextPaid,
  // Channel text value
  channelTextValue: channelTextValue,
  updateChannelTextValue: updateChannelTextValue,
  // Comment
  commentList: commentList,
  filterCommentList: filterCommentList,
  // Canvas copy invitation
  canvasCopyInvitation: canvasCopyInvitation,
  // Canvas view mode
  canvasView: canvasView,
  collaborateAddComment: collaborateAddComment,
  // Canvas history list
  canvasHistoryList: canvasHistoryList,
});
