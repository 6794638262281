import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Notification store
const notificationState = {
  show: false,
  status: "",
  error: false,
  message: "",
  newStatus: ""
};

export const notification = (state = notificationState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      return updateObject(state, {
        show: true,
        status: action.status,
        error: action.error,
        message: action.message,
        newStatus: action.newStatus
      });

    case actionTypes.INVALID_FILE_FORMAT:
      return updateObject(state, {
        show: true,
        status: action.status,
        error: action.error,
        message: action.message
      });

    case actionTypes.CLEAR_NOTIFICATION:
      return updateObject(state, {
        show: false,
        status: "",
        error: false,
        message: "",
        newStatus: ""
      });

    default: {
      return state;
    }
  }
};
