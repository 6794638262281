import * as actionTypes from "../../actions/actionTypes";
import {
  updateObject,
  modifyHistoryList,
  appendHistoryDataToList
} from "../../shared/utils/utils";

// Canvas history list store
const canvasHistoryListState = {
  loading: false,
  history: null,
  canvas_id: null,
  error: false,
  message: "",
  hasMore: true,
  caller: null
};

export const canvasHistoryList = (state = canvasHistoryListState, action) => {
  switch (action.type) {
    case actionTypes.CANVAS_HISTORY_LIST_INIT:
      return updateObject(state, {
        loading: true,
        error: false,
        caller: null
      });

    case actionTypes.CANVAS_HISTORY_LIST:
      return updateObject(state, { loading: true });

    case actionTypes.CANVAS_HISTORY_LIST_SUCCESS:
      let modifiedList = modifyHistoryList(action.list);
      if (action.list.length > 0) {
        if (action.list.length < 10) {
          return updateObject(state, {
            loading: false,
            message: action.message,
            history: modifiedList,
            canvas_id: action.list[0].canvas_id,
            hasMore: false,
            // caller: true
          });
        } else {
          return updateObject(state, {
            loading: false,
            message: action.message,
            history: modifiedList,
            canvas_id: action.list[0].canvas_id,
            hasMore: true
          });
        }
      } else {
        return updateObject(state, {
          loading: false,
          message: action.message,
          history: modifiedList,
          // hasMore: false,
          canvas_id: null
        });
      }

    case actionTypes.CANVAS_HISTORY_LIST_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    case actionTypes.RESET_HISTORY_LIST_SCROLLER:
      return updateObject(state, {
        hasMore: true,
        caller: true
      });

    case actionTypes.CANVAS_HISTORY_LIST_LOAD_MORE_SUCCESS:
      let addedList = appendHistoryDataToList(action.oldList, action.newList);
      let updatedList = modifyHistoryList(addedList);
      if (action.newList.length < 10) {
        return updateObject(state, {
          history: updatedList,
          hasMore: false
        });
      } else {
        return updateObject(state, {
          history: updatedList,
          hasMore: true
        });
      }

    default: {
      return state;
    }
  }
};
