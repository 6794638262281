import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Aux from "../../../../hoc/Auxilary/Auxilary";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../../../config/config";
import styles from "./Share.module.css";
import * as canvasActions from "../../../../actions/canvas.action";
import { getUserIdFromToken } from "../../../../shared/utils/utils";

class Share extends Component {
  constructor(props) {
    super(props);
    this.viewURL = React.createRef();
    this.collaborateURL = React.createRef();
  }

  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    viewUrl: "",
    collaborateUrl: "",
    sentClass: ""
  };

  componentDidUpdate(prevProps) {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.canvasDetails !== this.props.canvasDetails) {
      this.setState({
        canvas_id: nextProps.canvasDetails.canvas_id,
        viewUrl: config.viewUrl + nextProps.canvasDetails.viewUrl,
        collaborateUrl:
          config.commentUrl + nextProps.canvasDetails.collaborateUrl
      });
    }

    if (
      this.props.canvasList.canvas.length === 0 &&
      nextProps.canvasDelete.status === "deleted"
    ) {
      this.setState({ canvas_id: null, viewUrl: "", collaborateUrl: "" });
    }

    if (nextProps.sendCanvas.status === "completed") {
      this.setState({ sentClass: "sent" });
      setTimeout(
        function() {
          this.setState({ sentClass: "" });
        }.bind(this),
        2000
      );
    } else if (nextProps.sendCanvas.error === true) {
      this.setState({ sentClass: "nsent" });
      setTimeout(
        function() {
          this.setState({ sentClass: "" });
        }.bind(this),
        2000
      );
    }
  }

  viewLinkCopy = (text, result) => {
    if (result === true) {
      this.viewURL.current.classList.add("copied");
      setTimeout(() => {
        this.viewURL.current.classList.remove("copied");
      }, 2000);
    } else {
      this.viewURL.current.classList.add("ncopied");
      setTimeout(() => {
        this.viewURL.current.classList.remove("ncopied");
      }, 2000);
    }
  };

  collaborateLinkCopy = (text, result) => {
    if (result === true) {
      this.collaborateURL.current.classList.add("copied");
      setTimeout(() => {
        this.collaborateURL.current.classList.remove("copied");
      }, 2000);
    } else {
      this.collaborateURL.current.classList.add("ncopied");
      setTimeout(() => {
        this.collaborateURL.current.classList.remove("ncopied");
      }, 2000);
    }
  };

  render() {
    const copyCanvasSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required")
    });

    return (
      <Aux>
        <div className={"share"} id="share">
          <div className={"share__title"}>
            <h2>Share</h2>

            <div className={"sidebar__close"}>
              <button>x</button>
            </div>
          </div>

          <div className={"share__blocks"}>
            <div className={"share__block"}>
              <div className={"share__image"}>
                <img src="/img/main/share-1.svg" alt="share" />
              </div>

              <div className={"share__info"}>
                <h2>VIEW ONLY</h2>

                <p>
                  SHARE THIS LINK TO ALLOW PEOPLE
                  <br />
                  "VIEW ONLY" ACCESS.
                </p>
              </div>

              <div className={"share__link"}>
                <input
                  type="text"
                  className={styles.urlInput}
                  readOnly
                  defaultValue={this.state.viewUrl}
                />
              </div>

              {this.state.viewUrl !== "" ? (
                <CopyToClipboard
                  text={this.state.viewUrl}
                  onCopy={this.viewLinkCopy}
                >
                  <button className={"share__copy"} ref={this.viewURL}>
                    Copy link
                  </button>
                </CopyToClipboard>
              ) : null}
            </div>

            <div className={"share__block"}>
              <div className={"share__image"}>
                <img src="/img/main/share-2.svg" alt="share" />
              </div>

              <div className={"share__info"}>
                <h2>VIEW AND COMMENT</h2>

                <p>
                  SHARE THIS LINK TO ALLOW PEOPLE
                  <br />
                  TO VIEW AND LEAVE COMMENTS.
                </p>
              </div>

              <div className={"share__link"}>
                <input
                  type="text"
                  className={styles.urlInput}
                  readOnly
                  defaultValue={this.state.collaborateUrl}
                />
              </div>

              {this.state.collaborateUrl !== "" ? (
                <CopyToClipboard
                  text={this.state.collaborateUrl}
                  onCopy={this.collaborateLinkCopy}
                >
                  <button className={"share__copy"} ref={this.collaborateURL}>
                    Copy link
                  </button>
                </CopyToClipboard>
              ) : null}
            </div>

            <div className={"share__block"}>
              <div className={"share__image"}>
                <img src="/img/main/share-3.svg" alt="share" />
              </div>

              <div className={"share__info"}>
                <h2>SEND A COPY TO A USER</h2>

                <p>
                  ALLOW OTHER USERS TO DUPLICATE
                  <br />
                  THIS BRANDSCRIPT INSIDE THEIR
                  <br />
                  PROFILE.
                </p>
              </div>

              {this.state.canvas_id !== null ? (
                <div className={"share__link"}>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={copyCanvasSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      this.props.sendCanvasCopy(
                        this.state.user_id,
                        this.state.canvas_id,
                        values.email
                      );
                      setSubmitting(false);
                      resetForm();
                    }}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                        />
                        <ErrorMessage
                          className={"validation__error share__via__email"}
                          name="email"
                          component="div"
                        />
                        <br />

                        <br />
                        <button
                          type="submit"
                          className={["share__copy", this.state.sentClass].join(
                            " "
                          )}
                          disabled={isSubmitting}
                        >
                          Send copy
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    sendCanvasCopy: (user_id, canvas_id, email) => {
      dispatch(canvasActions.sendCanvas(user_id, canvas_id, email));
    }
  };
};

const mapStateToProps = state => {
  return {
    canvasDetails: state.canvasDetails,
    sendCanvas: state.sendCanvas,
    canvasList: state.canvasList,
    canvasDelete: state.canvasDelete
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Share);
