import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Customer data store
const customerDetailsState = {
  canvas_id: null,
  history_id: null,
  customer_audience: "",
  customer_elevate: "",
  community: ""
};

export const customer = (state = customerDetailsState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          customer_audience: action.details.customer_audience,
          customer_elevate: action.details.customer_elevate,
          community: action.details.community
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          customer_audience: action.details.customer_audience,
          customer_elevate: action.details.customer_elevate,
          community: action.details.community
        });
      }

    case actionTypes.CLEAR_CUSTOMER_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        customer_audience: "",
        customer_elevate: "",
        community: ""
      });

    case actionTypes.SET_CUSTOMER_AUDIENCE:
      return updateObject(state, {
        customer_audience: action.customer_audience
      });

    case actionTypes.SET_CUSTOMER_ELEVATE:
      return updateObject(state, {
        customer_elevate: action.customer_elevate
      });

    case actionTypes.SET_COMMUNITY:
      return updateObject(state, {
        community: action.community
      });

    default: {
      return state;
    }
  }
};

// Update customer audience
const updateCustomerAudienceState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCustomerAudience = (
  state = updateCustomerAudienceState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CUSTOMER_AUDIENCE_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CUSTOMER_AUDIENCE_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CUSTOMER_AUDIENCE_FAILURE:
      return updateObject(state, {
        loading: false,
        status: "idle",
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CUSTOMER_AUDIENCE_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        status: "idle",
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update customer elevate
const updateCustomerElevateState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCustomerElevate = (
  state = updateCustomerElevateState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CUSTOMER_ELEVATE_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CUSTOMER_ELEVATE_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CUSTOMER_ELEVATE_FAILURE:
      return updateObject(state, {
        loading: false,
        status: "idle",
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CUSTOMER_ELEVATE_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        status: "idle",
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update community
const updateCommunityState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCommunity = (state = updateCommunityState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMMUNITY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_COMMUNITY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_COMMUNITY_FAILURE:
      return updateObject(state, {
        loading: false,
        status: "idle",
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_COMMUNITY_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_COMMUNITY_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_COMMUNITY_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        status: "idle",
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
