import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/canvas.history.action";
import * as canvasAction from "../../actions/canvas.action";
import axios from "../../config/axios";
// import publicAxios from "../../config/axios_public";

// Canvas history list
export function* canvasHistoryListSaga(action) {
  yield put(actions.canvasHistoryListStart());
  let data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id
  };
  try {
    let response = yield axios.post(`/canvas/history`, data);
    yield put(
      actions.canvasHistoryListSuccess(response.message, response.data)
    );
    // yield put(actions.addCanvasToList(action.list, response.data));
  } catch (e) {
    yield put(actions.canvasHistoryListFailure(e.message));
  }
}

// Canvas history list - load more
export function* canvasHistoryListLoadMoreSaga(action) {
  yield put(actions.canvasHistoryListLoadMoreStart());
  let data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    page: action.page
  };
  try {
    let response = yield axios.post(`/canvas/history`, data);
    yield put(
      actions.canvasHistoryListLoadMoreSuccess(
        response.message,
        action.list,
        response.data
      )
    );
    // yield put(actions.addCanvasToList(action.list, response.data));
  } catch (e) {
    yield put(actions.canvasHistoryListLoadMoreFailure(e.message));
  }
}

// Canvas history details
export function* canvasHistoryDetailsSaga(action) {
  yield put(actions.canvasHistoryDetailsFetchStart());
  let data = {
    user_id: action.user_id,
    canvas_id: action.canvas_id,
    history_id: action.history_id
  };
  try {
    let response = yield axios.post(`/canvas/history/details`, data);
    yield put(
      canvasAction.setCanvasDetailsData(response.data._id, response.data)
    );
    yield put(canvasAction.setCoreData(response.data._id, response.data));
    yield put(canvasAction.setCustomerData(response.data._id, response.data));
    yield put(
      canvasAction.setCompetitionData(response.data._id, response.data)
    );
    yield put(
      canvasAction.setContentStoryData(response.data._id, response.data)
    );
    yield put(
      canvasAction.setContentCalendarData(response.data._id, response.data)
    );
    yield put(canvasAction.setCreativesData(response.data._id, response.data));
    yield put(
      canvasAction.setChannelTextOwnedData(response.data._id, response.data)
    );
    yield put(
      canvasAction.setChannelTextSharedData(response.data._id, response.data)
    );
    yield put(
      canvasAction.setChannelTextPaidData(response.data._id, response.data)
    );
    yield put(
      canvasAction.setChannelTextValueData(response.data._id, response.data)
    );
    yield put(canvasAction.setNoteData(response.data));
  } catch (e) {
    yield put(actions.canvasHistoryListFailure(e.message));
  }
}
