import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as actions from "../../../actions/forgotPassword/resetInitiate.action";
import Aux from "../../../hoc/Auxilary/Auxilary";
import SimpleAlert from "../../UI/SimpleAlert/SimpleAlert";

class ForgotPassword extends Component {

  render() {
    const forgotPasswordInitSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required")
    });

    let resetForm = (
      <Formik
        initialValues={{ email: "" }}
        validationSchema={forgotPasswordInitSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.props.onResetPasswordInit(values.email);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={"access__form"}>
            <div className={"access__group"}>
              <Field type="email" name="email" placeholder="Email Address" />
              <ErrorMessage
                name="email"
                className="validation__error"
                component="div"
              />
            </div>
            <div className={"access__group"}>
              <Link to="/login">click here to login</Link>
            </div>
            <div className={"access__group"}>
              <input type="submit" value="Reset My Password" />
            </div>
          </Form>
        )}
      </Formik>
    );

    let className;
    let show;
    if (this.props.loading === false && this.props.resetInitiated === true) {
      className = "custom__alert success";
      show = true;
    }
    if (
      this.props.loading === false &&
      this.props.resetInitiated === false &&
      this.props.error === true
    ) {
      className = "custom__alert";
      show = true;
    }

    return (
      <Aux>
        <div id="access">
          <main className={"wrapper"}>
            <div className={"access"}>
              <div className={"container"}>
                <div className={"access__logo"}>
                  <Link to="/">
                    <img src="../img/access/logo.png" alt="eBrandBuilders" />
                  </Link>
                </div>
                {show === true ? (
                  <SimpleAlert
                    show={show}
                    className={className}
                    message={this.props.message}
                  />
                ) : null}
                {resetForm}
              </div>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
    resetInitiated: state.forgotPassword.resetInitiated,
    message: state.forgotPassword.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetPasswordInit: email => dispatch(actions.initiateProcess(email)),
    clearMessage: () => dispatch(actions.initiateClearMessage()),
    clearResetPasswordInit: () => dispatch(actions.initiateReset())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
