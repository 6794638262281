import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.customer.action";
import * as canvasAction from "../actions/canvas.action";
import * as history from "../actions/history/canvas.history.action";
import axios from "../config/axios";

// Update customer audience
export function* updateCustomerAudienceSaga(action) {
  yield put(actions.updateCustomerAudienceStart());
  const data = {
    user_id: action.user_id,
    customer_audience: action.customer_audience
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/customer-audience`,
      data
    );
    yield put(
      actions.updateCustomerAudienceSuccess(response.status, response.message)
    );
    yield put(actions.setCustomerAudience(action.customer_audience));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCustomerAudienceFail(e.message));
  }
}

// Update customer elevate
export function* updateCustomerElevateSaga(action) {
  yield put(actions.updateCustomerElevateStart());
  const data = {
    user_id: action.user_id,
    customer_elevate: action.customer_elevate
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/customer-elevate`,
      data
    );
    yield put(
      actions.updateCustomerElevateSuccess(response.status, response.message)
    );
    yield put(actions.setCustomerElevate(action.customer_elevate));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCustomerElevateFail(e.message));
  }
}

// Update community
export function* updateCommunitySaga(action) {
  yield put(actions.updateCommunityStart());
  const data = {
    user_id: action.user_id,
    community: action.community
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/community`,
      data
    );
    yield put(
      actions.updateCommunitySuccess(response.status, response.message)
    );
    yield put(actions.setCommunity(action.community));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCommunityFailure(e.message));
  }
}
