import * as actionTypes from "../actions/actionTypes";
import {
  updateObject,
  updateColorList,
  removeColorFromList
} from "../shared/utils/utils";

// Creatives store
const creativesState = {
  canvas_id: null,
  history_id: null,
  naming: "",
  domain: "",
  typography: "",
  color: "",
  logo: "",
  packaging: ""
};

export const creatives = (state = creativesState, action) => {
  switch (action.type) {
    case actionTypes.SET_CREATIVES_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          naming: action.details.naming,
          domain: action.details.domain,
          typography: action.details.typography,
          color: action.details.color,
          logo: action.details.logo,
          packaging: action.details.packaging
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          naming: action.details.naming,
          domain: action.details.domain,
          typography: action.details.typography,
          color: action.details.color,
          logo: action.details.logo,
          packaging: action.details.packaging
        });
      }

    case actionTypes.CLEAR_CREATIVES_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        naming: "",
        domain: "",
        typography: "",
        color: "",
        logo: "",
        packaging: ""
      });

    case actionTypes.SET_CREATIVES:
      return updateObject(state, {
        naming: action.naming,
        domain: action.domain,
        typography: action.typography,
        logo: action.logo,
        packaging: action.packaging
      });

    case actionTypes.SET_LOGO:
      return updateObject(state, {
        logo: action.logo
      });

    case actionTypes.SET_PACKAGING:
      return updateObject(state, {
        packaging: action.packaging
      });

    case actionTypes.UPDATE_COLOR_LIST:
      const updatedList = updateColorList(action.list, action.color);
      return updateObject(state, {
        loading: false,
        color: updatedList
      });

    case actionTypes.REMOVE_COLOR_LIST:
      const removedList = removeColorFromList(action.list, action.color);
      return updateObject(state, {
        loading: false,
        color: removedList
      });

    default: {
      return state;
    }
  }
};

// Update creatives
const updateCreativesState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCreatives = (state = updateCreativesState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CREATIVES_INIT:
      return updateObject(state, {
        status: "started",
        error: false,
        loading: true
      });

    case actionTypes.UPDATE_CREATIVES_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CREATIVES_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CREATIVES_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        error: false,
        loading: true
      });

    case actionTypes.UPDATE_CREATIVES_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CREATIVES_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Add color
const addColorState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const addColor = (state = addColorState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COLOR_INIT:
      return updateObject(state, {
        status: "started",
        error: false,
        loading: true
      });

    case actionTypes.ADD_COLOR_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.ADD_COLOR_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    // History
    case actionTypes.ADD_COLOR_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        error: false,
        loading: true
      });

    case actionTypes.ADD_COLOR_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.ADD_COLOR_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Delete color
const deleteColorState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const deleteColor = (state = deleteColorState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_COLOR_INIT:
      return updateObject(state, {
        status: "started",
        error: false,
        loading: true
      });

    case actionTypes.DELETE_COLOR_SUCCESS:
      return updateObject(state, {
        status: "deleted",
        loading: false,
        message: action.message
      });

    case actionTypes.DELETE_COLOR_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    // History
    case actionTypes.DELETE_COLOR_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        error: false,
        loading: true
      });

    case actionTypes.DELETE_COLOR_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "deleted",
        loading: false,
        message: action.message
      });

    case actionTypes.DELETE_COLOR_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    default: {
      return state;
    }
  }
};
