import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as contentActions from "../../../actions/canvas.content.action";
import * as historyAction from "../../../actions/history/content.history.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import TextArea from "../../UI/TextArea/TextArea";

class ContentStory extends Component {
  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    content_brand: "",
    content_story: "",
    showSuccess: false,
    identifier: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.contentStory !== this.props.contentStory) {
      this.setState({
        canvas_id: nextProps.contentStory.canvas_id,
        history_id: nextProps.contentStory.history_id,
        content_brand: nextProps.contentStory.content_brand,
        content_story: nextProps.contentStory.content_story
      });
    }

    if (
      nextProps.UpdateContentStory.status === "completed" &&
      nextProps.UpdateContentStory.status !==
        this.props.UpdateContentStory.status
    ) {
      this.setState({ showSuccess: true, identifier: this.state.field });
    }
  }

  // Save function
  save = (field, value) => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      this.setState({ [field]: value, showSuccess: false, field: field });
      if (
        this.props.contentStory.canvas_id !== null &&
        this.props.contentStory.canvas_id !== ""
      ) {
        if (field === "content_story" || field === "content_brand") {
          setTimeout(
            function() {
              if (this.state.history_id !== null) {
                this.props.updateContentStoryHistory(
                  this.state.user_id,
                  this.state.canvas_id,
                  this.state.history_id,
                  this.state.content_brand,
                  this.state.content_story
                );
              } else {
                this.props.updateContentStory(
                  this.state.user_id,
                  this.state.canvas_id,
                  this.state.content_brand,
                  this.state.content_story
                );
              }
            }.bind(this),
            500
          );
        }
      }
    }
  };

  render() {
    return (
      <Aux>
        <div className={"content-first show"}>
          <div className={"content__group-wrapper group-short"}>
            <TextArea
              nextClass={""}
              id={"content_brand"}
              commentSection={"content_brand"}
              identifier={this.state.field}
              canvas_id={this.state.canvas_id}
              field={"content_brand"}
              title={"STORY OF THE BRAND"}
              value={this.state.content_brand}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={1200}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>

          <div className={"content__group-wrapper group-short"}>
            <TextArea
              nextClass={""}
              id={"content_story"}
              commentSection={"content_story"}
              canvas_id={this.state.canvas_id}
              identifier={this.state.field}
              field={"content_story"}
              title={"BRAND STORIES"}
              value={this.state.content_story}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={1200}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateContentStory: (user_id, canvas_id, content_brand, content_story) => {
      dispatch(
        contentActions.updateContentStory(
          user_id,
          canvas_id,
          content_brand,
          content_story
        )
      );
    },
    updateContentStoryHistory: (
      user_id,
      canvas_id,
      history_id,
      content_brand,
      content_story
    ) => {
      dispatch(
        historyAction.updateContentStoryHistory(
          user_id,
          canvas_id,
          history_id,
          content_brand,
          content_story
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    contentStory: state.contentStory,
    UpdateContentStory: state.updateContentStory,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentStory);
