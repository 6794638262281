// Local version
// const config = {
//   apiUrl: "http://localhost:4000/api/v1/users/",
//   publicUrl: "http://localhost:4000/api/v1/public/",
//   viewUrl: "http://localhost:4000/canvas/view/",
//   commentUrl: "http://localhost:4000/canvas/collaborate/"
// };

// Live version
const config = {
  apiUrl: "https://api.onepager.in/api/v1/users/",
  publicUrl: "https://api.onepager.in/api/v1/public/",
  viewUrl: "https://app.onepager.in/canvas/view/",
  commentUrl: "https://app.onepager.in/canvas/collaborate/"
};

export default config;