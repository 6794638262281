// Signup
export const SIGNUP_INIT = "SIGNUP_INIT";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const CLEAR_SIGNUP_MESSAGE = "CLEAR_SIGNUP_MESSAGE";
export const RESET_SIGNUP = "RESET_SIGNUP";

// Check whether email is present in the subscriber list
export const CHECK_USER_IN_LIST_INIT = "CHECK_USER_IN_LIST_INIT";
export const CHECK_USER_IN_LIST = "CHECK_USER_IN_LIST";
export const CHECK_USER_IN_LIST_SUCCESS = "CHECK_USER_IN_LIST_SUCCESS";
export const CHECK_USER_IN_LIST_FAILURE = "CHECK_USER_IN_LIST_FAILURE";

// Login
export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_RESET = "LOGIN_RESET";

// Logout
export const LOGOUT = "LOGOUT";

// Login check
export const LOGIN_CHECK_RESET = "LOGIN_CHECK_RESET";
export const LOGIN_CHECK_INIT = "LOGIN_CHECK_INIT";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const LOGIN_CHECK_SUCCESS = "LOGIN_CHECK_SUCCESS";
export const LOGIN_CHECK_FAIL = "LOGIN_CHECK_FAIL";

// Forgot/reset password
export const INITIATE_START = "INITIATE_START";
export const INITIATE_PROCESS = "INITIATE_PROCESS";
export const INITIATE_SUCCESS = "INITIATE_SUCCESS";
export const INITIATE_FAIL = "INITIATE_FAIL";
export const INITIATE_CLEAR_MESSAGE = "INITIATE_CLEAR_MESSAGE";
export const INITIATE_RESET = "INITIATE_RESET";

export const VERIFICATION_INIT = "VERIFICATION_INIT";
export const VERIFICATION_PROCESS = "VERIFICATION_PROCESS";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_FAIL = "VERIFICATION_FAIL";
export const VERIFICATION_CLEAR = "VERIFICATION_CLEAR";
export const VERIFICATION_RESET = "VERIFICATION_RESET";

export const RESET_INIT = "RESET_INIT";
export const RESET_PROCESS = "RESET_PROCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";
export const RESET_CLEAR = "RESET_CLEAR";

// Account details
export const ACCOUNT_DETAILS_FETCH_INIT = "ACCOUNT_DETAILS_FETCH_INIT";
export const ACCOUNT_DETAILS_FETCH = "ACCOUNT_DETAILS_FETCH";
export const ACCOUNT_DETAILS_FETCH_SUCCESS = "ACCOUNT_DETAILS_FETCH_SUCCESS";
export const ACCOUNT_DETAILS_FETCH_FAILURE = "ACCOUNT_DETAILS_FETCH_FAILURE";

// Account update
export const ACCOUNT_UPDATE_INIT = "ACCOUNT_UPDATE_INIT";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAILURE = "ACCOUNT_UPDATE_FAILURE";
export const ACCOUNT_UPDATE_RESET = "ACCOUNT_UPDATE_RESET";

// Create canvas
export const CREATE_CANVAS_INIT = "CREATE_CANVAS_INIT";
export const CREATE_CANVAS = "CREATE_CANVAS";
export const CREATE_CANVAS_SUCCESS = "CREATE_CANVAS_SUCCESS";
export const CREATE_CANVAS_FAIL = "CREATE_CANVAS_FAIL";
export const CLEAR_CREATE_CANVAS_MESSAGE = "CLEAR_CREATE_CANVAS_MESSAGE";
export const RESET_CREATE_CANVAS = "RESET_CREATE_CANVAS";

// Delete canvas
export const CANVAS_DELETE_INIT = "CANVAS_DELETE_INIT";
export const CANVAS_DELETE = "CANVAS_DELETE";
export const CANVAS_DELETE_SUCCESS = "CANVAS_DELETE_SUCCESS";
export const CANVAS_DELETE_FAILURE = "CANVAS_DELETE_FAILURE";
export const CANVAS_DELETE_LIST_ITEM = "CANVAS_DELETE_LIST_ITEM";
export const CANVAS_DELETE_RESET = "CANVAS_DELETE_RESET";

// Fetch canvas details
export const CANVAS_DETAILS_INIT = "CANVAS_DETAILS_INIT";
export const CANVAS_DETAILS_FETCH = "CANVAS_DETAILS_FETCH";
export const CANVAS_DETAILS_FETCH_FAILURE = "CANVAS_DETAILS_FETCH_FAILURE";

// Set canvas details data
export const SET_CANVAS_DETAILS_DATA = "SET_CANVAS_DETAILS_DATA";
export const CLEAR_CANVAS_DETAILS_DATA = "CLEAR_CANVAS_DETAILS_DATA";

// Set canvas name
export const SET_CANVAS_NAME = "SET_CANVAS_NAME";
export const CLEAR_CANVAS_NAME = "CLEAR_CANVAS_NAME";

// Set canvas name in canvas list
export const SET_CANVAS_LIST_NAME = "SET_CANVAS_LIST_NAME";

// Set core data
export const SET_CORE_DATA = "SET_CORE_DATA";
export const CLEAR_CORE_DATA = "CLEAR_CORE_DATA";

// Set customer data
export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
export const CLEAR_CUSTOMER_DATA = "CLEAR_CUSTOMER_DATA";

// Set community data
export const SET_COMMUNITY_DATA = "SET_COMMUNITY_DATA";
export const CLEAR_COMMUNITY_DATA = "CLEAR_COMMUNITY_DATA";

// Set competition data
export const SET_COMPETITION_DATA = "SET_COMPETITION_DATA";
export const CLEAR_COMPETITION_DATA = "CLEAR_COMPETITION_DATA";

// Set content story data
export const SET_CONTENT_STORY_DATA = "SET_CONTENT_STORY_DATA";
export const CLEAR_CONTENT_STORY_DATA = "CLEAR_CONTENT_STORY_DATA";

// Set content calendar data
export const SET_CONTENT_CALENDAR_DATA = "SET_CONTENT_CALENDAR_DATA";
export const CLEAR_CONTENT_CALENDAR_DATA = "CLEAR_CONTENT_CALENDAR_DATA";

// Set creatives data
export const SET_CREATIVES_DATA = "SET_CREATIVES_DATA";
export const CLEAR_CREATIVES_DATA = "CLEAR_CREATIVES_DATA";

// Set channel textowned  data
export const SET_CHANNEL_TEXT_OWNED_DATA = "SET_CHANNEL_TEXT_OWNED_DATA";
export const CLEAR_CHANNEL_TEXT_OWNED_DATA = "CLEAR_CHANNEL_TEXT_OWNED_DATA";

// Set channel text shared data
export const SET_CHANNEL_TEXT_SHARED_DATA = "SET_CHANNEL_TEXT_SHARED_DATA";
export const CLEAR_CHANNEL_TEXT_SHARED_DATA = "CLEAR_CHANNEL_TEXT_SHARED_DATA";

// Set channel text paid data
export const SET_CHANNEL_TEXT_PAID_DATA = "SET_CHANNEL_TEXT_PAID_DATA";
export const CLEAR_CHANNEL_TEXT_PAID_DATA = "CLEAR_CHANNEL_TEXT_PAID_DATA";

// Set channel text value data
export const SET_CHANNEL_TEXT_VALUE_DATA = "SET_CHANNEL_TEXT_VALUE_DATA";
export const CLEAR_CHANNEL_TEXT_VALUE_DATA = "CLEAR_CHANNEL_TEXT_VALUE_DATA";

// Set notes data
export const SET_NOTE_DATA = "SET_NOTE_DATA";
export const CLEAR_NOTE_DATA = "CLEAR_NOTE_DATA";

// Update canvas name
export const CANVAS_NAME_UPDATE_INIT = "CANVAS_NAME_UPDATE_INIT";
export const CANVAS_NAME_UPDATE = "CANVAS_NAME_UPDATE";
export const CANVAS_NAME_UPDATE_SUCCESS = "CANVAS_NAME_UPDATE_SUCCESS";
export const CANVAS_NAME_UPDATE_FAILURE = "CANVAS_NAME_UPDATE_FAILURE";

// Canvas list
export const CANVAS_LIST_FETCH_INIT = "CANVAS_LIST_FETCH_INIT";
export const CANVAS_LIST_FETCH = "CANVAS_LIST_FETCH";
export const CANVAS_LIST_FETCH_SUCCESS = "CANVAS_LIST_FETCH_SUCCESS";
export const CANVAS_LIST_FETCH_FAILURE = "CANVAS_LIST_FETCH_FAILURE";

// Add canvas to the list
export const ADD_CANVAS_NAME_TO_LIST = "ADD_CANVAS_NAME_TO_LIST";

// Update core purpose
export const UPDATE_CORE_PURPOSE_INIT = "UPDATE_CORE_PURPOSE_INIT";
export const UPDATE_CORE_PURPOSE = "UPDATE_CORE_PURPOSE";
export const UPDATE_CORE_PURPOSE_SUCCESS = "UPDATE_CORE_PURPOSE_SUCCESS";
export const UPDATE_CORE_PURPOSE_FAILURE = "UPDATE_CORE_PURPOSE_FAILURE";

// Set core purpose
export const SET_CORE_PURPOSE = "SET_CORE_PURPOSE";

// Update core values
export const UPDATE_CORE_VALUES_INIT = "UPDATE_CORE_VALUES_INIT";
export const UPDATE_CORE_VALUES = "UPDATE_CORE_VALUES";
export const UPDATE_CORE_VALUES_SUCCESS = "UPDATE_CORE_VALUES_SUCCESS";
export const UPDATE_CORE_VALUES_FAILURE = "UPDATE_CORE_VALUES_FAILURE";

// Set core values
export const SET_CORE_VALUES = "SET_CORE_VALUES";

// Update core essence
export const UPDATE_CORE_ESSENCE_INIT = "UPDATE_CORE_ESSENCE_INIT";
export const UPDATE_CORE_ESSENCE = "UPDATE_CORE_ESSENCE";
export const UPDATE_CORE_ESSENCE_SUCCESS = "UPDATE_CORE_ESSENCE_SUCCESS";
export const UPDATE_CORE_ESSENCE_FAILURE = "UPDATE_CORE_ESSENCE_FAILURE";

// Set core essence
export const SET_CORE_ESSENCE = "SET_CORE_ESSENCE";

// Update customer audience
export const UPDATE_CUSTOMER_AUDIENCE_INIT = "UPDATE_CUSTOMER_AUDIENCE_INIT";
export const UPDATE_CUSTOMER_AUDIENCE = "UPDATE_CUSTOMER_AUDIENCE";
export const UPDATE_CUSTOMER_AUDIENCE_SUCCESS =  "UPDATE_CUSTOMER_AUDIENCE_SUCCESS";
export const UPDATE_CUSTOMER_AUDIENCE_FAILURE =  "UPDATE_CUSTOMER_AUDIENCE_FAILURE";

// Set customer audience
export const SET_CUSTOMER_AUDIENCE = "SET_CUSTOMER_AUDIENCE";

// Update customer elevate
export const UPDATE_CUSTOMER_ELEVATE_INIT = "UPDATE_CUSTOMER_ELEVATE_INIT";
export const UPDATE_CUSTOMER_ELEVATE = "UPDATE_CUSTOMER_ELEVATE";
export const UPDATE_CUSTOMER_ELEVATE_SUCCESS =  "UPDATE_CUSTOMER_ELEVATE_SUCCESS";
export const UPDATE_CUSTOMER_ELEVATE_FAILURE =  "UPDATE_CUSTOMER_ELEVATE_FAILURE";

// Set customer audience
export const SET_CUSTOMER_ELEVATE = "SET_CUSTOMER_ELEVATE";

// Update community
export const UPDATE_COMMUNITY_INIT = "UPDATE_COMMUNITY_INIT";
export const UPDATE_COMMUNITY = "UPDATE_COMMUNITY";
export const UPDATE_COMMUNITY_SUCCESS = "UPDATE_COMMUNITY_SUCCESS";
export const UPDATE_COMMUNITY_FAILURE = "UPDATE_COMMUNITY_FAILURE";

// Set community
export const SET_COMMUNITY = "SET_COMMUNITY";

// Update competition
export const UPDATE_COMPETITION_INIT = "UPDATE_COMPETITION_INIT";
export const UPDATE_COMPETITION = "UPDATE_COMPETITION";
export const UPDATE_COMPETITION_SUCCESS = "UPDATE_COMPETITION_SUCCESS";
export const UPDATE_COMPETITION_FAILURE = "UPDATE_COMPETITION_FAILURE";

// Set competition
export const SET_COMPETITION = "SET_COMPETITION";

// Update content story
export const UPDATE_CONTENT_STORY_INIT = "UPDATE_CONTENT_STORY_INIT";
export const UPDATE_CONTENT_STORY = "UPDATE_CONTENT_STORY";
export const UPDATE_CONTENT_STORY_SUCCESS = "UPDATE_CONTENT_STORY_SUCCESS";
export const UPDATE_CONTENT_STORY_FAILURE = "UPDATE_CONTENT_STORY_FAILURE";

// Set content story
export const SET_CONTENT_STORY = "SET_CONTENT_STORY";

// Update content calendar
export const UPDATE_CONTENT_CALENDAR_INIT = "UPDATE_CONTENT_CALENDAR_INIT";
export const UPDATE_CONTENT_CALENDAR = "UPDATE_CONTENT_CALENDAR";
export const UPDATE_CONTENT_CALENDAR_SUCCESS =  "UPDATE_CONTENT_CALENDAR_SUCCESS";
export const UPDATE_CONTENT_CALENDAR_FAILURE =  "UPDATE_CONTENT_CALENDAR_FAILURE";

// Set content calender
export const SET_CONTENT_CALENDAR = "SET_CONTENT_CALENDAR";

// Update creatives
export const UPDATE_CREATIVES_INIT = "UPDATE_CREATIVES_INIT";
export const UPDATE_CREATIVES = "UPDATE_CREATIVES";
export const UPDATE_CREATIVES_SUCCESS = "UPDATE_CREATIVES_SUCCESS";
export const UPDATE_CREATIVES_FAILURE = "UPDATE_CREATIVES_FAILURE";

// Set creatives
export const SET_CREATIVES = "SET_CREATIVES";

// Upload logo
export const UPLOAD_LOGO_INIT = "UPLOAD_LOGO_INIT";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_FAILURE = "UPLOAD_LOGO_FAILURE";

// Delete logo/package
export const DELETE_CREATIVE_FILE_INIT = "DELETE_CREATIVE_FILE_INIT";
export const DELETE_CREATIVE_FILE = "DELETE_CREATIVE_FILE";
export const DELETE_CREATIVE_FILE_SUCCESS = "DELETE_CREATIVE_FILE_SUCCESS";
export const DELETE_CREATIVE_FILE_FAILURE = "DELETE_CREATIVE_FILE_FAILURE";

// Set creatives logo
export const SET_LOGO = "SET_LOGO";

// Upload package
export const UPLOAD_PACKAGE_INIT = "UPLOAD_PACKAGE_INIT";
export const UPLOAD_PACKAGE = "UPLOAD_PACKAGE";
export const UPLOAD_PACKAGE_SUCCESS = "UPLOAD_PACKAGE_SUCCESS";
export const UPLOAD_PACKAGE_FAILURE = "UPLOAD_PACKAGE_FAILURE";

// Set creatives packaging
export const SET_PACKAGING = "SET_PACKAGING";

// Invalid file format
export const INVALID_FILE_FORMAT = "INVALID_FILE_FORMAT";

// Add color
export const ADD_COLOR_INIT = "ADD_COLOR_INIT";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_COLOR_SUCCESS = "ADD_COLOR_SUCCESS";
export const ADD_COLOR_FAILURE = "ADD_COLOR_FAILURE";

// Delete color
export const DELETE_COLOR_INIT = "DELETE_COLOR_INIT";
export const DELETE_COLOR = "DELETE_COLOR";
export const DELETE_COLOR_SUCCESS = "DELETE_COLOR_SUCCESS";
export const DELETE_COLOR_FAILURE = "DELETE_COLOR_FAILURE";

// Add color to list
export const UPDATE_COLOR_LIST = "UPDATE_COLOR_LIST";

// Remove color from list
export const REMOVE_COLOR_LIST = "REMOVE_COLOR_LIST";

// Update channel text owned
export const UPDATE_CHANNEL_TEXT_OWNED_INIT = "UPDATE_CHANNEL_TEXT_OWNED_INIT";
export const UPDATE_CHANNEL_TEXT_OWNED = "UPDATE_CHANNEL_TEXT_OWNED";
export const UPDATE_CHANNEL_TEXT_OWNED_SUCCESS =  "UPDATE_CHANNEL_TEXT_OWNED_SUCCESS";
export const UPDATE_CHANNEL_TEXT_OWNED_FAILURE =  "UPDATE_CHANNEL_TEXT_OWNED_FAILURE";

// Set channel text owned
export const SET_CHANNEL_TEXT_OWNED = "SET_CHANNEL_TEXT_OWNED";

// Update channel text shared
export const UPDATE_CHANNEL_TEXT_SHARED_INIT =  "UPDATE_CHANNEL_TEXT_SHARED_INIT";
export const UPDATE_CHANNEL_TEXT_SHARED = "UPDATE_CHANNEL_TEXT_SHARED";
export const UPDATE_CHANNEL_TEXT_SHARED_SUCCESS =  "UPDATE_CHANNEL_TEXT_SHARED_SUCCESS";
export const UPDATE_CHANNEL_TEXT_SHARED_FAILURE =  "UPDATE_CHANNEL_TEXT_SHARED_FAILURE";

// Set channel text owned
export const SET_CHANNEL_TEXT_SHARED = "SET_CHANNEL_TEXT_SHARED";

// Update channel text paid
export const UPDATE_CHANNEL_TEXT_PAID_INIT = "UPDATE_CHANNEL_TEXT_PAID_INIT";
export const UPDATE_CHANNEL_TEXT_PAID = "UPDATE_CHANNEL_TEXT_PAID";
export const UPDATE_CHANNEL_TEXT_PAID_SUCCESS =  "UPDATE_CHANNEL_TEXT_PAID_SUCCESS";
export const UPDATE_CHANNEL_TEXT_PAID_FAILURE =  "UPDATE_CHANNEL_TEXT_PAID_FAILURE";

// Set channel text paid
export const SET_CHANNEL_TEXT_PAID = "SET_CHANNEL_TEXT_PAID";

// Update channel text value
export const UPDATE_CHANNEL_TEXT_VALUE_INIT = "UPDATE_CHANNEL_TEXT_VALUE_INIT";
export const UPDATE_CHANNEL_TEXT_VALUE = "UPDATE_CHANNEL_TEXT_VALUE";
export const UPDATE_CHANNEL_TEXT_VALUE_SUCCESS =  "UPDATE_CHANNEL_TEXT_VALUE_SUCCESS";
export const UPDATE_CHANNEL_TEXT_VALUE_FAILURE =  "UPDATE_CHANNEL_TEXT_VALUE_FAILURE";

// Set channel text value
export const SET_CHANNEL_TEXT_VALUE = "SET_CHANNEL_TEXT_VALUE";

// Add comment
export const ADD_COMMENT_INIT = "ADD_COMMENT_INIT";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

// Get comment
export const GET_COMMENT_INIT = "GET_COMMENT_INIT";
export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const GET_COMMENT_FAILURE = "GET_COMMENT_FAILURE";

// Get collaborate comment
export const GET_COLLABORATE_COMMENT_INIT = "GET_COLLABORATE_COMMENT_INIT";
export const GET_COLLABORATE_COMMENT = "GET_COLLABORATE_COMMENT";
export const GET_COLLABORATE_COMMENT_SUCCESS = "GET_COLLABORATE_COMMENT_SUCCESS";
export const GET_COLLABORATE_COMMENT_FAILURE = "GET_COLLABORATE_COMMENT_FAILURE";

// Filter comment
export const FILTER_COMMENT = "FILTER_COMMENT";

// Add new comment to filtered list
export const UPDATE_FILTER_COMMENT = "UPDATE_FILTER_COMMENT";

// Send canvas copy
export const SEND_CANVAS_INIT = "SEND_CANVAS_INIT";
export const SEND_CANVAS = "SEND_CANVAS";
export const SEND_CANVAS_SUCCESS = "SEND_CANVAS_SUCCESS";
export const SEND_CANVAS_FAILURE = "SEND_CANVAS_FAILURE";

// Add notes
export const UPDATE_NOTE_INIT = "UPDATE_NOTE_INIT";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILURE = "UPDATE_NOTE_FAILURE";

// Set note
export const SET_NOTE = "SET_NOTE";

// Set notification
export const SET_NOTIFICATION = "SET_NOTIFICATION";

// Clear notification
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

// Canvas view
export const CANVAS_VIEW_INIT = "CANVAS_VIEW_INIT";
export const CANVAS_VIEW = "CANVAS_VIEW";
export const CANVAS_VIEW_SUCCESS = "CANVAS_VIEW_SUCCESS";
export const CANVAS_VIEW_FAILURE = "CANVAS_VIEW_FAILURE";

// Set canvas view mode - private/preview/collaborate
export const SET_CANVAS_PRIVATE = "SET_CANVAS_PRIVATE";
export const SET_CANVAS_PREVIEW = "SET_CANVAS_PREVIEW";
export const SET_CANVAS_COLLABORATE = "SET_CANVAS_COLLABORATE";

// Canvas copy invitation
export const CANVAS_COPY_INVITATION_INIT = "CANVAS_COPY_INVITATION_INIT";
export const CANVAS_COPY_INVITATION = "CANVAS_COPY_INVITATION";
export const CANVAS_COPY_INVITATION_SUCCESS = "CANVAS_COPY_INVITATION_SUCCESS";
export const CANVAS_COPY_INVITATION_FAILURE = "CANVAS_COPY_INVITATION_FAILURE";

// Collaborate add comment
export const CANVAS_COLLABORATE_ADD_COMMENT_INIT = "CANVAS_COLLABORATE_ADD_COMMENT_INIT";
export const CANVAS_COLLABORATE_ADD_COMMENT = "CANVAS_COLLABORATE_ADD_COMMENT";
export const CANVAS_COLLABORATE_ADD_COMMENT_SUCCESS = "CANVAS_COLLABORATE_ADD_COMMENT_SUCCESS";
export const CANVAS_COLLABORATE_ADD_COMMENT_FAILURE = "CANVAS_COLLABORATE_ADD_COMMENT_FAILURE";



/*************************************************************************************************
 * Canvas history details, list, update
 *************************************************************************************************/
// Canvas history list
export const CANVAS_HISTORY_LIST_INIT = "CANVAS_HISTORY_LIST_INIT";
export const CANVAS_HISTORY_LIST = "CANVAS_HISTORY_LIST";
export const CANVAS_HISTORY_LIST_SUCCESS = "CANVAS_HISTORY_LIST_SUCCESS";
export const CANVAS_HISTORY_LIST_FAILURE = "CANVAS_HISTORY_LIST_FAILURE";

// Canvas history list load more
export const CANVAS_HISTORY_LIST_LOAD_MORE_INIT = "CANVAS_HISTORY_LIST_LOAD_MORE_INIT";
export const CANVAS_HISTORY_LIST_LOAD_MORE = "CANVAS_HISTORY_LIST_LOAD_MORE";
export const CANVAS_HISTORY_LIST_LOAD_MORE_SUCCESS = "CANVAS_HISTORY_LIST_LOAD_MORE_SUCCESS";
export const CANVAS_HISTORY_LIST_LOAD_MORE_FAILURE = "CANVAS_HISTORY_LIST_LOAD_MORE_FAILURE";

// Reset history scroller
export const RESET_HISTORY_LIST_SCROLLER = "RESET_HISTORY_LIST_SCROLLER";

// Canvas history details
export const CANVAS_HISTORY_DETAILS_INIT = "CANVAS_HISTORY_DETAILS_INIT";
export const CANVAS_HISTORY_DETAILS = "CANVAS_HISTORY_DETAILS";
export const CANVAS_HISTORY_DETAILS_SUCCESS = "CANVAS_HISTORY_DETAILS_SUCCESS";
export const CANVAS_HISTORY_DETAILS_FAILURE = "CANVAS_HISTORY_DETAILS_FAILURE";

// Update core purpose history
export const UPDATE_CORE_PURPOSE_HISTORY_INIT = "UPDATE_CORE_PURPOSE_HISTORY_INIT";
export const UPDATE_CORE_PURPOSE_HISTORY = "UPDATE_CORE_PURPOSE_HISTORY";
export const UPDATE_CORE_PURPOSE_HISTORY_SUCCESS = "UPDATE_CORE_PURPOSE_HISTORY_SUCCESS";
export const UPDATE_CORE_PURPOSE_HISTORY_FAILURE = "UPDATE_CORE_PURPOSE_HISTORY_FAILURE";

// Update core values history
export const UPDATE_CORE_VALUES_HISTORY_INIT = "UPDATE_CORE_VALUES_HISTORY_INIT";
export const UPDATE_CORE_VALUES_HISTORY = "UPDATE_CORE_VALUES_HISTORY";
export const UPDATE_CORE_VALUES_HISTORY_SUCCESS = "UPDATE_CORE_VALUES_HISTORY_SUCCESS";
export const UPDATE_CORE_VALUES_HISTORY_FAILURE = "UPDATE_CORE_VALUES_HISTORY_FAILURE";

// Update core essence history
export const UPDATE_CORE_ESSENCE_HISTORY_INIT = "UPDATE_CORE_ESSENCE_HISTORY_INIT";
export const UPDATE_CORE_ESSENCE_HISTORY = "UPDATE_CORE_ESSENCE_HISTORY";
export const UPDATE_CORE_ESSENCE_HISTORY_SUCCESS = "UPDATE_CORE_ESSENCE_HISTORY_SUCCESS";
export const UPDATE_CORE_ESSENCE_HISTORY_FAILURE = "UPDATE_CORE_ESSENCE_HISTORY_FAILURE";

// Update customer audience history
export const UPDATE_CUSTOMER_AUDIENCE_HISTORY_INIT = "UPDATE_CUSTOMER_AUDIENCE_HISTORY_INIT";
export const UPDATE_CUSTOMER_AUDIENCE_HISTORY = "UPDATE_CUSTOMER_AUDIENCE_HISTORY";
export const UPDATE_CUSTOMER_AUDIENCE_HISTORY_SUCCESS = "UPDATE_CUSTOMER_AUDIENCE_HISTORY_SUCCESS";
export const UPDATE_CUSTOMER_AUDIENCE_HISTORY_FAILURE = "UPDATE_CUSTOMER_AUDIENCE_HISTORY_FAILURE";

// Update customer elevate history
export const UPDATE_CUSTOMER_ELEVATE_HISTORY_INIT = "UPDATE_CUSTOMER_ELEVATE_HISTORY_INIT";
export const UPDATE_CUSTOMER_ELEVATE_HISTORY = "UPDATE_CUSTOMER_ELEVATE_HISTORY";
export const UPDATE_CUSTOMER_ELEVATE_HISTORY_SUCCESS =  "UPDATE_CUSTOMER_ELEVATE_HISTORY_SUCCESS";
export const UPDATE_CUSTOMER_ELEVATE_HISTORY_FAILURE =  "UPDATE_CUSTOMER_ELEVATE_HISTORY_FAILURE";

// Update community history
export const UPDATE_COMMUNITY_HISTORY_INIT = "UPDATE_COMMUNITY_HISTORY_INIT";
export const UPDATE_COMMUNITY_HISTORY = "UPDATE_COMMUNITY_HISTORY";
export const UPDATE_COMMUNITY_HISTORY_SUCCESS = "UPDATE_COMMUNITY_HISTORY_SUCCESS";
export const UPDATE_COMMUNITY_HISTORY_FAILURE = "UPDATE_COMMUNITY_HISTORY_FAILURE";

// Update competition history
export const UPDATE_COMPETITION_HISTORY_INIT = "UPDATE_COMPETITION_HISTORY_INIT";
export const UPDATE_COMPETITION_HISTORY = "UPDATE_COMPETITION_HISTORY";
export const UPDATE_COMPETITION_HISTORY_SUCCESS = "UPDATE_COMPETITION_HISTORY_SUCCESS";
export const UPDATE_COMPETITION_HISTORY_FAILURE = "UPDATE_COMPETITION_HISTORY_FAILURE";

// Update creatives history
export const UPDATE_CREATIVES_HISTORY_INIT = "UPDATE_CREATIVES_HISTORY_INIT";
export const UPDATE_CREATIVES_HISTORY = "UPDATE_CREATIVES_HISTORY";
export const UPDATE_CREATIVES_HISTORY_SUCCESS = "UPDATE_CREATIVES_HISTORY_SUCCESS";
export const UPDATE_CREATIVES_HISTORY_FAILURE = "UPDATE_CREATIVES_HISTORY_FAILURE";

// Upload history logo
export const UPLOAD_LOGO_HISTORY_INIT = "UPLOAD_LOGO_HISTORY_INIT";
export const UPLOAD_LOGO_HISTORY = "UPLOAD_LOGO_HISTORY";
export const UPLOAD_LOGO_HISTORY_SUCCESS = "UPLOAD_LOGO_HISTORY_SUCCESS";
export const UPLOAD_LOGO_HISTORY_FAILURE = "UPLOAD_LOGO_HISTORY_FAILURE";

// Upload history package
export const UPLOAD_PACKAGE_HISTORY_INIT = "UPLOAD_PACKAGE_HISTORY_INIT";
export const UPLOAD_PACKAGE_HISTORY = "UPLOAD_PACKAGE_HISTORY";
export const UPLOAD_PACKAGE_HISTORY_SUCCESS = "UPLOAD_PACKAGE_HISTORY_SUCCESS";
export const UPLOAD_PACKAGE_HISTORY_FAILURE = "UPLOAD_PACKAGE_HISTORY_FAILURE";

// Delete history creative file
export const DELETE_HISTORY_CREATIVE_FILE_INIT = "DELETE_HISTORY_CREATIVE_FILE_INIT";
export const DELETE_HISTORY_CREATIVE_FILE = "DELETE_HISTORY_CREATIVE_FILE";
export const DELETE_HISTORY_CREATIVE_FILE_SUCCESS = "DELETE_HISTORY_CREATIVE_FILE_SUCCESS";
export const DELETE_HISTORY_CREATIVE_FILE_FAILURE = "DELETE_HISTORY_CREATIVE_FILE_FAILURE";

// Add history color
export const ADD_COLOR_HISTORY_INIT = "ADD_COLOR_HISTORY_INIT";
export const ADD_COLOR_HISTORY = "ADD_COLOR_HISTORY";
export const ADD_COLOR_HISTORY_SUCCESS = "ADD_COLOR_HISTORY_SUCCESS";
export const ADD_COLOR_HISTORY_FAILURE = "ADD_COLOR_HISTORY_FAILURE";

// Delete history color
export const DELETE_COLOR_HISTORY_INIT = "DELETE_COLOR_HISTORY_INIT";
export const DELETE_COLOR_HISTORY = "DELETE_COLOR_HISTORY";
export const DELETE_COLOR_HISTORY_SUCCESS = "DELETE_COLOR_HISTORY_SUCCESS";
export const DELETE_COLOR_HISTORY_FAILURE = "DELETE_COLOR_HISTORY_FAILURE";

// Update content story history
export const UPDATE_CONTENT_STORY_HISTORY_INIT = "UPDATE_CONTENT_STORY_HISTORY_INIT";
export const UPDATE_CONTENT_STORY_HISTORY = "UPDATE_CONTENT_STORY_HISTORY";
export const UPDATE_CONTENT_STORY_HISTORY_SUCCESS = "UPDATE_CONTENT_STORY_HISTORY_SUCCESS";
export const UPDATE_CONTENT_STORY_HISTORY_FAILURE = "UPDATE_CONTENT_STORY_HISTORY_FAILURE";

// Update content calendar history
export const UPDATE_CONTENT_CALENDAR_HISTORY_INIT = "UPDATE_CONTENT_CALENDAR_HISTORY_INIT";
export const UPDATE_CONTENT_CALENDAR_HISTORY = "UPDATE_CONTENT_CALENDAR_HISTORY";
export const UPDATE_CONTENT_CALENDAR_HISTORY_SUCCESS =  "UPDATE_CONTENT_CALENDAR_HISTORY_SUCCESS";
export const UPDATE_CONTENT_CALENDAR_HISTORY_FAILURE =  "UPDATE_CONTENT_CALENDAR_HISTORY_FAILURE";

// Update channel text owned history
export const UPDATE_CHANNEL_TEXT_OWNED_HISTORY_INIT = "UPDATE_CHANNEL_TEXT_OWNED_HISTORY_INIT";
export const UPDATE_CHANNEL_TEXT_OWNED_HISTORY = "UPDATE_CHANNEL_TEXT_OWNED_HISTORY";
export const UPDATE_CHANNEL_TEXT_OWNED_HISTORY_SUCCESS =  "UPDATE_CHANNEL_TEXT_OWNED_HISTORY_SUCCESS";
export const UPDATE_CHANNEL_TEXT_OWNED_HISTORY_FAILURE =  "UPDATE_CHANNEL_TEXT_OWNED_HISTORY_FAILURE";

// Update channel text shared history
export const UPDATE_CHANNEL_TEXT_SHARED_HISTORY_INIT =  "UPDATE_CHANNEL_TEXT_SHARED_HISTORY_INIT";
export const UPDATE_CHANNEL_TEXT_SHARED_HISTORY = "UPDATE_CHANNEL_TEXT_SHARED_HISTORY";
export const UPDATE_CHANNEL_TEXT_SHARED_HISTORY_SUCCESS =  "UPDATE_CHANNEL_TEXT_SHARED_HISTORY_SUCCESS";
export const UPDATE_CHANNEL_TEXT_SHARED_HISTORY_FAILURE =  "UPDATE_CHANNEL_TEXT_SHARED_HISTORY_FAILURE";

// Update channel text paid history
export const UPDATE_CHANNEL_TEXT_PAID_HISTORY_INIT = "UPDATE_CHANNEL_TEXT_PAID_HISTORY_INIT";
export const UPDATE_CHANNEL_TEXT_PAID_HISTORY = "UPDATE_CHANNEL_TEXT_PAID_HISTORY";
export const UPDATE_CHANNEL_TEXT_PAID_HISTORY_SUCCESS =  "UPDATE_CHANNEL_TEXT_PAID_HISTORY_SUCCESS";
export const UPDATE_CHANNEL_TEXT_PAID_HISTORY_FAILURE =  "UPDATE_CHANNEL_TEXT_PAID_HISTORY_FAILURE";

// Update channel text value history
export const UPDATE_CHANNEL_TEXT_VALUE_HISTORY_INIT = "UPDATE_CHANNEL_TEXT_VALUE_HISTORY_INIT";
export const UPDATE_CHANNEL_TEXT_VALUE_HISTORY = "UPDATE_CHANNEL_TEXT_VALUE_HISTORY";
export const UPDATE_CHANNEL_TEXT_VALUE_HISTORY_SUCCESS =  "UPDATE_CHANNEL_TEXT_VALUE_HISTORY_SUCCESS";
export const UPDATE_CHANNEL_TEXT_VALUE_HISTORY_FAILURE =  "UPDATE_CHANNEL_TEXT_VALUE_HISTORY_FAILURE";

// Set current version time
export const SET_CURRENT_VERSION_TIME =  "SET_CURRENT_VERSION_TIME";