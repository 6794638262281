import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as contentActions from "../../../actions/canvas.content.action";
import * as historyAction from "../../../actions/history/content.history.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import TextArea from "../../UI/TextArea/TextArea";

class ContentCalendar extends Component {
  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    content_core: "",
    content_customer: "",
    content_community: "",
    content_competition: "",
    content_creatives: "",
    showSuccess: false,
    identifier: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.contentCalendar !== this.props.contentCalendar) {
      this.setState({
        canvas_id: nextProps.contentCalendar.canvas_id,
        history_id: nextProps.contentCalendar.history_id,
        content_core: nextProps.contentCalendar.content_core,
        content_customer: nextProps.contentCalendar.content_customer,
        content_community: nextProps.contentCalendar.content_community,
        content_competition: nextProps.contentCalendar.content_competition,
        content_creatives: nextProps.contentCalendar.content_creatives
      });
    }

    if (
      nextProps.UpdateContentCalendar.status === "completed" &&
      nextProps.UpdateContentCalendar.status !==
        this.props.UpdateContentCalendar.status
    ) {
      this.setState({ showSuccess: true, identifier: this.state.field });
    }
  }

  // Save function
  save = (field, value) => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      this.setState({ [field]: value, showSuccess: false, field: field });

      if (
        this.props.contentCalendar.canvas_id !== null &&
        this.props.contentCalendar.canvas_id !== ""
      ) {
        if (
          field === "content_core" ||
          field === "content_customer" ||
          field === "content_community" ||
          field === "content_competition" ||
          field === "content_creatives"
        ) {
          setTimeout(
            function() {
              if (this.state.history_id !== null) {
                this.props.updateContentCalendarHistory(
                  this.state.user_id,
                  this.state.canvas_id,
                  this.state.history_id,
                  this.state.content_core,
                  this.state.content_customer,
                  this.state.content_community,
                  this.state.content_competition,
                  this.state.content_creatives
                );
              } else {
                this.props.updateContentCalendar(
                  this.state.user_id,
                  this.state.canvas_id,
                  this.state.content_core,
                  this.state.content_customer,
                  this.state.content_community,
                  this.state.content_competition,
                  this.state.content_creatives
                );
              }
            }.bind(this),
            500
          );
        }
      }
    }
  };

  render() {
    return (
      <Aux>
        <div className={"content-second auto-scroll"}>
          <div className={"content__subtitle"}>
            <h3>EDITORIAL CALENDAR</h3>
          </div>

          <div className={"content__group-wrapper group-vshort"}>
            <TextArea
              nextClass={""}
              id={"content_core"}
              commentSection={"content_core"}
              identifier={this.state.field}
              canvas_id={this.state.canvas_id}
              field={"content_core"}
              title={"CORE"}
              value={this.state.content_core}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={600}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>

          <div className={"content__group-wrapper group-vshort"}>
            <TextArea
              nextClass={""}
              id={"content_customer"}
              commentSection={"content_customer"}
              identifier={this.state.field}
              canvas_id={this.state.canvas_id}
              field={"content_customer"}
              title={"CUSTOMER"}
              value={this.state.content_customer}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={600}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>

          <div className={"content__group-wrapper group-vshort"}>
            <TextArea
              nextClass={""}
              id={"content_community"}
              canvas_id={this.state.canvas_id}
              commentSection={"content_community"}
              identifier={this.state.field}
              field={"content_community"}
              title={"COMMUNITY"}
              value={this.state.content_community}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={600}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>

          <div className={"content__group-wrapper group-vshort"}>
            <TextArea
              nextClass={""}
              id={"content_competition"}
              canvas_id={this.state.canvas_id}
              commentSection={"content_competition"}
              identifier={this.state.field}
              field={"content_competition"}
              title={"COMPETITION"}
              value={this.state.content_competition}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={600}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>

          <div className={"content__group-wrapper group-vshort"}>
            <TextArea
              nextClass={""}
              id={"content_creatives"}
              canvas_id={this.state.canvas_id}
              commentSection={"content_creatives"}
              identifier={this.state.field}
              field={"content_creatives"}
              title={"CREATIVES"}
              value={this.state.content_creatives}
              placeholder={
                "What do your customers want as it relates to your product or service?"
              }
              showComment={this.showComment}
              save={this.save}
              showSuccess={this.state.showSuccess}
              maxLimit={600}
              alertLimit={25}
              commentBoxPosition={"left"}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateContentCalendar: (
      user_id,
      canvas_id,
      content_core,
      content_customer,
      content_community,
      content_competition,
      content_creatives
    ) => {
      dispatch(
        contentActions.updateContentCalendar(
          user_id,
          canvas_id,
          content_core,
          content_customer,
          content_community,
          content_competition,
          content_creatives
        )
      );
    },
    updateContentCalendarHistory: (
      user_id,
      canvas_id,
      history_id,
      content_core,
      content_customer,
      content_community,
      content_competition,
      content_creatives
    ) => {
      dispatch(
        historyAction.updateContentCalendarHistory(
          user_id,
          canvas_id,
          history_id,
          content_core,
          content_customer,
          content_community,
          content_competition,
          content_creatives
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    contentCalendar: state.contentCalendar,
    UpdateContentCalendar: state.updateContentCalendar,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentCalendar);
