import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/competition.history.action";
import * as competitionAction from "../../actions/canvas.competition.action";
import axios from "../../config/axios";

// Update competition
export function* updateCompetitionHistorySaga(action) {
  yield put(actions.updateCompetitionHistoryStart());
  const data = {
    user_id: action.user_id,
    competition: action.competition,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/competition`,
      data
    );
    yield put(
      actions.updateCompetitionHistorySuccess(response.status, response.message)
    );
    yield put(competitionAction.setCompetition(action.competition));
  } catch (e) {
    yield put(actions.updateCompetitionHistoryFail(e.message));
  }
}
