import { put } from "redux-saga/effects";
import * as actions from "../actions/account.action";
import * as notification from "../actions/notification.action";
import axios from "../config/axios";

// Account details
export function* accountDetailsSaga(action) {
  yield put(actions.accountDetailsFetchInitiated());

  const id = action.user_id;

  try {
    let response = yield axios.get("/account/" + id);
    yield put(
      actions.accountDetailsFetchSuccess(response.message, response.data)
    );
  } catch (e) {
    yield put(actions.accountDetailsFetchFailure(e.message));
  }
}

// Account update
export function* updateAccountSaga(action) {
  yield put(actions.updateAccountInitiated());

  try {
    const accountData = {
      id: action.id,
      first_name: action.first_name,
      last_name: action.last_name,
      email: action.email
    };

    if (action.password !== "" && action.newPassword !== "") {
      accountData.password = action.password;
      accountData.newPassword = action.newPassword;
    }

    let response = yield axios.post("/account", accountData);
    yield put(actions.updateAccountSuccess(response.message));
    yield put(
      notification.setNotification(response.status, false, response.message)
    );
  } catch (e) {
    yield put(actions.updateAccountFailure(e.message));
    yield put(
      notification.setNotification(true, false, e.message)
    );
  }
}
