import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Comment from "../../user/Comment/Comment";
import * as canvasActions from "../../../actions/canvas.action";
import * as creativesActions from "../../../actions/canvas.creatives.action";
import * as notificationAction from "../../../actions/notification.action";
import * as historyAction from "../../../actions/history/creatives.history.action";
import Aux from "../../../hoc/Auxilary/Auxilary";
import SingleInput from "../../UI/SingleInput/SingleInput";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import FileUploader from "../../UI/FileUploader/FileUploader";
import { ChromePicker } from "react-color";

class Creatives extends Component {
  constructor(props) {
    super(props);
    this.subGroupWrapperRef = React.createRef();
    this.contentCalcs = React.createRef();
    this.contentGroupRef = React.createRef();
    this.contentNavRef = React.createRef();
    this.checkmarkRef = React.createRef();
    this.contentNavButtonRef = React.createRef();
    this.spinnerRef = React.createRef();
    this.commentBtnRef = React.createRef();
  }

  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    disabled: false,
    displayColorPicker: false,
    naming: "",
    domain: "",
    typography: "",
    logo: null,
    packaging: null,
    color: null,
    logoFileExist: false,
    packagingFileExist: false,

    maxLimit: this.props.maxLimit,
    alertLimit: this.props.alertLimit,
    alertLength: 0,
    showAlert: false,
    reverseAlert: false,
    focusOutCounter: 0,
    valueSaved: false,
    changeDetected: false,
    valueState: false,
    displayComment: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.creatives !== this.props.creatives) {
      this.setState({
        canvas_id: nextProps.creatives.canvas_id,
        history_id: nextProps.creatives.history_id,
        naming: nextProps.creatives.naming,
        domain: nextProps.creatives.domain,
        typography: nextProps.creatives.typography,
        logo: nextProps.creatives.logo,
        packaging: nextProps.creatives.packaging,
        color: nextProps.creatives.color
      });
    }

    if (nextProps.creatives.logo !== null && nextProps.creatives.logo !== "") {
      this.setState({ logoFileExist: true });
    } else {
      this.setState({ logoFileExist: false });
    }

    if (
      nextProps.creatives.packaging !== null &&
      nextProps.creatives.packaging !== ""
    ) {
      this.setState({ packagingFileExist: true });
    } else {
      this.setState({ packagingFileExist: false });
    }

    if (nextProps.UpdateCreatives.status === "completed") {
      this.showSuccess();
    }

    if (nextProps.DeleteColor.status === "deleted") {
      this.hideButtonBox();
    }
  }

  contentGroupFocus = () => {
    this.subGroupWrapperRef.current.classList.add("cut");
    this.contentNavRef.current.classList.add("show");
  };

  contentGroupBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        focusOutCounter: Number(this.state.focusOutCounter) + Number(1)
      });

      this.subGroupWrapperRef.current.classList.remove("cut");

      setTimeout(
        function () {
          if (
            this.state.focusOutCounter === 1 &&
            this.state.valueSaved !== true
          ) {
            if (
              (this.state.naming !== this.props.creatives.naming &&
                this.state.naming !== "") ||
              (this.state.domain !== this.props.creatives.domain &&
                this.state.domain !== "") ||
              (this.state.typography !== this.props.creatives.typography &&
                this.state.typography !== "")
            ) {
              this.save();
            } else {
              this.hideButtonBox();
            }
          } else if (
            this.state.changeDetected === false &&
            this.state.valueState === false
          ) {
            this.hideButtonBox();
          } else if (this.state.focusOutCounter > 1) {
            this.hideButtonBox();
          }
        }.bind(this),
        500
      );
    } else {
    }
  };

  // Function to show progress bubble
  showProgress = () => {
    this.spinnerRef.current.classList.remove("spinner_hidden");
  };

  showSuccess = () => {
    this.spinnerRef.current.classList.add("spinner_hidden");
    this.checkmarkRef.current.classList.remove("checkmark__hidden");

    setTimeout(
      function () {
        this.hideCheckmark();
        this.hideButtonBox();
        $("input").attr("disabled", false);
        $("textarea").attr("disabled", false);
      }.bind(this),
      2000
    );
  };

  hideCheckmark = () => {
    this.checkmarkRef.current.classList.add("checkmark__hidden");
  };

  // Hide save & cancel button
  hideButtons = () => {
    this.commentBtnRef.current.classList.add("hide_cs_btn");
    this.contentNavButtonRef.current.classList.add("hide_cs_btn");
  };

  hideButtonBox = () => {
    this.subGroupWrapperRef.current.classList.remove("cut");
    this.contentNavRef.current.classList.remove("show");
    setTimeout(() => {
      this.commentBtnRef.current.classList.remove("hide_cs_btn");
    }, 1000);
  };

  // Remove progress bubble & checkmark
  showSuccess = () => {
    this.spinnerRef.current.classList.add("spinner_hidden");
    this.checkmarkRef.current.classList.remove("checkmark__hidden");

    setTimeout(
      function () {
        this.hideCheckmark();
        this.hideButtonBox();
        $("input").attr("disabled", false);
        $("textarea").attr("disabled", false);
        this.setState({ changeDetected: false, valueState: false });
      }.bind(this),
      2000
    );
  };

  // Handle color picker click
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  // Close color picker
  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  // Add color
  colorPicker = (color, event) => {
    if (this.props.creatives.canvas_id !== null) {
      if (this.state.history_id !== null) {
        this.props.addHistoryColor(
          this.state.user_id,
          this.state.canvas_id,
          this.state.history_id,
          this.state.color,
          color.hex
        );
      } else {
        this.props.addColor(
          this.state.user_id,
          this.state.canvas_id,
          this.state.color,
          color.hex
        );
      }
    }
  };

  // Remove/delete color
  removeColor = name => {
    if (this.props.creatives.canvas_id !== null) {
      if (this.state.history_id !== null) {
        this.props.deleteHistoryColor(
          this.state.user_id,
          this.state.canvas_id,
          this.state.history_id,
          this.state.color,
          name
        );
      } else {
        this.props.deleteColor(
          this.state.user_id,
          this.state.canvas_id,
          this.state.color,
          name
        );
      }
    }
  };

  // Display colors
  renderColorList = () => {
    let disabled;

    if (
      this.props.canvasView.preview === true ||
      this.props.canvasView.collaborate === true
    ) {
      disabled = true;
    }

    let list = this.state.color;
    return list.map((color, index) => {
      const colorClass = {
        backgroundColor: color.name,
        cursor: "pointer"
      };
      return (
        <input
          type="button"
          id={index}
          key={index}
          disabled={disabled}
          style={colorClass}
          onClick={() => this.removeColor(color.name)}
        />
      );
    });
  };

  // Change
  changeHandler = (field, event) => {
    const value = event.target.value;

    this.setState({
      field: field,
      [field]: value,
      showSuccess: false,
      focusOutCounter: 0,
      valueSaved: false,
      changeDetected: true
    });

    if (value !== this.props.creatives[field]) {
      this.setState({ valueState: true });
      this.contentNavButtonRef.current.classList.remove("hide_cs_btn");
    } else {
      this.setState({ valueState: false });
      this.contentNavButtonRef.current.classList.add("hide_cs_btn");
    }
  };

  // Cancel
  cancel = () => {
    this.setState({
      naming: this.props.creatives.naming,
      domain: this.props.creatives.domain,
      typography: this.props.creatives.typography,
      showAlert: false,
      alertLength: 0,
      reverseAlert: !this.state.reverseAlert,
      valueSaved: true
    });

    this.subGroupWrapperRef.current.classList.remove("cut");
    this.hideButtons();
    this.hideButtonBox();
  };

  // Save function
  save = () => {
    this.setState({ valueSaved: true });
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      setTimeout(
        function () {
          if (
            this.props.creatives.canvas_id !== null &&
            this.props.creatives.canvas_id !== ""
          ) {
            this.hideButtons();
            this.showProgress();
            $("input").attr("disabled", true);
            $("textarea").attr("disabled", true);
            this.setState({
              showAlert: false,
              reverseAlert: !this.state.reverseAlert,
              alertLength: 0
            });
            this.hideButtons();
            this.showProgress();
            if (this.state.history_id !== null) {
              this.props.updateCreativesHistory(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                this.state.naming,
                this.state.domain,
                this.state.typography,
                this.state.logo,
                this.state.packaging
              );
            } else {
              this.props.updateCreatives(
                this.state.user_id,
                this.state.canvas_id,
                this.state.naming,
                this.state.domain,
                this.state.typography,
                this.state.logo,
                this.state.packaging
              );
            }
          }
        }.bind(this),
        1000
      );
    }
  };

  // Upload file
  uploadFile = (event, type) => {
    event.preventDefault();
    let file = event.target.files[0];
    let format = "jpeg|jpg|png|gif";
    if (file) {
      let fileType = file.type;
      const formats = format.split("|");
      let counter = 0;

      for (let i = 0; i < formats.length; i++) {
        if (fileType === "image/" + formats[i]) {
          counter += counter + 1;
        }
      }

      if (counter === 0) {
        this.props.invalidFileFormat();
      } else {
        // if details not null - ADD
        if (this.props.creatives.canvas_id !== null) {
          if (type === "logo") {
            if (this.state.history_id !== null) {
              this.props.uploadCanvasHistoryLogo(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                file
              );
            } else {
              this.props.uploadCanvasLogo(
                this.state.user_id,
                this.state.canvas_id,
                file
              );
            }
          } else {
            if (this.state.history_id !== null) {
              this.props.uploadHistoryPackaging(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                file
              );
            } else {
              this.props.uploadPackaging(
                this.state.user_id,
                this.state.canvas_id,
                file
              );
            }
          }
        }
      }
    }
  };

  // Show comment
  showComment = section => {
    if (this.props.creatives.canvas_id !== null) {
      this.setState({ commentSection: section });
      this.setState({ displayComment: true });
      this.props.filterComment(
        this.state.canvas_id,
        this.props.commentList.comment,
        section
      );
    }
  };

  hideComment = () => {
    this.setState({ displayComment: false });
    this.hideButtonBox();
  };

  removeFile = (type, path) => {
    if (this.state.history_id) {
      this.props.deleteHistoryCreativeFile(
        this.state.user_id,
        this.state.canvas_id,
        this.state.history_id,
        type,
        path
      );
    } else {
      this.props.deleteCreativeFile(
        this.state.user_id,
        this.state.canvas_id,
        type,
        path
      );
    }
  };

  render() {
    const popover = {
      position: "absolute",
      zIndex: "2",
      top: "0px",
      left: "5px"
    };

    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    };

    let colorClass;

    if (this.props.creatives.color) {
      if (this.props.creatives.color.length === 5) {
        colorClass = "colors-none";
      } else {
        colorClass = "";
      }
    }

    if (
      this.props.canvasView.preview === true ||
      this.props.canvasView.collaborate === true
    ) {
      colorClass = "colors-none";
    }

    return (
      <Aux>
        <div className={"content__element element-60"}>
          <div className={"content__title"}>
            <h2>
              <img src="/img/main/Creatives.svg" alt="Creatives" />
              Creatives
            </h2>
          </div>

          <div className={"content__group-wrapper group-long auto-scroll"}>
            <div
              className={"content__group"}
              onBlur={event => this.contentGroupBlur(event)}
              onFocus={this.contentGroupFocus}
              ref={this.contentGroupRef}
            >
              <div
                className={"content__subgroup-wrapper auto-scroll"}
                ref={this.subGroupWrapperRef}
              >
                <div className={"content__subgroup"}>
                  <div className={"content__subgroup-left"}>
                    <h3>Naming</h3>
                    <div className={"content__input-small"}>
                      <SingleInput
                        id={"naming"}
                        field={"naming"}
                        value={this.state.naming}
                        onUpdate={this.changeHandler}
                        clickHandler={this.clickHandler}
                        maxLimit={25}
                        alertLimit={5}
                        showAlert={this.state.showAlert}
                        reverseAlert={this.state.reverseAlert}
                      />
                    </div>

                    <div className={"content__input-small"}>
                      <SingleInput
                        id={"domain"}
                        field={"domain"}
                        value={this.state.domain}
                        onUpdate={this.changeHandler}
                        clickHandler={this.clickHandler}
                        maxLimit={25}
                        alertLimit={5}
                        showAlert={this.state.showAlert}
                        reverseAlert={this.state.reverseAlert}
                      />
                    </div>
                    <h5>Domain</h5>
                  </div>

                  <div className={"content__subgroup-right"}>
                    <FileUploader
                      title={"LOGO"}
                      type={"logo"}
                      fileExist={this.state.logoFileExist}
                      file={this.state.logo}
                      uploadFile={this.uploadFile}
                      removeFile={this.removeFile}
                    />
                  </div>
                </div>

                <div className={"content__subgroup"}>
                  <div className={"content__subgroup-left"}>
                    <h3>Colors</h3>
                    <div className={"content__subgroup-colors"}>
                      <button onClick={this.handleClick} className={colorClass}>
                        +
                      </button>
                      {this.state.displayColorPicker ? (
                        <div style={popover}>
                          <div style={cover} onClick={this.handleClose} />
                          <ChromePicker
                            disableAlpha={true}
                            onChangeComplete={this.colorPicker}
                          />
                        </div>
                      ) : null}

                      {this.state.color !== null
                        ? this.state.color.length > 0
                          ? this.renderColorList()
                          : null
                        : null}
                    </div>

                    <h3>Typography</h3>
                    <div className={"content__input-small"}>
                      <SingleInput
                        id={"typography"}
                        field={"typography"}
                        value={this.state.typography}
                        onUpdate={this.changeHandler}
                        clickHandler={this.clickHandler}
                        maxLimit={25}
                        alertLimit={5}
                        showAlert={this.state.showAlert}
                        reverseAlert={this.state.reverseAlert}
                      />
                    </div>
                  </div>

                  <div className={"content__subgroup-right"}>
                    <FileUploader
                      title={"PACKAGING"}
                      type={"packaging"}
                      fileExist={this.state.packagingFileExist}
                      file={this.state.packaging}
                      uploadFile={this.uploadFile}
                      removeFile={this.removeFile}
                    />
                  </div>
                </div>
              </div>

              <div className={"content__nav"} ref={this.contentNavRef}>
                <div
                  className={"content__nav-comment"}
                  ref={this.commentBtnRef}
                >
                  <button onClick={() => this.showComment("creatives")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1000 1000"
                    >
                      <path d="M873.3,145.5c32.8,0,60.2,26.4,61.2,59.1v456.5c0,15.2-6.1,29.8-17.2,40.9c-11.1,11.2-25.7,17.3-41.1,17.3 H769.5c-19.2,0-37.4,8.8-49.4,23.9L636.3,849L546,741.8c-12-14.2-29.6-22.4-48.2-22.4h-374c-15.2,0-29.8-6.1-40.9-17.2 c-11.2-11.1-17.3-25.7-17.3-41.1V203.8c0-15.2,6.1-29.8,17.2-40.9c11.1-11.2,25.7-17.3,41.1-17.3H873.3 M873.3,82.5H123.8 C56.6,82.5,2.5,138.1,2.5,203.8v457.3c0,67.2,55.5,121.3,121.3,121.3h374l100.8,119.8c10.2,10.2,23.7,15.3,37.4,15.3 c13.7,0,27.6-5.1,38.5-15.3l95-119.8h106.6c67.2,0,121.3-55.5,121.3-121.3V203.8C996,136.6,940.5,82.5,873.3,82.5L873.3,82.5z" />
                    </svg>
                  </button>
                </div>
                {/* Progress bubbles */}
                <div className={"spinner spinner_hidden"} ref={this.spinnerRef}>
                  <div className={"bounce1"} />
                  <div className={"bounce2"} />
                  <div className={"bounce3"} />
                </div>
                {/* Checkmark - Success */}
                <div
                  className={"checkmark checkmark__hidden"}
                  ref={this.checkmarkRef}
                >
                  <svg
                    className={"checkmark-svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className={"checkmark__circle"}
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className={"checkmark__check"}
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
                {/* Error */}
                <div className={"danger__hidden"}>
                  <svg
                    className={"danger"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className={"danger__circle"}
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className={"danger__check"}
                      fill="none"
                      d="M16 16 36 36 M36 16 16 36"
                    />
                  </svg>
                </div>

                <div
                  className={"content__nav-buttons hide_cs_btn"}
                  ref={this.contentNavButtonRef}
                >
                  <button type="button" onClick={() => this.cancel()}>
                    Cancel
                  </button>

                  <button type="button" onClick={() => this.save()}>
                    Save
                  </button>
                </div>
              </div>
            </div>

            <Comment
              canvas_id={this.state.canvas_id}
              section={"creatives"}
              position={"left"}
              hideComment={this.hideComment}
              displayComment={this.state.displayComment}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCreatives: (
      user_id,
      canvas_id,
      naming,
      domain,
      typography,
      logo,
      packaging
    ) => {
      dispatch(
        creativesActions.updateCreatives(
          user_id,
          canvas_id,
          naming,
          domain,
          typography,
          logo,
          packaging
        )
      );
    },
    uploadCanvasLogo: (user_id, canvas_id, logo) => {
      dispatch(creativesActions.uploadLogo(user_id, canvas_id, logo));
    },
    uploadCanvasHistoryLogo: (user_id, canvas_id, history_id, logo) => {
      dispatch(
        historyAction.uploadLogoHistory(user_id, canvas_id, history_id, logo)
      );
    },
    uploadPackaging: (user_id, canvas_id, packaging) => {
      dispatch(creativesActions.uploadPackage(user_id, canvas_id, packaging));
    },
    uploadHistoryPackaging: (user_id, canvas_id, history_id, packaging) => {
      dispatch(
        historyAction.uploadPackageHistory(
          user_id,
          canvas_id,
          history_id,
          packaging
        )
      );
    },
    deleteCreativeFile: (user_id, canvas_id, type, path) => {
      dispatch(creativesActions.deleteCreativeFile(user_id, canvas_id, type, path));
    },
    deleteHistoryCreativeFile: (user_id, canvas_id, history_id, type, path) => {
      dispatch(historyAction.deleteHistoryCreativeFile(user_id, canvas_id, history_id, type, path));
    },
    addColor: (user_id, canvas_id, list, color) => {
      dispatch(creativesActions.addColor(user_id, canvas_id, list, color));
    },
    addHistoryColor: (user_id, canvas_id, history_id, list, color) => {
      dispatch(
        historyAction.addColorHistory(
          user_id,
          canvas_id,
          history_id,
          list,
          color
        )
      );
    },
    deleteColor: (user_id, canvas_id, list, color) => {
      dispatch(creativesActions.deleteColor(user_id, canvas_id, list, color));
    },
    deleteHistoryColor: (user_id, canvas_id, history_id, list, color) => {
      dispatch(
        historyAction.deleteColorHistory(
          user_id,
          canvas_id,
          history_id,
          list,
          color
        )
      );
    },
    filterComment: (canvas_id, list, section) => {
      dispatch(canvasActions.filterComment(canvas_id, list, section));
    },
    invalidFileFormat: () => {
      dispatch(
        notificationAction.invalidFileFormat(
          true,
          false,
          "Please upload an image file"
        )
      );
    },
    updateCreativesHistory: (
      user_id,
      canvas_id,
      history_id,
      naming,
      domain,
      typography,
      logo,
      packaging
    ) => {
      dispatch(
        historyAction.updateCreativesHistory(
          user_id,
          canvas_id,
          history_id,
          naming,
          domain,
          typography,
          logo,
          packaging
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    creatives: state.creatives,
    UpdateCreatives: state.updateCreatives,
    commentList: state.commentList,
    canvasView: state.canvasView,
    DeleteColor: state.deleteColor
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Creatives);
