import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/customer.history.action";
import * as customerAction from "../../actions/canvas.customer.action";
import axios from "../../config/axios";

// Update customer audience history
export function* updateCustomerAudienceHistorySaga(action) {
  yield put(actions.updateCustomerAudienceHistoryStart());
  const data = {
    user_id: action.user_id,
    customer_audience: action.customer_audience,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/customer-audience`,
      data
    );
    yield put(
      actions.updateCustomerAudienceHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(customerAction.setCustomerAudience(action.customer_audience));
  } catch (e) {
    yield put(actions.updateCustomerAudienceHistoryFail(e.message));
  }
}

// Update customer elevate
export function* updateCustomerElevateHistorySaga(action) {
  yield put(actions.updateCustomerElevateHistoryStart());
  const data = {
    user_id: action.user_id,
    customer_elevate: action.customer_elevate,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/customer-elevate`,
      data
    );
    yield put(
      actions.updateCustomerElevateHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(customerAction.setCustomerElevate(action.customer_elevate));
  } catch (e) {
    yield put(actions.updateCustomerElevateHistoryFail(e.message));
  }
}

// Update community
export function* updateCommunityHistorySaga(action) {
  yield put(actions.updateCommunityHistoryStart());
  const data = {
    user_id: action.user_id,
    community: action.community,
    history_id: action.history_id
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/community`,
      data
    );
    yield put(
      actions.updateCommunityHistorySuccess(response.status, response.message)
    );
    yield put(customerAction.setCommunity(action.community));
  } catch (e) {
    yield put(actions.updateCommunityHistoryFailure(e.message));
  }
}
