import * as actionTypes from "../actionTypes";

// Canvas history list
export const canvasHistoryListStart = () => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_INIT
  };
};

export const canvasHistoryList = (user_id, canvas_id, page, limit) => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST,
    user_id: user_id,
    canvas_id: canvas_id,
    page: page,
    limit: limit
  };
};

export const canvasHistoryListSuccess = (message, list) => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_SUCCESS,
    message: message,
    list: list
  };
};

export const canvasHistoryListFailure = error => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_FAILURE,
    error: error
  };
};

// Canvas history list - load more
export const canvasHistoryListLoadMoreStart = () => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_LOAD_MORE_INIT
  };
};

export const canvasHistoryListLoadMore = (user_id, canvas_id, list, page) => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_LOAD_MORE,
    user_id: user_id,
    canvas_id: canvas_id,
    list: list,
    page: page
  };
};

export const canvasHistoryListLoadMoreSuccess = (message, oldList, newList) => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_LOAD_MORE_SUCCESS,
    message: message,
    oldList: oldList,
    newList: newList
  };
};

export const canvasHistoryListLoadMoreFailure = error => {
  return {
    type: actionTypes.CANVAS_HISTORY_LIST_LOAD_MORE_FAILURE,
    error: error
  };
};

// Canvas history details
export const canvasHistoryDetailsFetchStart = () => {
  return {
    type: actionTypes.CANVAS_HISTORY_DETAILS_INIT
  };
};

export const canvasHistoryDetailsFetch = (user_id, canvas_id, history_id) => {
  return {
    type: actionTypes.CANVAS_HISTORY_DETAILS,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id
  };
};

export const canvasHistoryDetailsFetchSuccess = (message, details) => {
  return {
    type: actionTypes.CANVAS_HISTORY_DETAILS_SUCCESS,
    message: message,
    details: details
  };
};

export const canvasHistoryDetailsFetchFailure = error => {
  return {
    type: actionTypes.CANVAS_HISTORY_DETAILS_FAILURE,
    error: error
  };
};

// Reset history list scroller
export const resetHistoryScroller = () => {
  return {
    type: actionTypes.RESET_HISTORY_LIST_SCROLLER
  };
};
