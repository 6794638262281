import React, { Component } from "react";
import Aux from "../../../../hoc/Auxilary/Auxilary";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as accountActions from "../../../../actions/account.action";
import { getUserIdFromToken } from "../../../../shared/utils/utils";

class Account extends Component {
  componentDidMount() {
    if (localStorage.getItem("token")) {
      const user_id = getUserIdFromToken();
      this.props.getAccountDetails(user_id);
    }
  }

  render() {
    const accountSchema = Yup.object().shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      password: Yup.string().min(6, "Password must be atleast 6 characters"),
      newPassword: Yup.string().min(6, "Password must be atleast 6 characters")
    });

    let accountForm;

    if (this.props.accountDetails.details != null) {
      accountForm = (
        <Formik
          enableReinitialize="true"
          initialValues={{
            first_name: this.props.accountDetails.details.first_name,
            last_name: this.props.accountDetails.details.last_name,
            email: this.props.accountDetails.details.email,
            password: "",
            newPassword: ""
          }}
          validationSchema={accountSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.props.updateAccount(
              this.props.accountDetails.details._id,
              values.first_name,
              values.last_name,
              values.email,
              values.password,
              values.newPassword
            );
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={"account__form"}>
              <div className={"account__group"}>
                <label htmlFor="fname">First name</label>
                <Field
                  id="fname"
                  type="text"
                  name="first_name"
                  placeholder="First name"
                />
              </div>
              <ErrorMessage
                className="validation__error"
                name="first_name"
                component="div"
              />

              <div className={"account__group"}>
                <label htmlFor="lname">Last name</label>
                <Field
                  id="lname"
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                />
              </div>
              <ErrorMessage
                className="validation__error"
                name="last_name"
                component="div"
              />

              <div className={"account__group"}>
                <label htmlFor="email">Email</label>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
              </div>
              <ErrorMessage
                className="validation__error"
                name="email"
                component="div"
              />

              <div className={"account__group"}>
                <label htmlFor="password">Current password</label>
                <Field
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter password"
                />
              </div>
              <ErrorMessage
                className="validation__error"
                name="password"
                component="div"
              />

              <div className={"account__group"}>
                <label htmlFor="newPassword">New password</label>
                <Field
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  placeholder="Enter password"
                />
              </div>
              <ErrorMessage
                className="validation__error"
                name="newPassword"
                component="div"
              />

              <input type="submit" value="save" disabled={isSubmitting} />
              <Link to="/logout"> Log out</Link>
            </Form>
          )}
        </Formik>
      );
    }

    return (
      <Aux>
        <div className={"account"} id="account">
          <div className={"account__title"}>
            <h2>My Account</h2>

            <div className={"sidebar__close"}>
              <button>x</button>
            </div>
          </div>
          {accountForm}
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAccountDetails: user_id => {
      dispatch(accountActions.accountDetailsFetch(user_id));
    },
    updateAccount: (
      id,
      first_name,
      last_name,
      email,
      password,
      newPassword
    ) => {
      dispatch(
        accountActions.updateAccount(
          id,
          first_name,
          last_name,
          email,
          password,
          newPassword
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    accountDetails: state.accountDetails,
    login: state.login
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
