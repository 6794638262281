import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../shared/utils/utils";

const initialState = {
  error: null,
  loading: false,
  isLinkVerified: false,
  message: "",
  id: null
};

const verifyStart = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const verifySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    isLinkVerified: true,
    message: action.message,
    id: action.id
  });
};

const verifyFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
    isLinkVerified: false,
    message: action.message
  });
};

const verifyClearMessage = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    message: null,
    isLinkVerified: false
  });
};

const verifyReset = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    message: null,
    isLinkVerified: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VERIFICATION_INIT:
      return verifyStart(state, action);

    case actionTypes.VERIFICATION_SUCCESS:
      return verifySuccess(state, action);

    case actionTypes.VERIFICATION_FAIL:
      return verifyFail(state, action);

    case actionTypes.VERIFICATION_CLEAR:
      return verifyClearMessage(state, action);

    case actionTypes.VERIFICATION_RESET:
      return verifyReset(state, action);

    default:
      return state;
  }
};

export default reducer;
