import axios from "axios";
import config from "./config";

const axiosInstance = axios.create({
  baseURL: config.publicUrl
});

// Request configurations
axiosInstance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response configurations
axiosInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error.response.data);
  }
);

export default axiosInstance;
