import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as verifyActions from "../../../actions/forgotPassword/verifyInitiate.action";
import * as resetActions from "../../../actions/forgotPassword/resetPassword.action";
import Aux from "../../../hoc/Auxilary/Auxilary";
import SimpleAlert from "../../UI/SimpleAlert/SimpleAlert";

class ResetPassword extends Component {
  componentDidMount() {
    this.props.verifyInitiateToken(this.props.match.params.token);
  }

  componentWillUnmount() {
    this.props.clearVerificationMessage();
    if (this.props.isPasswordReset === true) {
      this.props.clearResetMessage();
    }
  }

  state = {
    isLinkVerified: "",
    message: "",
    error: "",
    show: false,
    disabled: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLinkVerified === false && nextProps.error === true) {
      this.setState({
        show: true,
        error: true,
        message: nextProps.message,
        disabled: true
      });
    }
    if (nextProps.isPasswordReset === true && nextProps.error === null) {
      this.setState({
        show: true,
        error: false,
        message: nextProps.resetMessage
      });

      setTimeout(function () {
        this.props.history.push('/login')
      }.bind(this), 1500);
    }
  }

  close = () => {
    this.setState({ show: false, error: false, message: "" });
  };

  render() {
    const resetPasswordSchema = Yup.object().shape({
      newPassword: Yup.string()
        .min(6, "Password must be atleast 6 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Confirm password must match")
        .min(6, "Password must be atleast 6 characters")
        .required("Password confirmation is required")
    });

    return (
      <Aux>
        <div id="access">
          <main className={"wrapper"}>
            <div className={"access"}>
              <div className={"container"}>
                <div className={"access__logo"}>
                  <Link to="/">
                    <img src="../img/access/logo.png" alt="eBrandBuilders" />
                  </Link>
                </div>
                {this.state.show === true ? (
                  <form className={"access__form"}>
                    <SimpleAlert
                      show={this.state.show}
                      close={this.close}
                      className={"custom__alert"}
                      message={this.state.message}
                    />
                  </form>
                ) : null}
                <Formik
                  initialValues={{ newPassword: "", confirmPassword: "" }}
                  validationSchema={resetPasswordSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.resetPassword(this.props.id, values.newPassword);
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className={"access__form"}>
                      <div className={"access__group"}>
                        <Field
                          type="password"
                          name="newPassword"
                          placeholder="New Password"
                        />
                        <ErrorMessage
                          className="validation__error"
                          name="newPassword"
                          component="div"
                        />
                      </div>

                      <div className={"access__group"}>
                        <Field
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                        />
                        <ErrorMessage
                          className="validation__error"
                          name="confirmPassword"
                          component="div"
                        />
                      </div>

                      <div className={"access__group"}>
                        <input
                          type="submit"
                          value="Reset My Password"
                          disabled={this.state.disabled}
                        />
                      </div>
                      <div className={"access__group"}>
                        <Link to="/login">click here to login</Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.verifyInitiate.loading,
    error: state.verifyInitiate.error,
    isLinkVerified: state.verifyInitiate.isLinkVerified,
    message: state.verifyInitiate.message,
    id: state.verifyInitiate.id,
    isPasswordReset: state.resetPassword.isPasswordReset,
    resetMessage: state.resetPassword.message,
    resetPassword: state.resetPassword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyInitiateToken: token => dispatch(verifyActions.verifyProcess(token)),
    resetPassword: (id, password) =>
      dispatch(resetActions.resetProcess(id, password)),
    clearVerificationMessage: () =>
      dispatch(verifyActions.verifyClearMessage()),
    clearResetMessage: () => dispatch(resetActions.resetClearMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
