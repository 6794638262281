import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import Home from "./components/public/Home/Home";
// import Signup from "./components/public/Signup/Signup";
import Login from "./components/public/Login/Login";
import ForgotPassword from "./components/public/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/public/ResetPassword/ResetPassword";
import Container from "./hoc/Layout/Container";
import Logout from "./components/user/Rightmenu/Logout/Logout";
import View from "./hoc/Public/View";
import Collaborate from "./hoc/Public/Collaborate";
import Share from "./hoc/Public/Share";
import NotFound from "./components/public/NotFound/NotFound";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/sign-up" component={Signup} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/password/reset/:token" component={ResetPassword} />
        <Route exact path="/logout" component={Logout} />
        <PrivateRoute exact path="/dashboard" component={Container} />

        <Route exact path="/canvas/view/:token" component={View} />
        <Route
          exact
          path="/canvas/collaborate/:token"
          component={Collaborate}
        />
        <Route exact path="/canvas/invitation/:token" component={Share} />

        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    );
  }
}

export default withRouter(App);
