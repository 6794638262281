import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as notificationAction from "../../../actions/notification.action";

class Notification extends Component {
  state = {
    open: false,
    message: ""
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.notification.show === true &&
      nextProps.notification.newStatus === false
    ) {
      this.setState({ open: false, message: nextProps.notification.message });
      // setTimeout(function() {
      //   nextProps.clearNotification();
      // }, 1500);
    } else if (nextProps.notification.show === true) {
      this.setState({ open: true, message: nextProps.notification.message });
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false, message: "" });
    this.props.clearNotification();
  };

  render() {
    return (
      <Aux>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span className="notification__message" id="notification__message">
              {this.state.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearNotification: () => dispatch(notificationAction.clearNotification())
  };
};

const mapStateToProps = state => {
  return {
    notification: state.notification
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
