import * as actionTypes from "./actionTypes";

export const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_INIT
  };
};

export const signup = (first_name, last_name, zip_code, email, password) => {
  return {
    type: actionTypes.SIGNUP,
    first_name: first_name,
    last_name: last_name,
    zip_code: zip_code,
    email: email,
    password: password
  };
};

export const signupSuccess = message => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    message: message
  };
};

export const signupFail = message => {
  return {
    type: actionTypes.SIGNUP_FAIL,
    message: message
  };
};

export const clearMessage = () => {
  return {
    type: actionTypes.CLEAR_SIGNUP_MESSAGE
  };
};

export const resetSignup = () => {
  return {
    type: actionTypes.RESET_SIGNUP
  };
};

// Check user already in list
export const checkUserInListStart = () => {
  return {
    type: actionTypes.CHECK_USER_IN_LIST_INIT
  };
};

export const checkUserInList = email => {
  return {
    type: actionTypes.CHECK_USER_IN_LIST,
    email: email
  };
};

export const checkUserInListSuccess = message => {
  return {
    type: actionTypes.CHECK_USER_IN_LIST_SUCCESS,
    message: message
  };
};

export const checkUserInListFail = message => {
  return {
    type: actionTypes.CHECK_USER_IN_LIST_FAILURE,
    message: message
  };
};
