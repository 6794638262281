import * as actionTypes from "../actionTypes";

// Update creatives history
export const updateCreativesHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CREATIVES_HISTORY_INIT
  };
};

export const updateCreativesHistory = (
  user_id,
  canvas_id,
  history_id,
  naming,
  domain,
  typography,
  logo,
  packaging
) => {
  return {
    type: actionTypes.UPDATE_CREATIVES_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    naming: naming,
    domain: domain,
    typography: typography,
    logo: logo,
    packaging: packaging
  };
};

export const updateCreativesHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CREATIVES_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCreativesHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CREATIVES_HISTORY_FAILURE,
    message: message
  };
};

// Upload history logo
export const uploadLogoHistoryStart = () => {
  return {
    type: actionTypes.UPLOAD_LOGO_HISTORY_INIT
  };
};

export const uploadLogoHistory = (user_id, canvas_id, history_id, file) => {
  return {
    type: actionTypes.UPLOAD_LOGO_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    file: file
  };
};

export const uploadLogoHistorySuccess = (message, path) => {
  return {
    type: actionTypes.UPLOAD_LOGO_HISTORY_SUCCESS,
    message: message,
    path: path
  };
};

export const uploadLogoHistoryFail = message => {
  return {
    type: actionTypes.UPLOAD_LOGO_HISTORY_FAILURE,
    message: message
  };
};

// Upload history package
export const uploadPackageHistoryStart = () => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_HISTORY_INIT
  };
};

export const uploadPackageHistory = (user_id, canvas_id, history_id, file) => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    file: file
  };
};

export const uploadPackageHistorySuccess = (message, path) => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_HISTORY_SUCCESS,
    message: message,
    path: path
  };
};

export const uploadPackageHistoryFail = message => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_HISTORY_FAILURE,
    message: message
  };
};

// Add history color
export const addColorHistoryStart = () => {
  return {
    type: actionTypes.ADD_COLOR_HISTORY_INIT
  };
};

export const addColorHistory = (
  user_id,
  canvas_id,
  history_id,
  list,
  color
) => {
  return {
    type: actionTypes.ADD_COLOR_HISTORY,
    canvas_id: canvas_id,
    user_id: user_id,
    history_id: history_id,
    list: list,
    color: color
  };
};

export const addColorHistorySuccess = message => {
  return {
    type: actionTypes.ADD_COLOR_HISTORY_SUCCESS,
    message: message
  };
};

export const addColorHistoryFail = message => {
  return {
    type: actionTypes.ADD_COLOR_HISTORY_FAILURE,
    message: message
  };
};

// Delete color
export const deleteColorHistoryStart = () => {
  return {
    type: actionTypes.DELETE_COLOR_HISTORY_INIT
  };
};

export const deleteColorHistory = (
  user_id,
  canvas_id,
  history_id,
  list,
  color
) => {
  return {
    type: actionTypes.DELETE_COLOR_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    list: list,
    color: color
  };
};

export const deleteColorHistorySuccess = message => {
  return {
    type: actionTypes.DELETE_COLOR_HISTORY_SUCCESS,
    message: message
  };
};

export const deleteColorHistoryFail = message => {
  return {
    type: actionTypes.DELETE_COLOR_HISTORY_FAILURE,
    message: message
  };
};


// Delete history logo/package
export const deleteHistoryCreativeFileStart = () => {
  return {
    type: actionTypes.DELETE_HISTORY_CREATIVE_FILE_INIT
  };
};

export const deleteHistoryCreativeFile = (user_id, canvas_id, history_id, type, path) => {
  return {
    type: actionTypes.DELETE_HISTORY_CREATIVE_FILE,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id,
    fileType: type,
    path
  };
};

export const deleteHistoryCreativeFileSuccess = (message) => {
  return {
    type: actionTypes.DELETE_HISTORY_CREATIVE_FILE_SUCCESS,
    message: message,
  };
};

export const deleteHistoryCreativeFileFail = message => {
  return {
    type: actionTypes.DELETE_HISTORY_CREATIVE_FILE_FAILURE,
    message: message
  };
};