import React, { Component } from "react";
import Aux from "../../../../hoc/Auxilary/Auxilary";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { getUserIdFromToken } from "../../../../shared/utils/utils";
import * as canvasActions from "../../../../actions/canvas.action";

class Notepad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty()
    };

    this.user_id = getUserIdFromToken();
    this.canvas_id = null;
  }

  onChange = editorState => this.setState({ editorState: editorState });

  onContentStateChange = editorState => {
    this.setState({
      editorState: editorState
    });
  };

  componentWillReceiveProps(nextProps) {
    this.canvas_id = nextProps.notepad.canvas_id;
    if (nextProps.notepad.notes && nextProps.notepad.notes !== "") {
      let content = JSON.parse(nextProps.notepad.notes);
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(content))
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty()
      });
    }
  }

  updateDetails = (event, state) => {
    const content = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    if (this.canvas_id !== null) {
      this.props.noteUpdate(this.user_id, this.canvas_id, content);
    }
  };

  render() {
    const { editorState } = this.state;

    return (
      <Aux>
        <div className={"notepad"} id="notepad">
          <div className={"notepad__title"}>
            <h2>Notepad</h2>

            <div className={"sidebar__close"}>
              <button>x</button>
            </div>
          </div>
          <Editor
            editorState={editorState}
            onEditorStateChange={this.onChange}
            onBlur={event => this.updateDetails(event, editorState)}
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "fontFamily",
                "list"
              ],
              inline: {
                options: [
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "monospace"
                ],
                bold: { className: "bordered-option-classname" },
                italic: { className: "bordered-option-classname" },
                underline: { className: "bordered-option-classname" },
                strikethrough: { className: "bordered-option-classname" },
                code: { className: "bordered-option-classname" }
              },
              fontSize: {
                className: "bordered-option-classname"
              },
              fontFamily: {
                className: "bordered-option-classname"
              },
              list: {
                inDropdown: true,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ["unordered", "ordered", "indent", "outdent"]
              }
            }}
          />
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    noteUpdate: (user_id, canvas_id, note) => {
      dispatch(canvasActions.noteUpdate(user_id, canvas_id, note));
    }
  };
};

const mapStateToProps = state => {
  return {
    notepad: state.notepad
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notepad);
