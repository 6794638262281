import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Channel text owned store
const channelTextOwnedState = {
  canvas_id: null,
  history_id: null,
  channel_owned_text1: "",
  channel_owned_value1: "",
  channel_owned_text2: "",
  channel_owned_value2: "",
  channel_owned_text3: "",
  channel_owned_value3: "",
  channel_owned_text4: "",
  channel_owned_value4: "",
  channel_owned_text5: "",
  channel_owned_value5: "",

  channel_shared_text1: "",
  channel_shared_value1: "",
  channel_shared_text2: "",
  channel_shared_value2: "",
  channel_shared_text3: "",
  channel_shared_value3: "",
  channel_shared_text4: "",
  channel_shared_value4: "",
  channel_shared_text5: "",
  channel_shared_value5: "",

  channel_paid_text1: "",
  channel_paid_value1: "",
  channel_paid_text2: "",
  channel_paid_value2: "",
  channel_paid_text3: "",
  channel_paid_value3: "",
  channel_paid_text4: "",
  channel_paid_value4: "",
  channel_paid_text5: "",
  channel_paid_value5: "",

  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const channelTextValue = (state = channelTextOwnedState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANNEL_TEXT_VALUE_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,

          channel_owned_text1: action.details.channel_owned_text1,
          channel_owned_value1: action.details.channel_owned_value1,
          channel_owned_text2: action.details.channel_owned_text2,
          channel_owned_value2: action.details.channel_owned_value2,
          channel_owned_text3: action.details.channel_owned_text3,
          channel_owned_value3: action.details.channel_owned_value3,
          channel_owned_text4: action.details.channel_owned_text4,
          channel_owned_value4: action.details.channel_owned_value4,
          channel_owned_text5: action.details.channel_owned_text5,
          channel_owned_value5: action.details.channel_owned_value5,

          channel_shared_text1: action.details.channel_shared_text1,
          channel_shared_value1: action.details.channel_shared_value1,
          channel_shared_text2: action.details.channel_shared_text2,
          channel_shared_value2: action.details.channel_shared_value2,
          channel_shared_text3: action.details.channel_shared_text3,
          channel_shared_value3: action.details.channel_shared_value3,
          channel_shared_text4: action.details.channel_shared_text4,
          channel_shared_value4: action.details.channel_shared_value4,
          channel_shared_text5: action.details.channel_shared_text5,
          channel_shared_value5: action.details.channel_shared_value5,

          channel_paid_text1: action.details.channel_paid_text1,
          channel_paid_value1: action.details.channel_paid_value1,
          channel_paid_text2: action.details.channel_paid_text2,
          channel_paid_value2: action.details.channel_paid_value2,
          channel_paid_text3: action.details.channel_paid_text3,
          channel_paid_value3: action.details.channel_paid_value3,
          channel_paid_text4: action.details.channel_paid_text4,
          channel_paid_value4: action.details.channel_paid_value4,
          channel_paid_text5: action.details.channel_paid_text5,
          channel_paid_value5: action.details.channel_paid_value5
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,

          channel_owned_text1: action.details.channel_owned_text1,
          channel_owned_value1: action.details.channel_owned_value1,
          channel_owned_text2: action.details.channel_owned_text2,
          channel_owned_value2: action.details.channel_owned_value2,
          channel_owned_text3: action.details.channel_owned_text3,
          channel_owned_value3: action.details.channel_owned_value3,
          channel_owned_text4: action.details.channel_owned_text4,
          channel_owned_value4: action.details.channel_owned_value4,
          channel_owned_text5: action.details.channel_owned_text5,
          channel_owned_value5: action.details.channel_owned_value5,

          channel_shared_text1: action.details.channel_shared_text1,
          channel_shared_value1: action.details.channel_shared_value1,
          channel_shared_text2: action.details.channel_shared_text2,
          channel_shared_value2: action.details.channel_shared_value2,
          channel_shared_text3: action.details.channel_shared_text3,
          channel_shared_value3: action.details.channel_shared_value3,
          channel_shared_text4: action.details.channel_shared_text4,
          channel_shared_value4: action.details.channel_shared_value4,
          channel_shared_text5: action.details.channel_shared_text5,
          channel_shared_value5: action.details.channel_shared_value5,

          channel_paid_text1: action.details.channel_paid_text1,
          channel_paid_value1: action.details.channel_paid_value1,
          channel_paid_text2: action.details.channel_paid_text2,
          channel_paid_value2: action.details.channel_paid_value2,
          channel_paid_text3: action.details.channel_paid_text3,
          channel_paid_value3: action.details.channel_paid_value3,
          channel_paid_text4: action.details.channel_paid_text4,
          channel_paid_value4: action.details.channel_paid_value4,
          channel_paid_text5: action.details.channel_paid_text5,
          channel_paid_value5: action.details.channel_paid_value5
        });
      }

    case actionTypes.CLEAR_CHANNEL_TEXT_VALUE_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",

        channel_owned_text1: "",
        channel_owned_value1: "",
        channel_owned_text2: "",
        channel_owned_value2: "",
        channel_owned_text3: "",
        channel_owned_value3: "",
        channel_owned_text4: "",
        channel_owned_value4: "",
        channel_owned_text5: "",
        channel_owned_value5: "",

        channel_shared_text1: "",
        channel_shared_value1: "",
        channel_shared_text2: "",
        channel_shared_value2: "",
        channel_shared_text3: "",
        channel_shared_value3: "",
        channel_shared_text4: "",
        channel_shared_value4: "",
        channel_shared_text5: "",
        channel_shared_value5: "",

        channel_paid_text1: "",
        channel_paid_value1: "",
        channel_paid_text2: "",
        channel_paid_value2: "",
        channel_paid_text3: "",
        channel_paid_value3: "",
        channel_paid_text4: "",
        channel_paid_value4: "",
        channel_paid_text5: "",
        channel_paid_value5: ""
      });

    case actionTypes.SET_CHANNEL_TEXT_VALUE:
      return updateObject(state, {
        channel_owned_text1: action.channel_owned_text1,
        channel_owned_value1: action.channel_owned_value1,
        channel_owned_text2: action.channel_owned_text2,
        channel_owned_value2: action.channel_owned_value2,
        channel_owned_text3: action.channel_owned_text3,
        channel_owned_value3: action.channel_owned_value3,
        channel_owned_text4: action.channel_owned_text4,
        channel_owned_value4: action.channel_owned_value4,
        channel_owned_text5: action.channel_owned_text5,
        channel_owned_value5: action.channel_owned_value5,

        channel_shared_text1: action.channel_shared_text1,
        channel_shared_value1: action.channel_shared_value1,
        channel_shared_text2: action.channel_shared_text2,
        channel_shared_value2: action.channel_shared_value2,
        channel_shared_text3: action.channel_shared_text3,
        channel_shared_value3: action.channel_shared_value3,
        channel_shared_text4: action.channel_shared_text4,
        channel_shared_value4: action.channel_shared_value4,
        channel_shared_text5: action.channel_shared_text5,
        channel_shared_value5: action.channel_shared_value5,

        channel_paid_text1: action.channel_paid_text1,
        channel_paid_value1: action.channel_paid_value1,
        channel_paid_text2: action.channel_paid_text2,
        channel_paid_value2: action.channel_paid_value2,
        channel_paid_text3: action.channel_paid_text3,
        channel_paid_value3: action.channel_paid_value3,
        channel_paid_text4: action.channel_paid_text4,
        channel_paid_value4: action.channel_paid_value4,
        channel_paid_text5: action.channel_paid_text5,
        channel_paid_value5: action.channel_paid_value5
      });

    default: {
      return state;
    }
  }
};

// Update channel text value
const updateChannelTextValueState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateChannelTextValue = (
  state = updateChannelTextValueState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CHANNEL_TEXT_VALUE_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_VALUE_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_VALUE_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CHANNEL_TEXT_VALUE_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_VALUE_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CHANNEL_TEXT_VALUE_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
