import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../shared/utils/utils";

const initialState = {
  error: null,
  loading: false,
  resetInitiated: false,
  message: ""
};

const initiateStart = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const initiateSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    resetInitiated: true,
    message: action.message
  });
};

const initiateFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
    resetInitiated: false,
    message: action.message
  });
};

const initiateClearMessage = (state, action) => {
  return updateObject(state, { loading: false, error: null, message: null });
};

const initiateReset = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    message: null,
    resetInitiated: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INITIATE_START:
      return initiateStart(state, action);

    case actionTypes.INITIATE_SUCCESS:
      return initiateSuccess(state, action);

    case actionTypes.INITIATE_FAIL:
      return initiateFail(state, action);

    case actionTypes.INITIATE_CLEAR_MESSAGE:
      return initiateClearMessage(state, action);

    case actionTypes.INITIATE_RESET:
      return initiateReset(state, action);

    default:
      return state;
  }
};

export default reducer;
