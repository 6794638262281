import * as actionTypes from "./actionTypes";

// Update channel text value
export const updateChannelTextValueStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_VALUE_INIT
  };
};

export const updateChannelTextValue = (
  user_id,
  canvas_id,
  channel_owned_text1,
  channel_owned_value1,
  channel_owned_text2,
  channel_owned_value2,
  channel_owned_text3,
  channel_owned_value3,
  channel_owned_text4,
  channel_owned_value4,
  channel_owned_text5,
  channel_owned_value5,

  channel_shared_text1,
  channel_shared_value1,
  channel_shared_text2,
  channel_shared_value2,
  channel_shared_text3,
  channel_shared_value3,
  channel_shared_text4,
  channel_shared_value4,
  channel_shared_text5,
  channel_shared_value5,

  channel_paid_text1,
  channel_paid_value1,
  channel_paid_text2,
  channel_paid_value2,
  channel_paid_text3,
  channel_paid_value3,
  channel_paid_text4,
  channel_paid_value4,
  channel_paid_text5,
  channel_paid_value5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_VALUE,
    user_id: user_id,
    canvas_id: canvas_id,

    channel_owned_text1: channel_owned_text1,
    channel_owned_value1: channel_owned_value1,
    channel_owned_text2: channel_owned_text2,
    channel_owned_value2: channel_owned_value2,
    channel_owned_text3: channel_owned_text3,
    channel_owned_value3: channel_owned_value3,
    channel_owned_text4: channel_owned_text4,
    channel_owned_value4: channel_owned_value4,
    channel_owned_text5: channel_owned_text5,
    channel_owned_value5: channel_owned_value5,

    channel_shared_text1: channel_shared_text1,
    channel_shared_value1: channel_shared_value1,
    channel_shared_text2: channel_shared_text2,
    channel_shared_value2: channel_shared_value2,
    channel_shared_text3: channel_shared_text3,
    channel_shared_value3: channel_shared_value3,
    channel_shared_text4: channel_shared_text4,
    channel_shared_value4: channel_shared_value4,
    channel_shared_text5: channel_shared_text5,
    channel_shared_value5: channel_shared_value5,

    channel_paid_text1: channel_paid_text1,
    channel_paid_value1: channel_paid_value1,
    channel_paid_text2: channel_paid_text2,
    channel_paid_value2: channel_paid_value2,
    channel_paid_text3: channel_paid_text3,
    channel_paid_value3: channel_paid_value3,
    channel_paid_text4: channel_paid_text4,
    channel_paid_value4: channel_paid_value4,
    channel_paid_text5: channel_paid_text5,
    channel_paid_value5: channel_paid_value5
  };
};

export const updateChannelTextValueSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_VALUE_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextValueFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_VALUE_FAILURE,
    message: message
  };
};

// Set channel text value in store
export const setChannelTextValue = (
  channel_owned_text1,
  channel_owned_value1,
  channel_owned_text2,
  channel_owned_value2,
  channel_owned_text3,
  channel_owned_value3,
  channel_owned_text4,
  channel_owned_value4,
  channel_owned_text5,
  channel_owned_value5,

  channel_shared_text1,
  channel_shared_value1,
  channel_shared_text2,
  channel_shared_value2,
  channel_shared_text3,
  channel_shared_value3,
  channel_shared_text4,
  channel_shared_value4,
  channel_shared_text5,
  channel_shared_value5,

  channel_paid_text1,
  channel_paid_value1,
  channel_paid_text2,
  channel_paid_value2,
  channel_paid_text3,
  channel_paid_value3,
  channel_paid_text4,
  channel_paid_value4,
  channel_paid_text5,
  channel_paid_value5
) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_VALUE,
    channel_owned_text1: channel_owned_text1,
    channel_owned_value1: channel_owned_value1,
    channel_owned_text2: channel_owned_text2,
    channel_owned_value2: channel_owned_value2,
    channel_owned_text3: channel_owned_text3,
    channel_owned_value3: channel_owned_value3,
    channel_owned_text4: channel_owned_text4,
    channel_owned_value4: channel_owned_value4,
    channel_owned_text5: channel_owned_text5,
    channel_owned_value5: channel_owned_value5,

    channel_shared_text1: channel_shared_text1,
    channel_shared_value1: channel_shared_value1,
    channel_shared_text2: channel_shared_text2,
    channel_shared_value2: channel_shared_value2,
    channel_shared_text3: channel_shared_text3,
    channel_shared_value3: channel_shared_value3,
    channel_shared_text4: channel_shared_text4,
    channel_shared_value4: channel_shared_value4,
    channel_shared_text5: channel_shared_text5,
    channel_shared_value5: channel_shared_value5,

    channel_paid_text1: channel_paid_text1,
    channel_paid_value1: channel_paid_value1,
    channel_paid_text2: channel_paid_text2,
    channel_paid_value2: channel_paid_value2,
    channel_paid_text3: channel_paid_text3,
    channel_paid_value3: channel_paid_value3,
    channel_paid_text4: channel_paid_text4,
    channel_paid_value4: channel_paid_value4,
    channel_paid_text5: channel_paid_text5,
    channel_paid_value5: channel_paid_value5
  };
};
