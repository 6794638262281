import * as actionTypes from "./actionTypes";

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_INIT
  };
};

export const login = (email, password) => {
  return {
    type: actionTypes.LOGIN,
    email: email,
    password: password
  };
};

export const loginSuccess = message => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    message: message
  };
};

export const loginFail = message => {
  return {
    type: actionTypes.LOGIN_FAIL,
    message: message
  };
};

/** Login check at the beginning of app loading */
export const loginCheckStart = () => {
  return {
    type: actionTypes.LOGIN_CHECK_INIT
  };
};

export const loginCheck = () => {
  return {
    type: actionTypes.LOGIN_CHECK
  };
};

export const loginCheckSuccess = () => {
  return {
    type: actionTypes.LOGIN_CHECK_SUCCESS
  };
};

export const loginCheckFail = () => {
  return {
    type: actionTypes.LOGIN_CHECK_FAIL
  };
};

/** Reset login props on logout */
export const resetLogin = () => {
  return {
    type: actionTypes.LOGIN_RESET
  };
};

export const resetLoginCheck = () => {
  return {
    type: actionTypes.LOGIN_CHECK_RESET
  };
};

// Log out
export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  };
};
