import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import ChannelText from "./ChannelText";
import ChannelTextValue from "./ChannelTextValue";

class Channel extends Component {
  render() {
    return (
      <Aux>
        <div className={"content__element"}>
          <div className={"content__title"}>
            <h2>
              <img src="/img/main/Channel.svg" alt="Channel" />
              Channel
            </h2>

            <button className={"content__title-btn off"}>
              <span />
            </button>
          </div>

          <div className={"content-first auto-scroll"}>
            <div className={"content__subtitle"}>
              <h3>YOUR BRAND TOUCHPOINTS </h3>
            </div>
            <ChannelTextValue />
          </div>

          <div className={"content-second show"}>
            <div className={"content__subtitle"}>
              <h3>Your brand touchpoints</h3>
            </div>

            <ChannelText
              title={"OWNED CHANNEL"}
              onCancel={this.onCancel}
              numberValidation={false}
              showCommentButton={true}
              commentSection={"channel_text_owned"}
              field={"channel_text_owned"}
              commentPosition={"left"}
              fields={[
                "channel_text_owned_text1",
                "channel_text_owned_text2",
                "channel_text_owned_text3",
                "channel_text_owned_text4",
                "channel_text_owned_text5"
              ]}
            />

            <ChannelText
              title={"SHARED CHANNEL"}
              numberValidation={false}
              showCommentButton={true}
              commentSection={"channel_text_shared"}
              field={"channel_text_shared"}
              commentPosition={"left"}
              fields={[
                "channel_text_shared_text1",
                "channel_text_shared_text2",
                "channel_text_shared_text3",
                "channel_text_shared_text4",
                "channel_text_shared_text5"
              ]}
            />

            <ChannelText
              title={"PAID CHANNEL"}
              numberValidation={false}
              showCommentButton={true}
              commentSection={"channel_text_paid"}
              field={"channel_text_paid"}
              commentPosition={"left"}
              fields={[
                "channel_text_paid_text1",
                "channel_text_paid_text2",
                "channel_text_paid_text3",
                "channel_text_paid_text4",
                "channel_text_paid_text5"
              ]}
            />
          </div>
        </div>
      </Aux>
    );
  }
}

export default Channel;
