import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Competition store
const competitionState = {
  canvas_id: null,
  history_id: null,
  competition: ""
};

export const competition = (state = competitionState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPETITION_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          competition: action.details.competition
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          competition: action.details.competition
        });
      }

    case actionTypes.CLEAR_COMPETITION_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        competition: ""
      });

    case actionTypes.SET_COMPETITION:
      return updateObject(state, {
        competition: action.competition
      });

    default: {
      return state;
    }
  }
};

// Update competition
const updateCompetitionState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateCompetition = (state = updateCompetitionState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMPETITION_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_COMPETITION_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_COMPETITION_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    //  History
    case actionTypes.UPDATE_COMPETITION_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_COMPETITION_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_COMPETITION_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    default: {
      return state;
    }
  }
};
