import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../shared/utils/utils";

const initialState = {
  error: null,
  loading: false,
  isPasswordReset: false,
  message: ""
};

const resetStart = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const resetSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    isPasswordReset: true,
    message: action.message
  });
};

const resetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    isPasswordReset: false,
    message: action.message
  });
};

const resetClearMessage = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    message: "",
    isPasswordReset: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_INIT:
      return resetStart(state, action);

    case actionTypes.RESET_SUCCESS:
      return resetSuccess(state, action);

    case actionTypes.RESET_FAIL:
      return resetFail(state, action);

    case actionTypes.RESET_CLEAR:
      return resetClearMessage(state, action);

    default:
      return state;
  }
};

export default reducer;
