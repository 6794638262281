import * as actionTypes from "./actionTypes";

// Update channel text owned
export const updateChannelTextOwnedStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_INIT
  };
};

export const updateChannelTextOwned = (
  user_id,
  canvas_id,
  channel_text_owned_text1,
  channel_text_owned_text2,
  channel_text_owned_text3,
  channel_text_owned_text4,
  channel_text_owned_text5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED,
    user_id: user_id,
    canvas_id: canvas_id,
    channel_text_owned_text1: channel_text_owned_text1,
    channel_text_owned_text2: channel_text_owned_text2,
    channel_text_owned_text3: channel_text_owned_text3,
    channel_text_owned_text4: channel_text_owned_text4,
    channel_text_owned_text5: channel_text_owned_text5
  };
};

export const updateChannelTextOwnedSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextOwnedFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_OWNED_FAILURE,
    message: message
  };
};

// Set channel text owned in store
export const setChannelTextOwned = (
  channel_text_owned_text1,
  channel_text_owned_text2,
  channel_text_owned_text3,
  channel_text_owned_text4,
  channel_text_owned_text5
) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_OWNED,
    channel_text_owned_text1: channel_text_owned_text1,
    channel_text_owned_text2: channel_text_owned_text2,
    channel_text_owned_text3: channel_text_owned_text3,
    channel_text_owned_text4: channel_text_owned_text4,
    channel_text_owned_text5: channel_text_owned_text5
  };
};

// Update channel text shared
export const updateChannelTextSharedStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_INIT
  };
};

export const updateChannelTextShared = (
  user_id,
  canvas_id,
  channel_text_shared_text1,
  channel_text_shared_text2,
  channel_text_shared_text3,
  channel_text_shared_text4,
  channel_text_shared_text5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED,
    user_id: user_id,
    canvas_id: canvas_id,
    channel_text_shared_text1: channel_text_shared_text1,
    channel_text_shared_text2: channel_text_shared_text2,
    channel_text_shared_text3: channel_text_shared_text3,
    channel_text_shared_text4: channel_text_shared_text4,
    channel_text_shared_text5: channel_text_shared_text5
  };
};

export const updateChannelTextSharedSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextSharedFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_SHARED_FAILURE,
    message: message
  };
};

// Set channel text shared in store
export const setChannelTextShared = (
  channel_text_shared_text1,
  channel_text_shared_text2,
  channel_text_shared_text3,
  channel_text_shared_text4,
  channel_text_shared_text5
) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_SHARED,
    channel_text_shared_text1: channel_text_shared_text1,
    channel_text_shared_text2: channel_text_shared_text2,
    channel_text_shared_text3: channel_text_shared_text3,
    channel_text_shared_text4: channel_text_shared_text4,
    channel_text_shared_text5: channel_text_shared_text5
  };
};

// Update channel text paid
export const updateChannelTextPaidStart = () => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_INIT
  };
};

export const updateChannelTextPaid = (
  user_id,
  canvas_id,
  channel_text_paid_text1,
  channel_text_paid_text2,
  channel_text_paid_text3,
  channel_text_paid_text4,
  channel_text_paid_text5
) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID,
    user_id: user_id,
    canvas_id: canvas_id,
    channel_text_paid_text1: channel_text_paid_text1,
    channel_text_paid_text2: channel_text_paid_text2,
    channel_text_paid_text3: channel_text_paid_text3,
    channel_text_paid_text4: channel_text_paid_text4,
    channel_text_paid_text5: channel_text_paid_text5
  };
};

export const updateChannelTextPaidSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_SUCCESS,
    status: status,
    message: message
  };
};

export const updateChannelTextPaidFail = message => {
  return {
    type: actionTypes.UPDATE_CHANNEL_TEXT_PAID_FAILURE,
    message: message
  };
};

// Set channel text paid in store
export const setChannelTextPaid = (
  channel_text_paid_text1,
  channel_text_paid_text2,
  channel_text_paid_text3,
  channel_text_paid_text4,
  channel_text_paid_text5
) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_PAID,
    channel_text_paid_text1: channel_text_paid_text1,
    channel_text_paid_text2: channel_text_paid_text2,
    channel_text_paid_text3: channel_text_paid_text3,
    channel_text_paid_text4: channel_text_paid_text4,
    channel_text_paid_text5: channel_text_paid_text5
  };
};
