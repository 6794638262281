import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Account details
const accountDetailState = {
  error: null,
  loading: false,
  details: null,
  message: null
};

export const accountDetails = (state = accountDetailState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_DETAILS_FETCH_INIT:
      return updateObject(state, { loading: true });

    case actionTypes.ACCOUNT_DETAILS_FETCH:
      return updateObject(state, { loading: true });

    case actionTypes.ACCOUNT_DETAILS_FETCH_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message,
        details: action.details
      });

    case actionTypes.ACCOUNT_DETAILS_FETCH_FAILURE:
      return updateObject(state, {
        loading: false,
        details: null,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update account
const updateAccountState = {
  error: false,
  loading: false,
  message: "",
  status: "idle"
};

export const accountUpdate = (state = updateAccountState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_UPDATE_INIT:
      return updateObject(state, { loading: true });

    case actionTypes.ACCOUNT_UPDATE:
      return updateObject(state, { loading: true });

    case actionTypes.ACCOUNT_UPDATE_SUCCESS:
      return updateObject(state, {
        loading: false,
        status: "completed",
        message: action.message
      });

    case actionTypes.ACCOUNT_UPDATE_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    case actionTypes.ACCOUNT_UPDATE_RESET:
      return updateObject(state, {
        loading: false,
        error: false,
        status: "idle",
        message: ""
      });

    default: {
      return state;
    }
  }
};
