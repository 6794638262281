import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as loginActions from "../../../actions/login.action";

class Home extends Component {
  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.doesUserAlreadyLoggedIn();
    }
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  render() {
    return (
      <Aux>
        <div id="home">
          <main className={"wrapper"}>
            <div className={"home"}>
              <div className={"home__logo"}>
                <img src="img/home/logo.png" alt="eBrandBuilders" />
              </div>

              <div className={"container"}>
                <div className={"home__title"}>
                  <h1>
                    THE ONE PAGER
                    <br /> DIGITAL VERSION
                  </h1>
                </div>

                <div className={"home__intro"} />

                <div className={"home__link"}>
                  <Link to="/login">ONE PAGER LOG IN</Link>
                </div>
                {/* <div className={"home__link"}>
                  <Link to="/sign-up">NEW USER? SIGN UP</Link>
                </div> */}
              </div>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doesUserAlreadyLoggedIn: () => {
      dispatch(loginActions.loginCheck());
    }
  };
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
