import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../shared/utils/utils";

// Content story store
const contentStoryState = {
  canvas_id: null,
  history_id: null,
  content_brand: "",
  content_story: ""
};

export const contentStory = (state = contentStoryState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTENT_STORY_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          content_brand: action.details.content_brand,
          content_story: action.details.content_story
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          content_brand: action.details.content_brand,
          content_story: action.details.content_story
        });
      }

    case actionTypes.CLEAR_CONTENT_STORY_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        content_brand: "",
        content_story: ""
      });

    case actionTypes.SET_CONTENT_STORY:
      return updateObject(state, {
        content_brand: action.content_brand,
        content_story: action.content_story
      });

    default: {
      return state;
    }
  }
};

// Content calendar store
const contentCalendarState = {
  canvas_id: null,
  history_id: null,
  content_core: "",
  content_customer: "",
  content_community: "",
  content_competition: "",
  content_creatives: ""
};

export const contentCalendar = (state = contentCalendarState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTENT_CALENDAR_DATA:
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          content_core: action.details.content_core,
          content_customer: action.details.content_customer,
          content_community: action.details.content_community,
          content_competition: action.details.content_competition,
          content_creatives: action.details.content_creatives
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          content_core: action.details.content_core,
          content_customer: action.details.content_customer,
          content_community: action.details.content_community,
          content_competition: action.details.content_competition,
          content_creatives: action.details.content_creatives
        });
      }

    case actionTypes.CLEAR_CONTENT_CALENDAR_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        content_core: "",
        content_customer: "",
        content_community: "",
        content_competition: "",
        content_creatives: ""
      });

    case actionTypes.SET_CONTENT_CALENDAR:
      return updateObject(state, {
        content_core: action.content_core,
        content_customer: action.content_customer,
        content_community: action.content_community,
        content_competition: action.content_competition,
        content_creatives: action.content_creatives
      });

    default: {
      return state;
    }
  }
};

// Update content story
const updateContentStoryState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateContentStory = (state = updateContentStoryState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTENT_STORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CONTENT_STORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CONTENT_STORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CONTENT_STORY_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CONTENT_STORY_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CONTENT_STORY_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Update content calendar
const updateContentCalendarState = {
  status: "idle",
  loading: false,
  message: null,
  error: false
};

export const updateContentCalendar = (
  state = updateContentCalendarState,
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTENT_CALENDAR_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CONTENT_CALENDAR_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CONTENT_CALENDAR_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    // History
    case actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY_INIT:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: false
      });

    case actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        status: "idle",
        message: action.message
      });

    default: {
      return state;
    }
  }
};
