import * as actionTypes from "../actionTypes";

// Update content story history
export const updateContentStoryHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_HISTORY_INIT
  };
};

export const updateContentStoryHistory = (
  user_id,
  canvas_id,
  history_id,
  content_brand,
  content_story
) => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    content_brand: content_brand,
    content_story: content_story
  };
};

export const updateContentStoryHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateContentStoryHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CONTENT_STORY_HISTORY_FAILURE,
    message: message
  };
};

// Update content calendar history
export const updateContentCalendarHistoryStart = () => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY_INIT
  };
};

export const updateContentCalendarHistory = (
  user_id,
  canvas_id,
  history_id,
  content_core,
  content_customer,
  content_community,
  content_competition,
  content_creatives
) => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY,
    user_id: user_id,
    canvas_id: canvas_id,
    history_id: history_id,
    content_core: content_core,
    content_customer: content_customer,
    content_community: content_community,
    content_competition: content_competition,
    content_creatives: content_creatives
  };
};

export const updateContentCalendarHistorySuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY_SUCCESS,
    status: status,
    message: message
  };
};

export const updateContentCalendarHistoryFail = message => {
  return {
    type: actionTypes.UPDATE_CONTENT_CALENDAR_HISTORY_FAILURE,
    message: message
  };
};
