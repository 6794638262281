import * as actionTypes from "../actions/actionTypes";
import {
  updateObject,
  addItemToCanvasList,
  removeItemFromList,
  addFilterComment,
  filterComment,
  updateCanvasNameInList,
  timestampToDateTime
} from "../shared/utils/utils";

// Create canvas
const createCanvasState = {
  status: "idle",
  loading: false,
  id: null,
  message: null
};

export const createCanvas = (state = createCanvasState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CANVAS:
      return updateObject(state, {
        status: "started",
        loading: true
      });

    case actionTypes.CREATE_CANVAS_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message,
        id: action.id
      });

    case actionTypes.CREATE_CANVAS_FAIL:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    case actionTypes.RESET_CREATE_CANVAS:
      return updateObject(state, {
        status: "idle",
        loading: false,
        id: null,
        message: null
      });

    default: {
      return state;
    }
  }
};

// Canvas list
const canvasListState = {
  loading: false,
  canvas: [],
  error: false,
  message: ""
};

export const canvasList = (state = canvasListState, action) => {
  switch (action.type) {
    case actionTypes.CANVAS_LIST_FETCH_INIT:
      return updateObject(state, { loading: true, error: false });

    case actionTypes.CANVAS_LIST_FETCH:
      return updateObject(state, { loading: true });

    case actionTypes.CANVAS_LIST_FETCH_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message,
        canvas: action.canvas
      });

    case actionTypes.CANVAS_LIST_FETCH_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    case actionTypes.ADD_CANVAS_NAME_TO_LIST:
      const newCanvasList = addItemToCanvasList(action.list, action.details);
      return updateObject(state, {
        canvas: newCanvasList
      });

    case actionTypes.CANVAS_DELETE_LIST_ITEM:
      const newList = removeItemFromList(action.list, action.index);
      return updateObject(state, {
        loading: false,
        canvas: newList
      });

    case actionTypes.SET_CANVAS_LIST_NAME:
      const nameUpdated = updateCanvasNameInList(
        action.list,
        action.id,
        action.name
      );
      return updateObject(state, {
        canvas: nameUpdated
      });

    default: {
      return state;
    }
  }
};

// Add comment
const addComment = {
  status: "idle",
  loading: false,
  message: null
};

export const createComment = (state = addComment, action) => {
  switch (action.type) {
    case actionTypes.ADD_COMMENT:
      return updateObject(state, {
        status: "started",
        loading: true
      });

    case actionTypes.ADD_COMMENT_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.ADD_COMMENT_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Canvas details
const canvasDetailState = {
  loading: false,
  canvas_id: null,
  history_id: null,
  name: "",
  viewUrl: "",
  collaborateUrl: "",
  updated_at: ""
};

export const canvasDetails = (state = canvasDetailState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANVAS_DETAILS_DATA:
      let dateTime = timestampToDateTime(action.details.updatedAt);
      if (action.history_id !== null) {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details.canvas_id,
          history_id: action.history_id,
          name: action.details.name,
          viewUrl: action.details.viewUrl,
          collaborateUrl: action.details.collaborateUrl,
          updated_at: dateTime
        });
      } else {
        return updateObject(state, {
          loading: false,
          canvas_id: action.details._id,
          history_id: null,
          name: action.details.name,
          viewUrl: action.details.viewUrl,
          collaborateUrl: action.details.collaborateUrl,
          updated_at: dateTime
        });
      }

    case actionTypes.CLEAR_CANVAS_DETAILS_DATA:
      return updateObject(state, {
        loading: false,
        canvas_id: "",
        history_id: "",
        name: "",
        viewUrl: "",
        collaborateUrl: "",
        updated_at: ""
      });

    case actionTypes.SET_CANVAS_NAME:
      return updateObject(state, {
        name: action.details
      });

    case actionTypes.CLEAR_CANVAS_NAME:
      return updateObject(state, {
        name: ""
      });

    case actionTypes.SET_CURRENT_VERSION_TIME:
      let currentTime = timestampToDateTime(action.time);
      return updateObject(state, {
        updated_at: currentTime
      });

    default: {
      return state;
    }
  }
};

// Comment list
const commentlist = {
  error: null,
  loading: false,
  comment: [],
  message: ""
};

export const commentList = (state = commentlist, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMENT_INIT:
      return updateObject(state, { loading: true });

    case actionTypes.GET_COMMENT:
      return updateObject(state, { loading: true });

    case actionTypes.GET_COMMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message,
        comment: action.details
      });

    case actionTypes.GET_COLLABORATE_COMMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message,
        comment: action.details
      });

    case actionTypes.GET_COMMENT_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Filtered comments
const commentfilter = {
  error: null,
  loading: false,
  comment: []
};

export const filterCommentList = (state = commentfilter, action) => {
  switch (action.type) {
    case actionTypes.FILTER_COMMENT:
      const filterList = filterComment(
        action.canvas_id,
        action.list,
        action.section
      );
      return updateObject(state, {
        loading: false,
        comment: filterList
      });

    case actionTypes.UPDATE_FILTER_COMMENT:
      const updatedList = addFilterComment(action.list, action.comment);
      return updateObject(state, {
        loading: false,
        comment: updatedList
      });

    default: {
      return state;
    }
  }
};

// Delete canvas
const deleteState = {
  error: false,
  loading: false,
  message: "",
  status: "idle"
};

export const canvasDelete = (state = deleteState, action) => {
  switch (action.type) {
    case actionTypes.CANVAS_DELETE_INIT:
      return updateObject(state, {
        loading: true,
        error: false,
        status: "started"
      });

    case actionTypes.CANVAS_DELETE_SUCCESS:
      return updateObject(state, {
        loading: false,
        message: action.message,
        status: "deleted",
        error: false
      });

    case actionTypes.CANVAS_DELETE_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message,
        status: "failed"
      });

    case actionTypes.CANVAS_DELETE_RESET:
      return updateObject(state, {
        loading: false,
        error: false,
        message: "",
        status: "idle"
      });

    default: {
      return state;
    }
  }
};

// Send canvas
const sendCanvasState = {
  status: "idle",
  loading: false,
  message: null,
  error: ""
};

export const sendCanvas = (state = sendCanvasState, action) => {
  switch (action.type) {
    case actionTypes.SEND_CANVAS:
      return updateObject(state, {
        status: "started",
        loading: true,
        error: ""
      });

    case actionTypes.SEND_CANVAS_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.SEND_CANVAS_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Notepad store
const notepadState = {
  canvas_id: null,
  notes: null
};

export const notepad = (state = notepadState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTE_DATA:
      return updateObject(state, {
        canvas_id: action.details._id,
        notes: action.details.notes
      });

    case actionTypes.SET_NOTE:
      return updateObject(state, {
        notes: action.details
      });

    default: {
      return state;
    }
  }
};

// Note/notepad
const updateNotepadState = {
  status: "idle",
  loading: false,
  canvas_id: null,
  note: null,
  message: null
};

export const updateNotepad = (state = updateNotepadState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NOTE:
      return updateObject(state, {
        status: "started",
        loading: true
      });

    case actionTypes.UPDATE_NOTE_SUCCESS:
      return updateObject(state, {
        status: "completed",
        loading: false,
        message: action.message
      });

    case actionTypes.UPDATE_NOTE_FAILURE:
      return updateObject(state, {
        loading: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};

// Canvas copy invitation
const canvasCopyInvitationState = {
  status: null,
  copied: null,
  loading: false,
  message: null,
  error: false
};

export const canvasCopyInvitation = (
  state = canvasCopyInvitationState,
  action
) => {
  switch (action.type) {
    case actionTypes.CANVAS_COPY_INVITATION:
      return updateObject(state, {
        loading: true,
        error: false,
        copied: null
      });

    case actionTypes.CANVAS_COPY_INVITATION_SUCCESS:
      return updateObject(state, {
        status: action.status,
        loading: false,
        copied: "copied",
        message: action.message
      });

    case actionTypes.CANVAS_COPY_INVITATION_FAILURE:
      return updateObject(state, {
        loading: false,
        status: false,
        error: true,
        message: action.message
      });

    default: {
      return state;
    }
  }
};
