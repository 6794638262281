import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import * as channelText from "../../../actions/canvas.channelText.action";
import * as historyActions from "../../../actions/history/channelText.history.action";
import * as canvasActions from "../../../actions/canvas.action";
import { getUserIdFromToken } from "../../../shared/utils/utils";
import Comment from "../Comment/Comment";
import SingleInput from "../../UI/SingleInput/SingleInput";
import $ from "jquery";

class ChannelText extends Component {
  constructor(props) {
    super(props);
    this.contentGroupRef = React.createRef();
    this.contentTextAreaRef = React.createRef();
    this.contentNavRef = React.createRef();
    this.checkmarkRef = React.createRef();
    this.contentNavButtonRef = React.createRef();
    this.spinnerRef = React.createRef();
    this.commentBtnRef = React.createRef();
  }

  state = {
    user_id: getUserIdFromToken(),
    canvas_id: null,
    history_id: null,
    field: null,
    value: this.props.value,
    text1: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
    alertLength: 0,
    showAlert: false,
    reverseAlert: false,
    focusOutCounter: 0,
    valueSaved: false,
    displayComment: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.field === "channel_text_owned") {
      if (nextProps.channelTextOwned !== this.props.channelTextOwned) {
        this.setState({
          canvas_id: nextProps.channelTextOwned.canvas_id,
          history_id: nextProps.channelTextOwned.history_id,
          text1: nextProps.channelTextOwned.channel_text_owned_text1,
          text2: nextProps.channelTextOwned.channel_text_owned_text2,
          text3: nextProps.channelTextOwned.channel_text_owned_text3,
          text4: nextProps.channelTextOwned.channel_text_owned_text4,
          text5: nextProps.channelTextOwned.channel_text_owned_text5
        });
      }
    } else if (nextProps.field === "channel_text_shared") {
      if (nextProps.channelTextShared !== this.props.channelTextShared)
        this.setState({
          canvas_id: nextProps.channelTextOwned.canvas_id,
          history_id: nextProps.channelTextOwned.history_id,
          text1: nextProps.channelTextShared.channel_text_shared_text1,
          text2: nextProps.channelTextShared.channel_text_shared_text2,
          text3: nextProps.channelTextShared.channel_text_shared_text3,
          text4: nextProps.channelTextShared.channel_text_shared_text4,
          text5: nextProps.channelTextShared.channel_text_shared_text5
        });
    } else if (nextProps.field === "channel_text_paid") {
      if (nextProps.channelTextPaid !== this.props.channelTextPaid)
        this.setState({
          canvas_id: nextProps.channelTextOwned.canvas_id,
          history_id: nextProps.channelTextOwned.history_id,
          text1: nextProps.channelTextPaid.channel_text_paid_text1,
          text2: nextProps.channelTextPaid.channel_text_paid_text2,
          text3: nextProps.channelTextPaid.channel_text_paid_text3,
          text4: nextProps.channelTextPaid.channel_text_paid_text4,
          text5: nextProps.channelTextPaid.channel_text_paid_text5
        });
    }

    if (
      nextProps.UpdateChannelTextOwned.status !==
      this.props.UpdateChannelTextOwned.status
    ) {
      if (
        nextProps.UpdateChannelTextOwned.status === "completed" &&
        nextProps.field === "channel_text_owned"
      ) {
        this.showSuccess();
      }
    } else if (
      nextProps.UpdateChannelTextShared.status !==
      this.props.UpdateChannelTextShared.status
    ) {
      if (
        nextProps.UpdateChannelTextShared.status === "completed" &&
        nextProps.field === "channel_text_shared"
      ) {
        this.showSuccess();
      }
    } else if (
      nextProps.UpdateChannelTextPaid.status !==
      this.props.UpdateChannelTextPaid.status
    ) {
      if (
        nextProps.UpdateChannelTextPaid.status === "completed" &&
        nextProps.field === "channel_text_paid"
      ) {
        this.showSuccess();
      }
    }
  }

  contentGroupFocus = () => {
    this.contentTextAreaRef.current.classList.add("cut");
    this.contentNavRef.current.classList.add("show");
  };

  contentGroupBlur = event => {
    this.contentTextAreaRef.current.classList.remove("cut");
    let ownCounter = 0;
    let sharedCounter = 0;
    let paidCounter = 0;

    for (let i = 1; i <= 5; i++) {
      if (
        this.props.field === "channel_text_owned" &&
        this.state["text" + i] !==
          this.props.channelTextOwned["channel_text_owned_text" + i]
      ) {
        ownCounter = Number(ownCounter) + Number(1);
      } else if (
        this.props.field === "channel_text_shared" &&
        this.state["text" + i] !==
          this.props.channelTextShared["channel_text_shared_text" + i]
      ) {
        sharedCounter = Number(sharedCounter) + Number(1);
      } else if (
        this.props.field === "channel_text_paid" &&
        this.state["text" + i] !==
          this.props.channelTextPaid["channel_text_paid_text" + i]
      ) {
        paidCounter = Number(paidCounter) + Number(1);
      }
    }

    if (this.props.field === "channel_text_owned" && ownCounter === 0) {
      this.hideButtonBox();
    } else if (
      this.props.field === "channel_text_shared" &&
      sharedCounter === 0
    ) {
      this.hideButtonBox();
    } else if (this.props.field === "channel_text_paid" && paidCounter === 0) {
      this.hideButtonBox();
    }

    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        focusOutCounter: Number(this.state.focusOutCounter) + Number(1)
      });

      setTimeout(
        function() {
          if (
            this.state.focusOutCounter === 1 &&
            this.state.valueSaved !== true
          ) {
            if (this.props.field === "channel_text_owned" && ownCounter !== 0) {
              this.save();
            } else if (
              this.props.field === "channel_text_shared" &&
              sharedCounter !== 0
            ) {
              this.save();
            } else if (
              this.props.field === "channel_text_paid" &&
              paidCounter !== 0
            ) {
              this.save();
            }
          }
        }.bind(this),
        500
      );
    } else {
    }
  };

  // Hide save & cancel button
  hideButtons = () => {
    this.commentBtnRef.current.classList.add("hide_cs_btn");
    this.contentNavButtonRef.current.classList.add("hide_cs_btn");
  };

  // Function to show progress bubble
  showProgress = () => {
    this.spinnerRef.current.classList.remove("spinner_hidden");
  };

  showSuccess = () => {
    this.spinnerRef.current.classList.add("spinner_hidden");
    this.checkmarkRef.current.classList.remove("checkmark__hidden");

    setTimeout(
      function() {
        this.hideCheckmark();
        this.hideButtonBox();
        $("input").attr("disabled", false);
        $("textarea").attr("disabled", false);
      }.bind(this),
      2000
    );
  };

  hideCheckmark = () => {
    this.checkmarkRef.current.classList.add("checkmark__hidden");
  };

  hideButtonBox = () => {
    this.contentNavRef.current.classList.remove("show");
    setTimeout(() => {
      this.commentBtnRef.current.classList.remove("hide_cs_btn");
    }, 1000);
  };

  changeHandler = (field, event) => {
    const value = event.target.value;
    let ownIndex = field.substr(23, 1);
    let sharedIndex = field.substr(24, 1);
    let paidIndex = field.substr(22, 1);

    for (let i = 0; i < 5; i++) {
      if (field === "channel_text_owned_text" + ownIndex) {
        let otext = "text" + ownIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_text_shared_text" + sharedIndex) {
        let otext = "text" + sharedIndex;
        this.setState({ [otext]: value });
      } else if (field === "channel_text_paid_text" + paidIndex) {
        let otext = "text" + paidIndex;
        this.setState({ [otext]: value });
      }
    }

    this.setState({ valueSaved: false, focusOutCounter: 0 });

    for (let i = 0; i < 5; i++) {
      if (field === "channel_text_owned_text" + ownIndex) {
        if (
          value !==
          this.props.channelTextOwned["channel_text_owned_text" + ownIndex]
        ) {
          this.removeClass("hide_cs_btn");
          let counter = Number(this.state.ownDiffCounter) + Number(1);
          this.setState({ ownDiffCounter: counter });
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_text_shared_text" + sharedIndex) {
        if (
          value !==
          this.props.channelTextShared["channel_text_shared_text" + sharedIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      } else if (field === "channel_text_paid_text" + paidIndex) {
        if (
          value !==
          this.props.channelTextPaid["channel_text_paid_text" + paidIndex]
        ) {
          this.removeClass("hide_cs_btn");
          break;
        } else {
          this.addClass("hide_cs_btn");
        }
      }
    }
  };

  // Remove "hide_cs_btn" class
  removeClass = cssClass => {
    this.contentNavButtonRef.current.classList.remove(cssClass);
  };

  // Add "hide_cs_btn" class
  addClass = cssClass => {
    this.contentNavButtonRef.current.classList.add(cssClass);
  };

  cancel = field => {
    this.setState({
      showAlert: false,
      alertLength: 0,
      valueSaved: true,
      reverseAlert: !this.state.reverseAlert
    });

    if (field === "channel_text_owned") {
      this.setState({
        text1: this.props.channelTextOwned.channel_text_owned_text1,
        text2: this.props.channelTextOwned.channel_text_owned_text2,
        text3: this.props.channelTextOwned.channel_text_owned_text3,
        text4: this.props.channelTextOwned.channel_text_owned_text4,
        text5: this.props.channelTextOwned.channel_text_owned_text5
      });
    } else if (field === "channel_text_shared") {
      this.setState({
        text1: this.props.channelTextShared.channel_text_shared_text1,
        text2: this.props.channelTextShared.channel_text_shared_text2,
        text3: this.props.channelTextShared.channel_text_shared_text3,
        text4: this.props.channelTextShared.channel_text_shared_text4,
        text5: this.props.channelTextShared.channel_text_shared_text5
      });
    } else if (field === "channel_text_paid") {
      this.setState({
        text1: this.props.channelTextPaid.channel_text_paid_text1,
        text2: this.props.channelTextPaid.channel_text_paid_text2,
        text3: this.props.channelTextPaid.channel_text_paid_text3,
        text4: this.props.channelTextPaid.channel_text_paid_text4,
        text5: this.props.channelTextPaid.channel_text_paid_text5
      });
    }
    this.hideButtons();
    this.hideButtonBox();
  };

  // Save function
  save = field => {
    if (
      this.state.canvas_id !== null &&
      this.props.canvasView.private === true
    ) {
      setTimeout(
        function() {
          this.setState({ valueSaved: true });

          $("input").attr("disabled", true);
          $("textarea").attr("disabled", true);
          this.hideButtons();
          this.showProgress();
          this.setState({
            showAlert: false,
            reverseAlert: !this.state.reverseAlert,
            alertLength: 0
          });
          if (this.props.field === "channel_text_owned") {
            if (
              this.props.channelTextOwned.canvas_id !== null &&
              this.props.channelTextOwned.canvas_id !== ""
            ) {
              if (this.state.history_id !== null) {
                this.props.updateChannelTextOwnedHistory(
                  this.state.user_id,
                  this.state.canvas_id,
                  this.state.history_id,
                  this.state.text1,
                  this.state.text2,
                  this.state.text3,
                  this.state.text4,
                  this.state.text5
                );
              } else {
                this.props.updateChannelTextOwned(
                  this.state.user_id,
                  this.state.canvas_id,
                  this.state.text1,
                  this.state.text2,
                  this.state.text3,
                  this.state.text4,
                  this.state.text5
                );
              }
            }
          } else if (this.props.field === "channel_text_shared") {
            if (this.state.history_id !== null) {
              this.props.updateChannelTextSharedHistory(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                this.state.text1,
                this.state.text2,
                this.state.text3,
                this.state.text4,
                this.state.text5
              );
            } else {
              this.props.updateChannelTextShared(
                this.state.user_id,
                this.state.canvas_id,
                this.state.text1,
                this.state.text2,
                this.state.text3,
                this.state.text4,
                this.state.text5
              );
            }
          } else if (this.props.field === "channel_text_paid") {
            if (this.state.history_id !== null) {
              this.props.updateChannelTextPaidHistory(
                this.state.user_id,
                this.state.canvas_id,
                this.state.history_id,
                this.state.text1,
                this.state.text2,
                this.state.text3,
                this.state.text4,
                this.state.text5
              );
            } else {
              this.props.updateChannelTextPaid(
                this.state.user_id,
                this.state.canvas_id,
                this.state.text1,
                this.state.text2,
                this.state.text3,
                this.state.text4,
                this.state.text5
              );
            }
          }
          this.setState({ focusOutCounter: 0 });
        }.bind(this),
        1000
      );
    }
  };

  // Show comment
  showComment = section => {
    if (this.state.canvas_id !== null) {
      this.setState({ displayComment: true });

      this.props.filterComment(
        this.state.canvas_id,
        this.props.commentList.comment,
        section
      );
    }
  };

  hideComment = () => {
    this.setState({ displayComment: false });
    this.hideButtonBox();
  };

  render() {
    return (
      <Aux>
        <div className={"content__group-wrapper group-third"}>
          <div
            className={"content__group"}
            onBlur={event => this.contentGroupBlur(event)}
            onFocus={this.contentGroupFocus}
            ref={this.contentGroupRef}
          >
            <div className={"content__subtitle"}>
              <h5>{this.props.title}</h5>
            </div>

            <div
              className={"content__textarea auto-scroll"}
              ref={this.contentTextAreaRef}
            >
              <div className={"content__line"}>
                <SingleInput
                  key={this.props.fields[0]}
                  id={this.props.fields[0]}
                  field={this.props.fields[0]}
                  value={this.state.text1}
                  onUpdate={this.changeHandler}
                  numberValidation={this.props.numberValidation}
                  disabled={this.state.disabled}
                  maxLimit={25}
                  alertLimit={5}
                  showAlert={this.state.showAlert}
                  reverseAlert={this.state.reverseAlert}
                  onClick={this.clickHandler}
                />
              </div>

              <div className={"content__line"}>
                <SingleInput
                  key={this.props.fields[1]}
                  id={this.props.fields[1]}
                  field={this.props.fields[1]}
                  value={this.state.text2}
                  onUpdate={this.changeHandler}
                  numberValidation={this.props.numberValidation}
                  disabled={this.state.disabled}
                  maxLimit={25}
                  alertLimit={5}
                  showAlert={this.state.showAlert}
                  reverseAlert={this.state.reverseAlert}
                  onClick={this.clickHandler}
                />
              </div>

              <div className={"content__line"}>
                <SingleInput
                  key={this.props.fields[2]}
                  id={this.props.fields[2]}
                  field={this.props.fields[2]}
                  value={this.state.text3}
                  onUpdate={this.changeHandler}
                  numberValidation={this.props.numberValidation}
                  disabled={this.state.disabled}
                  maxLimit={25}
                  alertLimit={5}
                  showAlert={this.state.showAlert}
                  reverseAlert={this.state.reverseAlert}
                  onClick={this.clickHandler}
                />
              </div>

              <div className={"content__line"}>
                <SingleInput
                  key={this.props.fields[3]}
                  id={this.props.fields[3]}
                  field={this.props.fields[3]}
                  value={this.state.text4}
                  onUpdate={this.changeHandler}
                  numberValidation={this.props.numberValidation}
                  disabled={this.state.disabled}
                  maxLimit={25}
                  alertLimit={5}
                  showAlert={this.state.showAlert}
                  reverseAlert={this.state.reverseAlert}
                  onClick={this.clickHandler}
                />
              </div>

              <div className={"content__line"}>
                <SingleInput
                  key={this.props.fields[4]}
                  id={this.props.fields[4]}
                  field={this.props.fields[4]}
                  value={this.state.text5}
                  onUpdate={this.changeHandler}
                  numberValidation={this.props.numberValidation}
                  disabled={this.state.disabled}
                  maxLimit={25}
                  alertLimit={5}
                  showAlert={this.state.showAlert}
                  reverseAlert={this.state.reverseAlert}
                  onClick={this.clickHandler}
                />
              </div>
            </div>

            {this.props.showCommentButton === true ? (
              <div className={"content__nav"} ref={this.contentNavRef}>
                <div
                  className={"content__nav-comment"}
                  ref={this.commentBtnRef}
                >
                  <button
                    className="open-comment"
                    onClick={() => this.showComment(this.props.commentSection)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1000 1000"
                    >
                      <path d="M873.3,145.5c32.8,0,60.2,26.4,61.2,59.1v456.5c0,15.2-6.1,29.8-17.2,40.9c-11.1,11.2-25.7,17.3-41.1,17.3 H769.5c-19.2,0-37.4,8.8-49.4,23.9L636.3,849L546,741.8c-12-14.2-29.6-22.4-48.2-22.4h-374c-15.2,0-29.8-6.1-40.9-17.2 c-11.2-11.1-17.3-25.7-17.3-41.1V203.8c0-15.2,6.1-29.8,17.2-40.9c11.1-11.2,25.7-17.3,41.1-17.3H873.3 M873.3,82.5H123.8 C56.6,82.5,2.5,138.1,2.5,203.8v457.3c0,67.2,55.5,121.3,121.3,121.3h374l100.8,119.8c10.2,10.2,23.7,15.3,37.4,15.3 c13.7,0,27.6-5.1,38.5-15.3l95-119.8h106.6c67.2,0,121.3-55.5,121.3-121.3V203.8C996,136.6,940.5,82.5,873.3,82.5L873.3,82.5z" />
                    </svg>
                  </button>
                </div>
                {/* Progress bubbles */}
                <div className={"spinner spinner_hidden"} ref={this.spinnerRef}>
                  <div className={"bounce1"} />
                  <div className={"bounce2"} />
                  <div className={"bounce3"} />
                </div>
                {/* Checkmark - Success */}
                <div
                  className={"checkmark checkmark__hidden"}
                  ref={this.checkmarkRef}
                >
                  <svg
                    className={"checkmark-svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className={"checkmark__circle"}
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className={"checkmark__check"}
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
                <div
                  className={"content__nav-buttons hide_cs_btn"}
                  ref={this.contentNavButtonRef}
                >
                  <button
                    type="button"
                    onClick={() => this.cancel(this.props.field)}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    onClick={() => this.save(this.props.field)}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}
          </div>

          <Comment
            id={this.props.commentSection}
            canvas_id={this.state.canvas_id}
            section={this.props.commentSection}
            position={this.props.commentPosition}
            hideComment={this.hideComment}
            displayComment={this.state.displayComment}
          />
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateChannelTextOwned: (
      user_id,
      canvas_id,
      channel_text_owned_text1,
      channel_text_owned_text2,
      channel_text_owned_text3,
      channel_text_owned_text4,
      channel_text_owned_text5
    ) => {
      dispatch(
        channelText.updateChannelTextOwned(
          user_id,
          canvas_id,
          channel_text_owned_text1,
          channel_text_owned_text2,
          channel_text_owned_text3,
          channel_text_owned_text4,
          channel_text_owned_text5
        )
      );
    },
    updateChannelTextOwnedHistory: (
      user_id,
      canvas_id,
      history_id,
      channel_text_owned_text1,
      channel_text_owned_text2,
      channel_text_owned_text3,
      channel_text_owned_text4,
      channel_text_owned_text5
    ) => {
      dispatch(
        historyActions.updateChannelTextOwnedHistory(
          user_id,
          canvas_id,
          history_id,
          channel_text_owned_text1,
          channel_text_owned_text2,
          channel_text_owned_text3,
          channel_text_owned_text4,
          channel_text_owned_text5
        )
      );
    },
    updateChannelTextShared: (
      user_id,
      canvas_id,
      channel_text_shared_text1,
      channel_text_shared_text2,
      channel_text_shared_text3,
      channel_text_shared_text4,
      channel_text_shared_text5
    ) => {
      dispatch(
        channelText.updateChannelTextShared(
          user_id,
          canvas_id,
          channel_text_shared_text1,
          channel_text_shared_text2,
          channel_text_shared_text3,
          channel_text_shared_text4,
          channel_text_shared_text5
        )
      );
    },
    updateChannelTextSharedHistory: (
      user_id,
      canvas_id,
      history_id,
      channel_text_shared_text1,
      channel_text_shared_text2,
      channel_text_shared_text3,
      channel_text_shared_text4,
      channel_text_shared_text5
    ) => {
      dispatch(
        historyActions.updateChannelTextSharedHistory(
          user_id,
          canvas_id,
          history_id,
          channel_text_shared_text1,
          channel_text_shared_text2,
          channel_text_shared_text3,
          channel_text_shared_text4,
          channel_text_shared_text5
        )
      );
    },
    updateChannelTextPaid: (
      user_id,
      canvas_id,
      channel_text_paid_text1,
      channel_text_paid_text2,
      channel_text_paid_text3,
      channel_text_paid_text4,
      channel_text_paid_text5
    ) => {
      dispatch(
        channelText.updateChannelTextPaid(
          user_id,
          canvas_id,
          channel_text_paid_text1,
          channel_text_paid_text2,
          channel_text_paid_text3,
          channel_text_paid_text4,
          channel_text_paid_text5
        )
      );
    },
    updateChannelTextPaidHistory: (
      user_id,
      canvas_id,
      history_id,
      channel_text_paid_text1,
      channel_text_paid_text2,
      channel_text_paid_text3,
      channel_text_paid_text4,
      channel_text_paid_text5
    ) => {
      dispatch(
        historyActions.updateChannelTextPaidHistory(
          user_id,
          canvas_id,
          history_id,
          channel_text_paid_text1,
          channel_text_paid_text2,
          channel_text_paid_text3,
          channel_text_paid_text4,
          channel_text_paid_text5
        )
      );
    },
    filterComment: (canvas_id, list, section) => {
      dispatch(canvasActions.filterComment(canvas_id, list, section));
    }
  };
};

const mapStateToProps = state => {
  return {
    commentList: state.commentList,
    channelTextOwned: state.channelTextOwned,
    channelTextShared: state.channelTextShared,
    channelTextPaid: state.channelTextPaid,
    UpdateChannelTextOwned: state.updateChannelTextOwned,
    UpdateChannelTextShared: state.updateChannelTextShared,
    UpdateChannelTextPaid: state.updateChannelTextPaid,
    canvasView: state.canvasView
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelText);
