import React, { Component } from "react";
import { Link } from "react-router-dom";
import Aux from "../../../hoc/Auxilary/Auxilary";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as actions from "../../../actions/login.action";
import SimpleAlert from "../../UI/SimpleAlert/SimpleAlert";

class Login extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  state = {
    message: "",
    error: "",
    show: false,
    disabled: false
  };

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn === false && nextProps.error === true) {
      this.setState({
        show: true,
        error: true,
        message: nextProps.message
      });
    }
  }

  close = () => {
    this.setState({ show: false, error: false, message: "" });
  };

  showPassword = () => {
    let type;
    let childrenIndex = 1;

    if (this.state.show === true) {
      childrenIndex = 2;
    } else {
      childrenIndex = 1;
    }

    type = this.containerRef.current.children[childrenIndex][1].type;

    if (type === "password") {
      this.containerRef.current.children[childrenIndex][1].type = "text";
    } else {
      this.containerRef.current.children[childrenIndex][1].type = "password";
    }
  };

  render() {
    const loginSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      password: Yup.string().required("Password is required")
    });

    return (
      <Aux>
        <div id="access">
          <main className={"wrapper"}>
            <div className={"access"}>
              <div className={"container"} ref={this.containerRef}>
                <div className={"access__logo"}>
                  <Link to="/">
                    <img src="../img/access/logo.png" alt="eBrandBuilders" />
                  </Link>
                </div>
                {this.state.show === true ? (
                  <form className={"access__form"}>
                    <SimpleAlert
                      show={this.state.show}
                      close={this.close}
                      className={"custom__alert"}
                      message={this.state.message}
                    />
                  </form>
                ) : null}
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={loginSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    this.props.onLogin(values.email, values.password);
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className={"access__form"}>
                      <div className={"access__group"}>
                        <Field
                          type="email"
                          name="email"
                          placeholder="name@company.com"
                        />
                        <ErrorMessage
                          className="validation__error"
                          name="email"
                          component="div"
                        />
                      </div>

                      <div className={"access__group"}>
                        <div className={"access__group-wrapper"}>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                          />
                          <label
                            htmlFor="password"
                            id="show-pas"
                            onClick={this.showPassword}
                          />
                        </div>

                        <ErrorMessage
                          className="validation__error"
                          name="password"
                          component="div"
                        />
                      </div>

                      <div className={"access__group"}>
                        <Link to="/forgot-password">forgot your password?</Link>
                      </div>

                      <div className={"access__group"}>
                        <input
                          type="submit"
                          value="Log Me In"
                          disabled={isSubmitting}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (email, password) => {
      dispatch(actions.login(email, password));
    }
  };
};

const mapStateToProps = state => {
  return {
    loading: state.login.loading,
    error: state.login.error,
    isLoggedIn: state.login.isLoggedIn,
    message: state.login.message
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
