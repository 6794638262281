import * as actionTypes from "./actionTypes";

// Create canvas
export const createCanvasStart = () => {
  return {
    type: actionTypes.CREATE_CANVAS_INIT
  };
};

export const createCanvas = (user_id, name, list) => {
  return {
    type: actionTypes.CREATE_CANVAS,
    user_id: user_id,
    name: name,
    list: list
  };
};

export const createCanvasSuccess = (status, message, id) => {
  return {
    type: actionTypes.CREATE_CANVAS_SUCCESS,
    status: status,
    message: message,
    id: id
  };
};

export const createCanvasFail = message => {
  return {
    type: actionTypes.CREATE_CANVAS_FAIL,
    message: message
  };
};

export const clearMessage = () => {
  return {
    type: actionTypes.CLEAR_CREATE_CANVAS_MESSAGE
  };
};

export const resetcreateCanvas = () => {
  return {
    type: actionTypes.RESET_CREATE_CANVAS
  };
};

// Canvas list (sidemenu)
export const canvasListFetchInitiated = () => {
  return {
    type: actionTypes.CANVAS_LIST_FETCH_INIT
  };
};

export const canvasListFetch = user_id => {
  return {
    type: actionTypes.CANVAS_LIST_FETCH,
    user_id: user_id
  };
};

export const canvasListFetchSuccess = (message, canvas) => {
  return {
    type: actionTypes.CANVAS_LIST_FETCH_SUCCESS,
    message: message,
    canvas: canvas
  };
};

export const canvasListFetchFailure = error => {
  return {
    type: actionTypes.CANVAS_LIST_FETCH_FAILURE,
    error: error
  };
};

// Canvas details
export const canvasDetailsFetchInitiated = () => {
  return {
    type: actionTypes.CANVAS_DETAILS_INIT
  };
};

export const canvasDetailsFetch = (user_id, canvas_id) => {
  return {
    type: actionTypes.CANVAS_DETAILS_FETCH,
    user_id: user_id,
    canvas_id: canvas_id
  };
};

export const canvasDetailsFetchFailure = message => {
  return {
    type: actionTypes.CANVAS_DETAILS_FETCH_FAILURE,
    message: message
  };
};

// Add canvas to the canvas list(sidemenu)
export const addCanvasToList = (list, details) => {
  return {
    type: actionTypes.ADD_CANVAS_NAME_TO_LIST,
    list: list,
    details: details
  };
};

// Set canvas basic details
export const setCanvasDetailsData = (history_id, details) => {
  return {
    type: actionTypes.SET_CANVAS_DETAILS_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear canvas details
export const clearCanvasDetailsData = () => {
  return {
    type: actionTypes.CLEAR_CANVAS_DETAILS_DATA
  };
};

// Set core data
export const setCoreData = (history_id, details) => {
  return {
    type: actionTypes.SET_CORE_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear core data
export const clearCoreData = () => {
  return {
    type: actionTypes.CLEAR_CORE_DATA
  };
};

// Set customer data
export const setCustomerData = (history_id, details) => {
  return {
    type: actionTypes.SET_CUSTOMER_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear customer data
export const clearCustomerData = () => {
  return {
    type: actionTypes.CLEAR_CUSTOMER_DATA
  };
};

// Set community data
export const setCommunityData = (history_id, details) => {
  return {
    type: actionTypes.SET_COMMUNITY_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear community data
export const clearCommunityData = () => {
  return {
    type: actionTypes.CLEAR_COMMUNITY_DATA
  };
};

// Set competition data
export const setCompetitionData = (history_id, details) => {
  return {
    type: actionTypes.SET_COMPETITION_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear competition data
export const clearCompetitionData = () => {
  return {
    type: actionTypes.CLEAR_COMPETITION_DATA
  };
};

// Set content story data
export const setContentStoryData = (history_id, details) => {
  return {
    type: actionTypes.SET_CONTENT_STORY_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear content story data
export const clearContentStoryData = () => {
  return {
    type: actionTypes.CLEAR_CONTENT_STORY_DATA
  };
};

// Set content calendar data
export const setContentCalendarData = (history_id, details) => {
  return {
    type: actionTypes.SET_CONTENT_CALENDAR_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear content calendar data
export const clearContentCalendarData = () => {
  return {
    type: actionTypes.CLEAR_CONTENT_CALENDAR_DATA
  };
};

// Set creatives data
export const setCreativesData = (history_id, details) => {
  return {
    type: actionTypes.SET_CREATIVES_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear creatives data
export const clearCreativesData = () => {
  return {
    type: actionTypes.CLEAR_CREATIVES_DATA
  };
};

// Set channel text owned
export const setChannelTextOwnedData = (history_id, details) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_OWNED_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear channel text owned data
export const clearChannelTextOwnedData = () => {
  return {
    type: actionTypes.CLEAR_CHANNEL_TEXT_OWNED_DATA
  };
};

// Set channel text shared
export const setChannelTextSharedData = (history_id, details) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_SHARED_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear channel text shared data
export const clearChannelTextSharedData = () => {
  return {
    type: actionTypes.CLEAR_CHANNEL_TEXT_SHARED_DATA
  };
};

// Set channel text paid
export const setChannelTextPaidData = (history_id, details) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_PAID_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear channel text paid data
export const clearChannelTextPaidData = () => {
  return {
    type: actionTypes.CLEAR_CHANNEL_TEXT_PAID_DATA
  };
};

// Set channel text value
export const setChannelTextValueData = (history_id, details) => {
  return {
    type: actionTypes.SET_CHANNEL_TEXT_VALUE_DATA,
    history_id: history_id,
    details: details
  };
};

// Clear channel text value data
export const clearChannelTextValueData = () => {
  return {
    type: actionTypes.CLEAR_CHANNEL_TEXT_VALUE_DATA
  };
};

// Set note data
export const setNoteData = details => {
  return {
    type: actionTypes.SET_NOTE_DATA,
    details: details
  };
};

// Set current version time
export const setCurrentVersionTime = time => {
  return {
    type: actionTypes.SET_CURRENT_VERSION_TIME,
    time: time
  };
};

// Add comment
export const addCommentStart = () => {
  return {
    type: actionTypes.ADD_COMMENT_INIT
  };
};

export const addComment = (user_id, canvas_id, list, section, comment) => {
  return {
    type: actionTypes.ADD_COMMENT,
    user_id: user_id,
    canvas_id: canvas_id,
    list: list,
    section: section,
    comment: comment
  };
};

export const addCommentSuccess = (status, message) => {
  return {
    type: actionTypes.ADD_COMMENT_SUCCESS,
    status: status,
    message: message
  };
};

export const addCommentFail = message => {
  return {
    type: actionTypes.ADD_COMMENT_FAILURE,
    message: message
  };
};

export const updateFilterComment = (list, comment) => {
  return {
    type: actionTypes.UPDATE_FILTER_COMMENT,
    list: list,
    comment: comment
  };
};

// Get comments
export const commentsFetchInitiated = () => {
  return {
    type: actionTypes.GET_COMMENT_INIT
  };
};

export const commentsFetch = (user_id, canvas_id) => {
  return {
    type: actionTypes.GET_COMMENT,
    user_id: user_id,
    canvas_id: canvas_id
  };
};

export const commentsFetchSuccess = (message, details) => {
  return {
    type: actionTypes.GET_COMMENT_SUCCESS,
    message: message,
    details: details
  };
};

export const commentsFetchFailure = message => {
  return {
    type: actionTypes.GET_COMMENT_FAILURE,
    message: message
  };
};

// Get collaborate comments
export const collaborateCommentsFetchInitiated = () => {
  return {
    type: actionTypes.GET_COLLABORATE_COMMENT_INIT
  };
};

export const collaborateCommentsFetch = token => {
  return {
    type: actionTypes.GET_COLLABORATE_COMMENT,
    token: token
  };
};

export const collaborateCommentsFetchSuccess = (message, details) => {
  return {
    type: actionTypes.GET_COLLABORATE_COMMENT_SUCCESS,
    message: message,
    details: details
  };
};

export const collaborateCommentsFetchFailure = message => {
  return {
    type: actionTypes.GET_COLLABORATE_COMMENT_FAILURE,
    message: message
  };
};

// Filter comment per sections
export const filterComment = (canvas_id, list, section) => {
  return {
    type: actionTypes.FILTER_COMMENT,
    canvas_id: canvas_id,
    list: list,
    section: section
  };
};

// Canvas delete
export const canvasDeleteInitiated = () => {
  return {
    type: actionTypes.CANVAS_DELETE_INIT
  };
};

export const canvasDelete = (id, list, index) => {
  return {
    type: actionTypes.CANVAS_DELETE,
    id: id,
    list: list,
    index: index
  };
};

export const canvasDeleteSuccess = message => {
  return {
    type: actionTypes.CANVAS_DELETE_SUCCESS,
    message: message
  };
};

export const canvasDeleteFailure = message => {
  return {
    type: actionTypes.CANVAS_DELETE_FAILURE,
    message: message
  };
};

export const canvasDeleteReset = () => {
  return {
    type: actionTypes.CANVAS_DELETE_RESET
  };
};

export const canvasDeleteListItem = (list, index) => {
  return {
    type: actionTypes.CANVAS_DELETE_LIST_ITEM,
    list: list,
    index: index
  };
};

// Canvas name update
export const canvasNameUpdateStart = () => {
  return {
    type: actionTypes.CANVAS_NAME_UPDATE_INIT
  };
};

export const canvasNameUpdate = (user_id, canvas_id, list, name) => {
  return {
    type: actionTypes.CANVAS_NAME_UPDATE,
    user_id: user_id,
    canvas_id: canvas_id,
    list: list,
    name: name
  };
};

export const canvasNameUpdateSuccess = message => {
  return {
    type: actionTypes.CANVAS_NAME_UPDATE_SUCCESS,
    message: message
  };
};

export const canvasNameUpdateFailure = message => {
  return {
    type: actionTypes.CANVAS_NAME_UPDATE_FAILURE,
    message: message
  };
};

// Set canvas name in store
export const setCanvasName = details => {
  return {
    type: actionTypes.SET_CANVAS_NAME,
    details: details
  };
};

// Clear canvas name
export const clearCanvasName = () => {
  return {
    type: actionTypes.CLEAR_CANVAS_NAME
  };
};

// Update canvas name in canvas list store
export const updateCanvasNameList = (list, id, name) => {
  return {
    type: actionTypes.SET_CANVAS_LIST_NAME,
    list: list,
    id: id,
    name: name
  };
};

// Send canvas copy
export const sendCanvasStart = () => {
  return {
    type: actionTypes.SEND_CANVAS_INIT
  };
};

export const sendCanvas = (user_id, canvas_id, email) => {
  return {
    type: actionTypes.SEND_CANVAS,
    user_id: user_id,
    canvas_id: canvas_id,
    email: email
  };
};

export const sendCanvasSuccess = (status, message) => {
  return {
    type: actionTypes.SEND_CANVAS_SUCCESS,
    status: status,
    message: message
  };
};

export const sendCanvasFail = message => {
  return {
    type: actionTypes.SEND_CANVAS_FAILURE,
    message: message
  };
};

// Update notes
export const noteUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_NOTE_INIT
  };
};

export const noteUpdate = (user_id, canvas_id, note) => {
  return {
    type: actionTypes.UPDATE_NOTE,
    user_id: user_id,
    canvas_id: canvas_id,
    note: note
  };
};

export const noteUpdateSuccess = message => {
  return {
    type: actionTypes.UPDATE_NOTE_SUCCESS,
    message: message
  };
};

export const noteUpdateFailure = message => {
  return {
    type: actionTypes.UPDATE_NOTE_FAILURE,
    message: message
  };
};

// Set note in store
export const setNote = details => {
  return {
    type: actionTypes.SET_NOTE,
    details: details
  };
};

// Canvas view public
export const canvasViewStart = () => {
  return {
    type: actionTypes.CANVAS_VIEW_INIT
  };
};

export const canvasView = token => {
  return {
    type: actionTypes.CANVAS_VIEW,
    token: token
  };
};

export const canvasViewSuccess = message => {
  return {
    type: actionTypes.CANVAS_VIEW_SUCCESS,
    message: message
  };
};

export const canvasViewFailure = message => {
  return {
    type: actionTypes.CANVAS_VIEW_FAILURE,
    message: message
  };
};

// Set canvas view - private
export const setCanvasPrivate = () => {
  return {
    type: actionTypes.SET_CANVAS_PRIVATE
  };
};

// Set canvas view - preview
export const setCanvasPreview = token => {
  return {
    type: actionTypes.SET_CANVAS_PREVIEW,
    token: token
  };
};

// Set canvas view - collaborate
export const setCanvasCollaborate = token => {
  return {
    type: actionTypes.SET_CANVAS_COLLABORATE,
    token: token
  };
};

// Canvas copy inivitation
export const canvasCopyInvitationStart = () => {
  return {
    type: actionTypes.CANVAS_COPY_INVITATION_INIT
  };
};

export const canvasCopyInvitation = token => {
  return {
    type: actionTypes.CANVAS_COPY_INVITATION,
    token: token
  };
};

export const canvasCopyInvitationSuccess = (status, message) => {
  return {
    type: actionTypes.CANVAS_COPY_INVITATION_SUCCESS,
    status: status,
    message: message
  };
};

export const canvasCopyInvitationFailure = (status, message) => {
  return {
    type: actionTypes.CANVAS_COPY_INVITATION_FAILURE,
    status: status,
    message: message
  };
};

// Collaborate add comment
export const canvasCollaborateAddCommentStart = () => {
  return {
    type: actionTypes.CANVAS_COLLABORATE_ADD_COMMENT_INIT
  };
};

export const canvasCollaborateAddComment = (
  token,
  canvas_id,
  list,
  email,
  section,
  comment
) => {
  return {
    type: actionTypes.CANVAS_COLLABORATE_ADD_COMMENT,
    token: token,
    canvas_id: canvas_id,
    list: list,
    email: email,
    section: section,
    comment: comment
  };
};

export const canvasCollaborateAddCommentSuccess = (status, message) => {
  return {
    type: actionTypes.CANVAS_COLLABORATE_ADD_COMMENT_SUCCESS,
    status: status,
    message: message
  };
};

export const canvasCollaborateAddCommentFail = message => {
  return {
    type: actionTypes.CANVAS_COLLABORATE_ADD_COMMENT_FAILURE,
    message: message
  };
};
