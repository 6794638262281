import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.competition.action";
import * as canvasAction from "../actions/canvas.action";
import * as history from "../actions/history/canvas.history.action";
import axios from "../config/axios";

// Update competition
export function* updateCompetitionSaga(action) {
  yield put(actions.updateCompetitionStart());
  const data = {
    user_id: action.user_id,
    competition: action.competition
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/competition`,
      data
    );
    yield put(
      actions.updateCompetitionSuccess(response.status, response.message)
    );
    yield put(actions.setCompetition(action.competition));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCompetitionFail(e.message));
  }
}
