import React, { Component } from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";

class SimpleAlert extends Component {
  constructor(props) {
    super(props);
    this.alertBox = React.createRef();
  }

  state = {
    show: this.props.show,
    message: this.props.message,
    className: this.props.className
  };

  close = () => {
    this.alertBox.current.style.opacity = 0;
    this.setState({ show: false, message: "", className: "" });
    this.props.close();
  };

  render() {
    return (
      <Aux>
        {this.state.show === true ? (
          <div className={this.state.className} ref={this.alertBox}>
            <span className="custom__alert__closebtn" onClick={this.close}>
              &times;
            </span>
            {this.props.message}
          </div>
        ) : null}
      </Aux>
    );
  }
}

export default SimpleAlert;
