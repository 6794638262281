import { put } from "redux-saga/effects";
import * as actions from "../../actions/history/channelText.history.action";
import * as channelTextAction from "../../actions/canvas.channelText.action";
import axios from "../../config/axios";
import * as notification from "../../actions/notification.action";

// Update channel text owned history
export function* updateChannelTextOwnedHistorySaga(action) {
  yield put(actions.updateChannelTextOwnedHistoryStart());
  const data = {
    user_id: action.user_id,
    history_id: action.history_id,
    channel_text_owned_text1: action.channel_text_owned_text1,
    channel_text_owned_text2: action.channel_text_owned_text2,
    channel_text_owned_text3: action.channel_text_owned_text3,
    channel_text_owned_text4: action.channel_text_owned_text4,
    channel_text_owned_text5: action.channel_text_owned_text5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/channel-text-owned`,
      data
    );
    yield put(
      actions.updateChannelTextOwnedHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(
      channelTextAction.setChannelTextOwned(
        action.channel_text_owned_text1,
        action.channel_text_owned_text2,
        action.channel_text_owned_text3,
        action.channel_text_owned_text4,
        action.channel_text_owned_text5
      )
    );
  } catch (e) {
    yield put(actions.updateChannelTextOwnedHistoryFail(e.message));
  }
}

// Update channel text shared history
export function* updateChannelTextSharedHistorySaga(action) {
  yield put(actions.updateChannelTextSharedHistoryStart());
  const data = {
    user_id: action.user_id,
    history_id: action.history_id,
    channel_text_shared_text1: action.channel_text_shared_text1,
    channel_text_shared_text2: action.channel_text_shared_text2,
    channel_text_shared_text3: action.channel_text_shared_text3,
    channel_text_shared_text4: action.channel_text_shared_text4,
    channel_text_shared_text5: action.channel_text_shared_text5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/channel-text-shared`,
      data
    );
    yield put(
      actions.updateChannelTextSharedHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(
      channelTextAction.setChannelTextShared(
        action.channel_text_shared_text1,
        action.channel_text_shared_text2,
        action.channel_text_shared_text3,
        action.channel_text_shared_text4,
        action.channel_text_shared_text5
      )
    );
  } catch (e) {
    yield put(actions.updateChannelTextSharedHistoryFail(e.message));
  }
}

// Update channel text paid
export function* updateChannelTextPaidHistorySaga(action) {
  yield put(actions.updateChannelTextPaidHistoryStart());
  const data = {
    user_id: action.user_id,
    history_id: action.history_id,
    channel_text_paid_text1: action.channel_text_paid_text1,
    channel_text_paid_text2: action.channel_text_paid_text2,
    channel_text_paid_text3: action.channel_text_paid_text3,
    channel_text_paid_text4: action.channel_text_paid_text4,
    channel_text_paid_text5: action.channel_text_paid_text5
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/history/channel-text-paid`,
      data
    );
    yield put(
      actions.updateChannelTextPaidHistorySuccess(
        response.status,
        response.message
      )
    );
    yield put(
      channelTextAction.setChannelTextPaid(
        action.channel_text_paid_text1,
        action.channel_text_paid_text2,
        action.channel_text_paid_text3,
        action.channel_text_paid_text4,
        action.channel_text_paid_text5
      )
    );
    yield put(
      notification.setNotification(
        response.status,
        false,
        response.message,
        false
      )
    );
  } catch (e) {
    yield put(actions.updateChannelTextPaidHistoryFail(e.message));
  }
}
