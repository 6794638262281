import * as actionTypes from "../actionTypes";

export const verifyStart = () => {
  return {
    type: actionTypes.VERIFICATION_INIT
  };
};

export const verifyProcess = token => {
  return {
    type: actionTypes.VERIFICATION_PROCESS,
    token: token
  };
};

export const verifySuccess = (message, id) => {
  return {
    type: actionTypes.VERIFICATION_SUCCESS,
    message: message,
    id: id
  };
};

export const verifyFail = message => {
  return {
    type: actionTypes.VERIFICATION_FAIL,
    message: message
  };
};

export const verifyClearMessage = () => {
  return {
    type: actionTypes.VERIFICATION_CLEAR
  };
};

export const verifyReset = () => {
  return {
    type: actionTypes.VERIFICATION_RESET
  };
};
