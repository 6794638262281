import { put } from "redux-saga/effects";
import * as actions from "../actions/canvas.core.action";
import * as canvasAction from "../actions/canvas.action";
import * as history from "../actions/history/canvas.history.action";
import axios from "../config/axios";

// Update core purpose
export function* updateCorePurposeSaga(action) {
  yield put(actions.updateCorePurposeStart());
  const data = {
    user_id: action.user_id,
    core_purpose: action.core_purpose
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/core-purpose`,
      data
    );
    yield put(
      actions.updateCorePurposeSuccess(response.status, response.message)
    );
    yield put(actions.setCorePurpose(action.core_purpose));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCorePurposeFail(e.message));
  }
}

// Update core values
export function* updateCoreValuesSaga(action) {
  yield put(actions.updateCoreValuesStart());
  const data = {
    user_id: action.user_id,
    core_values: action.core_values
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/core-values`,
      data
    );
    yield put(
      actions.updateCoreValuesSuccess(response.status, response.message)
    );
    yield put(actions.setCoreValues(action.core_values));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCoreValuesFail(e.message));
  }
}

// Update core essence
export function* updateCoreEssenceSaga(action) {
  yield put(actions.updateCoreEssenceStart());
  const data = {
    user_id: action.user_id,
    core_essence: action.core_essence
  };

  try {
    let response = yield axios.put(
      `/canvas/${action.canvas_id}/core-essence`,
      data
    );
    yield put(
      actions.updateCoreEssenceSuccess(response.status, response.message)
    );
    yield put(actions.setCoreEssence(action.core_essence));
    yield put(history.resetHistoryScroller());
    yield put(history.canvasHistoryList(action.user_id, action.canvas_id));
    yield put(canvasAction.setCurrentVersionTime(response.data));
  } catch (e) {
    yield put(actions.updateCoreEssenceFail(e.message));
  }
}
