import * as actionTypes from "./actionTypes";

// Update creatives
export const updateCreativesStart = () => {
  return {
    type: actionTypes.UPDATE_CREATIVES_INIT
  };
};

export const updateCreatives = (
  user_id,
  canvas_id,
  naming,
  domain,
  typography,
  logo,
  packaging
) => {
  return {
    type: actionTypes.UPDATE_CREATIVES,
    user_id: user_id,
    canvas_id: canvas_id,
    naming: naming,
    domain: domain,
    typography: typography,
    logo: logo,
    packaging: packaging
  };
};

export const updateCreativesSuccess = (status, message) => {
  return {
    type: actionTypes.UPDATE_CREATIVES_SUCCESS,
    status: status,
    message: message
  };
};

export const updateCreativesFail = message => {
  return {
    type: actionTypes.UPDATE_CREATIVES_FAILURE,
    message: message
  };
};

// Set updated creatives  in store
export const setCreatives = (naming, domain, typography, logo, packaging) => {
  return {
    type: actionTypes.SET_CREATIVES,
    naming: naming,
    domain: domain,
    typography: typography,
    logo: logo,
    packaging: packaging
  };
};

// Upload logo
export const uploadLogoStart = () => {
  return {
    type: actionTypes.UPLOAD_LOGO_INIT
  };
};

export const uploadLogo = (user_id, canvas_id, file) => {
  return {
    type: actionTypes.UPLOAD_LOGO,
    user_id: user_id,
    canvas_id: canvas_id,
    file: file
  };
};

export const uploadLogoSuccess = (message, path) => {
  return {
    type: actionTypes.UPLOAD_LOGO_SUCCESS,
    message: message,
    path: path
  };
};

export const uploadLogoFail = message => {
  return {
    type: actionTypes.UPLOAD_LOGO_FAILURE,
    message: message
  };
};

// Set logo in local store
export const setLogo = logo => {
  return {
    type: actionTypes.SET_LOGO,
    logo: logo
  };
};

// Upload package
export const uploadPackageStart = () => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_INIT
  };
};

export const uploadPackage = (user_id, canvas_id, file) => {
  return {
    type: actionTypes.UPLOAD_PACKAGE,
    user_id: user_id,
    canvas_id: canvas_id,
    file: file
  };
};

export const uploadPackageSuccess = (message, path) => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_SUCCESS,
    message: message,
    path: path
  };
};

export const uploadPackageFail = message => {
  return {
    type: actionTypes.UPLOAD_PACKAGE_FAILURE,
    message: message
  };
};

// Set logo in local store
export const setPackaging = packaging => {
  return {
    type: actionTypes.SET_PACKAGING,
    packaging: packaging
  };
};

// Add color
export const addColorStart = () => {
  return {
    type: actionTypes.ADD_COLOR_INIT
  };
};

export const addColor = (user_id, canvas_id, list, color) => {
  return {
    type: actionTypes.ADD_COLOR,
    canvas_id: canvas_id,
    user_id: user_id,
    list: list,
    color: color
  };
};

export const addColorSuccess = message => {
  return {
    type: actionTypes.ADD_COLOR_SUCCESS,
    message: message
  };
};

export const addColorFail = message => {
  return {
    type: actionTypes.ADD_COLOR_FAILURE,
    message: message
  };
};

// Add new color to existing list 
export const updateColorList = (list, color) => {
  return {
    type: actionTypes.UPDATE_COLOR_LIST,
    list: list,
    color: color
  };
};

// Delete color
export const deleteColorStart = () => {
  return {
    type: actionTypes.DELETE_COLOR_INIT
  };
};

export const deleteColor = (user_id, canvas_id, list, color) => {
  return {
    type: actionTypes.DELETE_COLOR,
    user_id: user_id,
    canvas_id: canvas_id,
    list: list,
    color: color
  };
};

export const deleteColorSuccess = message => {
  return {
    type: actionTypes.DELETE_COLOR_SUCCESS,
    message: message
  };
};

export const deleteColorFail = message => {
  return {
    type: actionTypes.DELETE_COLOR_FAILURE,
    message: message
  };
};

// Remove color from existing list
export const removeColorFromList = (list, color) => {
  return {
    type: actionTypes.REMOVE_COLOR_LIST,
    list: list,
    color: color
  };
};

// Delete logo/package
export const deleteCreativeFileStart = () => {
  return {
    type: actionTypes.DELETE_CREATIVE_FILE_INIT
  };
};

export const deleteCreativeFile = (user_id, canvas_id, type, path) => {
  return {
    type: actionTypes.DELETE_CREATIVE_FILE,
    user_id: user_id,
    canvas_id: canvas_id,
    fileType: type,
    path
  };
};

export const deleteCreativeFileSuccess = (message, path) => {
  return {
    type: actionTypes.DELETE_CREATIVE_FILE_SUCCESS,
    message: message,
  };
};

export const deleteCreativeFileFail = message => {
  return {
    type: actionTypes.DELETE_CREATIVE_FILE_FAILURE,
    message: message
  };
};
